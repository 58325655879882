import Footer from "components/Frontend/Footer";
import Header from "components/Frontend/Header";
import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Language Academy</title>
      </Helmet>
      <Header />
      <div className="innerPage">
        <section className="bannerOuter">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1> Privacy Policy </h1>
                <span></span>
                <br />
                <a href="javascript:;" className="downArrow">
                  <img src="images/banner-arrow.png" alt="banner-arrow" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section id="pte-modules" className="mt-5">
        <div className="container">
          <div className="row">
            <p>
              By accessing and utilising the Website, the Apps, and the Services
              provided by Language Academy Australia Pty Ltd ("Language Academy"), you
              agree that any Personal Data concerning you, collected by us or
              provided by you, may be utilised, processed, and disclosed as
              outlined in this Privacy Policy, unless restricted by applicable
              law.
            </p>
            <p>
              As part of our commitment to responsibly manage, safeguard, and
              process your Personal Data, we regularly review our policies,
              procedures, and processes. We retain the right to amend this
              Privacy Policy at our discretion by posting a revised version on
              the Website. Although we endeavour to individually notify users of
              policy amendments, your continued use of the Website, the Apps, or
              the Services following such changes will be deemed as
              acknowledgment and acceptance of the revised Privacy Policy. We
              recommend periodically checking this Website for updates to our
              Privacy Policy.
            </p>
            <p>
              When engaging with our Services, we collect various information
              from and about you, your devices, and your interactions with the
              Services. Some of this information directly identifies you or can
              do so when combined with other data. Personal Data is defined as
              any data, whether true or not, that can identify an individual or
              can be linked to other information to identify an individual.
            </p>
            <p>
              In general, once you provide us with your personal information,
              you are no longer anonymous to us. We may automatically track
              certain information based on your behaviour on our Website, using
              it for internal research to understand our users' demographics,
              interests, and behaviour. This information is aggregated and
              analysed. It may encompass the URL from which you arrived (whether
              on our website or not), your subsequent destination URL (whether
              on our website or not), your computer's browser information, and
              your IP address.
            </p>
            <p>
              Should you choose to post reviews, messages, or feedback, we will
              collect the information you provide. We retain this data as
              required for dispute resolution, customer support, and
              problem-solving within the confines of the law. In cases where you
              or third parties send us correspondence about your activities or
              postings on the Website, such information may also be collected
              and stored.
            </p>
            <p>
              When you create an account with us, we collect personally
              identifiable information (email address, name, phone number, and
              profile picture). We use this contact information to send you
              offers based on your previous orders and interests. You may
              receive emails from us for various reasons. Even if you
              unsubscribe from specific email correspondences, we may still send
              you essential transactional or administrative information.
            </p>
            <p>
              It is essential that any Personal Data you submit to us is
              accurate, complete, true, and correct. Failure to do so may hinder
              our ability to provide the Services you have requested.
            </p>
            <p>
              We will make reasonable efforts to maintain the accuracy and
              completeness of your Personal Data when such data is likely to
              influence our decisions or when disclosed to other organisations.
              To achieve this, you must promptly update us with any changes to
              your initially provided Personal Data. We cannot be held
              responsible for relying on inaccurate or incomplete Personal Data
              resulting from your failure to update us.
            </p>
            <p>
              We will employ reasonable technical and organisational measures to
              prevent the loss, misuse, or alteration of your Personal Data. All
              Personal Data you provide will be securely stored on encrypted
              servers.
            </p>
            <p>
              However, it is important to acknowledge that information
              transmission over the Internet is not entirely secure. Despite our
              best efforts to protect your Personal Data, we cannot guarantee
              the security of data transmitted to the Website or the Apps.
              Transmission of information is done at your own risk.
              Additionally, we cannot assume responsibility for unauthorised use
              of your Personal Data by third parties, as such actions may be
              beyond our control.
            </p>
            <p>
              We strongly recommend taking precautions to secure your personal
              information, such as choosing a robust password and keeping it
              confidential. Be sure to log out of your user account and close
              your web browser when using our Services on shared or unsecured
              devices.
            </p>
            <p>
              We grant all our users the authority and freedom to delete their
              profiles along with all associated data. Users may initiate the
              deletion process themselves by contacting us at
              info@languageacademy.com for assistance. We will then promptly
              delete their profiles and all stored data, confirming the action.
            </p>
            <p>
              By using the Website and/or providing your information, you
              consent to the collection and use of the information disclosed on
              the Website in accordance with this Privacy Policy, including
              sharing your information as described herein. Should we decide to
              modify our privacy policy, we will post such changes on this page
              to ensure you remain informed of the data we collect, how we use
              it, and the circumstances under which we disclose it.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
