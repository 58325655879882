import React from "react";
import Carousal from "components/Dashboard/Carousal.jsx";


export default function TuteVideos(props) {
	const { videos } = props;


	return (
		<>
			<div className="borderTitle">
				<h3 className="text-uppercase fw-bold bg-grey">PTE TUTORIAL VIDEOS</h3>
			</div>
			<div className="totorialTabs">
				<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
					{Object.keys(videos).map((val, key) => {
						return (
							<li className="nav-item" role="presentation" key={key}>
								<button 
									className={`nav-link ${key === 0 ? 'active' : ''}`}
									data-bs-target={`#pills-${val}`}
									id={`pills-${val}-tab`}
									data-bs-toggle="pill"
									type="button"
									role="tab"
									aria-controls={`pills-${val}`}
									aria-selected="false"
								>
									{val}
								</button>
							</li>
						)
					})}
				</ul>
				<div className="tab-content" id="pills-tabContent">
					{Object.keys(videos).map((val, key) => {
						return (
							<div className={`tab-pane fade ${key === 0 ? 'show active' : ''}`} id={`pills-${val}`} role="tabpanel" aria-labelledby={`pills-${val}-tab`} key={key}>
								<div className="row">
									<Carousal items={videos[val]} />
								</div>
							</div>
						)
					})}	
				</div>
			</div>
		</>
	);
}
