import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import API from 'api.js';

export default function MoreAiSuggestions(props) {

	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState("");
	const [title, setTitle] = useState("");

	const suggestedIdeas = () => {
		API({
			url: "essay/suggest/keywords",
			method: "POST",
			data: {
				question_id: props.question_id
			}
		}).then(res => {
			setIsOpen(true);
			setData(res.data.keywords);
			setTitle(res.data.title);
		});
	}

	const sampleResponse = () => {
		API({
			url: "essay/sample/response",
			method: "POST",
			data: {
				question_id: props.question_id
			}
		}).then(res => {
			setIsOpen(true);
			setData(res.data.sample_response);
			setTitle(res.data.title);
		});
	}

	return (
		<>
			<div className="col-12 more-ai-options text-left">
				<Button
					className="suggected-ideas bottom-btn"
					size="sm"
					onClick={() => sampleResponse()}
				>Sample Response</Button>

				<Button
					className="suggected-ideas bottom-btn"
					size="sm"
					onClick={() => suggestedIdeas()}
				>Suggested Ideas</Button>
			</div>

			<Modal
				isOpen={isOpen}
				size="lg"
				backdropClassName="shadow-backdrop"
				className="practice-shadowing-modal unselectable text-translate-modal word-definition-modal pre-line"
			>
				<ModalHeader
					className="word-definition-heading"
					tag="div"
				>
					<h4 className="mb-2 text-light">{title}</h4>
				</ModalHeader>
				<ModalBody>
					<div className="text-translation">
						<div className="mb-2 definition-text">
							{data}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="danger"
						className="close-button"
						onClick={() => { setIsOpen(false) }}
					>Close</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}