import { s3base } from "api";
import Image from "components/Image/Image";
import BootstrapModal from "components/PopUps/BootstrapModal";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const Item = ({ title, thumbnail, vidLink, id }) => {
  const [modelStatus, setModelStatus] = useState(false);
  const history = useHistory();

  const modelHandler = () => {
    if (!vidLink) {
      setModelStatus((prevState) => !prevState);
      return;
    }
    history.push("/admin/video/" + id);
  };

  let vidPopup;
  if (!vidLink) {
    vidPopup = (
      <ModalSkeleton
        status={modelStatus}
        toggle={modelHandler}
        handleClick={() => {
          history.push("/admin/plans");
        }}
        btnText="Buy Plan"
        message="Please subscribe to our gold plan to see Secret Strategy Videos"
        isSmall={false}
      />
    );
  }

  return (
    <>
      <div className="videoChapter col-md-4 col-lg-3">
        <div className="videoChapterInr" onClick={modelHandler}>
          {/* <img src={s3base + thumbnail} /> */}
          <Image path={s3base + thumbnail} alt="" classes="" />
          <div className="videoChapterTitle">{title}</div>
        </div>
      </div>
      {vidPopup}
    </>
  );
};

export default Item;
