import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ModalPopup = ({ status, toggle, render, showCloseBtn }) => {
  return (
    <Modal isOpen={status} size="md">
      <ModalBody>
        {showCloseBtn && <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span>}
        {render(toggle)}
      </ModalBody>
    </Modal>
  );
};

export default ModalPopup;
