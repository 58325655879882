import RazorPay from "components/Payments/Razorpay/RazorPay";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const planType = ["STANDARD", "BEST SELLER", "MOST SAVINGS"];

const Plan = ({ plan, startCheckout, currency, country, activeTab }) => {
  const [paymentType, setPaymentType] = useState("");
  const token = useSelector((state) => state.token);
  const history = useHistory();

  const handleBuyNowClick = (e) => {
    e.preventDefault();
    if (token) {
      history.push("/admin/sub/payment/form/" + plan.id);
      return;
    }
  };

  const redirectAfterPayment = () => {
    history.push(
      `/pay-success?p=${plan.razorpay_price}&currency=${ReactHtmlParser(
        currency
      )}&mode=razorpay`
    );
  };

  return (
    <div className="col" key={plan.id}>
      <div className={"mplanInr mb-4 month0" + plan.duration}>
        <div className="mplanHead">
          <h3>
            {currency} ({plan.duration} Month(s))
          </h3>
          <span className="planTag">{planType[plan.duration - 1]}</span>
        </div>
        <div className="mpanBody">
          {plan.discount && (
            <div className="mplanOffer">
              <i className="fa fa-check-circle"></i>
              <span>{plan.discount}% OFF</span>
            </div>
          )}
          <p>
            {paymentType == "upi" ? plan.razorpay_price : plan.price}* (
            {plan.duration} Month(s))
          </p>
          <span>* Auto Renews every {plan.duration} month</span>
          <div className="buyNowBtn text-white mt-2">
            <button
              className="btn ss-btn outlineBtn m-0 text-white"
              onClick={handleBuyNowClick}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Select Payment</span>
            </button>
            <ul className="dropdown-menu pay-opts">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => setPaymentType("card")}
                >
                  Pay with Card
                </button>
              </li>
              {country === "IN" && (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setPaymentType("upi")}
                  >
                    Pay with UPI
                  </button>
                </li>
              )}
            </ul>

            {paymentType === "card" && (
              <Link
                to="#"
                onClick={startCheckout.bind(this, plan.id)}
                className="btn ss-btn outlineBtn m-0 ms-1"
                data-animation="fadeInUp"
                data-delay=".8s"
              >
                <span>Pay with Card</span>
              </Link>
            )}

            {paymentType === "upi" && country === "IN" && (
              <RazorPay
                plan={plan}
                isLoggedIn={false}
                redirect={redirectAfterPayment}
                activeTab={activeTab}
              >
                {(clickHandler) => (
                  <Link
                    to="#"
                    onClick={clickHandler}
                    className="btn ss-btn outlineBtn m-0 ms-1"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    <span>Pay with UPI</span>
                  </Link>
                )}
              </RazorPay>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
