import React, { useState } from 'react';
import Select, { StylesConfig } from 'react-select';

export default function SelectOption(props) {

	const [isClearable, setIsClearable] = useState(props.isClearable);
	const [isSearchable, setIsSearchable] = useState(true);
	const [isDisabled, setIsDisabled] = useState(props?.isDisabled?true:false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRtl, setIsRtl] = useState(false);

	const { selected, data, placeholder } = props;

	const onChange = (data) => {
		const value = data === null ? "0" : data.value;
		props.changeEvent(value);
	};

	return (
		<>
			<Select
				className="basic-single"
				classNamePrefix="select"
				defaultValue={selected}
				isDisabled={isDisabled}
				isLoading={isLoading}
				isClearable={isClearable}
				isRtl={isRtl}
				isSearchable={isSearchable}
				name="color"
				options={data}
				onChange={onChange}
				placeholder={placeholder !== undefined ? placeholder : "Select"}
				styles={{
					control: (provided) => ({
						...provided,
						backgroundColor: isDisabled ? 'lightgrey' : 'white', // Apply grey only if disabled
						color: isDisabled ? 'lightgrey' : 'black'
					  })
				}} 
			/>
		</>
	);
}