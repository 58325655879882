import useQueryParam from "hooks/useQueryParam";
import React, { useEffect, useState } from "react";
import API from "api";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setLogo, setSub, setPasswordPopup } from "action";
import { useHistory } from "react-router-dom";

const MagicTokenLogin = () => {
  const dispatch = useDispatch();
  const tokenState = useSelector((state) => state.token);
  const history = useHistory();
  const query = useQueryParam();
  const token = query.get("token");
  const [error, setError] = useState(null);
  const [showLoginBtn, setShowLoginBtn] = useState(true);
  const [successMsg, setSuccessMsg] = useState(
    "Click on Login button to access dashboard."
  );

  useEffect(() => {
    if (tokenState) {
      history.push("/admin/dashboard");
    }
  }, [tokenState]);

  const loginHandler = async () => {
    if (!tokenState) {
      let message = null;
      try {
        const response = await API({
          url: "verify-token",
          method: "POST",
          data: { token },
        });

        if (response.status === 200) {
          dispatch(setToken(response.data.access_token));
          dispatch(setUser(response.data.user));
          dispatch(setLogo(response.data.image));
          dispatch(setPasswordPopup(true));
          let sub =
            response.data.user.active_subscription.length === 0 ? false : true;
          dispatch(setSub(sub));
          return;
        }

        if (response.response) {
          message =
            response.response.status === 403 &&
            response.response.data.key === "tok_exp"
              ? "This Link has been expired. Please generate a new one."
              : "Invalid Link.";
        }
      } catch (error) {
        message = "Something went wrong.";
      }
      setError(message);
    }
  };

  const handleRegenerate = async () => {
    let message = null;
    try {
      const response = await API({
        url: "regenerate-token",
        method: "POST",
        data: { token },
      });

      if (response.status === 200) {
        const success = response.data.message;
        setSuccessMsg(success);
        setError(null);
        setShowLoginBtn(false);
        return;
      }

      if (response.response) {
        message =
          response.response.status === 403
            ? "Invalid Link."
            : "Something went wrong.";
      }
    } catch (error) {
      message = "Something went wrong.";
    }

    setError(message);
  };

  return (
    <div className="link-verify__container">
      <div className="link-verify__message">{error ? error : successMsg}</div>
      {error && error !== "Invalid Link." && (
        <button
          className="button-resent__link"
          role="button"
          onClick={handleRegenerate}
        >
          Regenerate the link
        </button>
      )}

      {!error && showLoginBtn && (
        <button
          className="button-resent__link"
          role="button"
          onClick={loginHandler}
        >
          Login
        </button>
      )}
    </div>
  );
};

export default MagicTokenLogin;
