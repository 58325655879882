import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

const BottomBtns = ({ mockData, setMockData }) => {
  const showSaveAndExit =
    !mockData.dashboardFlag &&
    !mockData.saveProcess &&
    !mockData.resultId &&
    mockData.currentQuestion > 0 &&
    mockData.questionData.length !== mockData.currentQuestion + 1;

  const showSubmitBtn =
    !mockData.dashboardFlag &&
    !mockData.submitProcess &&
    mockData.secondCount !== 0 &&
    !mockData.resultId &&
    !(mockData.questionData.length !== mockData.currentQuestion + 1);

  const showNextBtn =
    !mockData.dashboardFlag &&
    mockData.secondCount !== 0 &&
    !mockData.resultId &&
    mockData.questionData.length !== mockData.currentQuestion + 1 &&
    mockData.questionData.length !== 0;

  const nextButton = (e, p) => {
    if (!mockData.soundStat) {
      setMockData((prevState) => {
        return { ...prevState, soundModal: true };
      });
    } else {
      let pending = 0;
      let msg = "";
      let complete = 0;
      if (p === 0) {
        pending = 0;
        msg = "Do you want to go to next Question?";
      } else if (p === 1) {
        pending = 0;
        msg = "Do you want to submit test?";
        complete = 1;
        // clearInterval(mockData.intervalId)
      } else if (p === 2) {
        msg = "Do you want to save this question and exit mock test?";
        pending = 1;
        // clearInterval(mockData.intervalId)
      }
      setMockData((prevState) => {
        return {
          ...prevState,
          modal: true,
          pending: pending,
          msg: msg,
          nextType: p,
          complete: complete,
        };
      });
    }
  };

  return (
    <Col xs={12}>
      <div className="bottomBtns">
        <Row>
          <Col xs={9}>
            {showSaveAndExit && (
              <Button
                color="primary"
                onClick={(e) => nextButton(e, 2)}
                size="xs"
              >
                Save & Exit
              </Button>
            )}
            {mockData.saveProcess &&
              !(
                mockData.questionData.length !==
                mockData.currentQuestion + 1
              ) && (
                <Button color="primary" size="xs">
                  Wait...
                </Button>
              )}
            {showSubmitBtn && (
              <Button
                color="primary"
                onClick={(e) => nextButton(e, 1)}
                size="xs"
              >
                Submit
              </Button>
            )}
            {mockData.submitProcess &&
              !(
                mockData.questionData.length !==
                mockData.currentQuestion + 1
              ) && (
                <Button color="primary" size="xs">
                  Wait...
                </Button>
              )}

            {mockData.submitProcess &&
              !(
                mockData.questionData.length !==
                mockData.currentQuestion + 1
              ) && (
                <Button color="primary" size="xs">
                  Wait...
                </Button>
              )}
            {mockData.resultId && (
              <NavLink to={"/admin/result/" + mockData.resultId}>
                <Button color="primary" size="xs">
                  Show Result
                </Button>
              </NavLink>
            )}
            {mockData.dashboardFlag && (
              <NavLink to={"/admin/dashboard"}>
                <Button color="primary" size="xs">
                  Go to Dashboard
                </Button>
              </NavLink>
            )}
            {mockData.timeOutFlag && <div>Test stopped due to timeout</div>}
          </Col>
          <Col xs={3}>
            {showNextBtn && (
              <Button
                color="primary"
                onClick={(e) => nextButton(e, 0)}
                size="xs"
              >
                NEXT
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default BottomBtns;
