import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  CardText,
  NavLink as NavLink1,
} from "reactstrap";
import ReactGA from "react-ga";
import API, { base } from "api.js";
import "react-daypicker/lib/DayPicker.css";
import DayPicker from "react-daypicker";
import SidebarClose from "components/Sidebar/SidebarClose";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      group: [],
      mock: {},
      planModal: false,
      result: false,
      remarks: [],
      activeTab: 1,
      user: false,
      f: true,
      date: new Date(),
      isDate: false,
      selectedDate: "",
      dateArr: [],
      dateSelect: "",
      isTask: true,
      rec_link: "",
      rec_pwd: "",
      timezone: "",
    };
  }
  componentDidMount() {
    API({
      method: "GET",
      url: "get/session-wl",
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((data) => {
        console.log(data);
        this.setState({
          selectedDate: data.data.date,
          data: data.data.data,
          timezone: data.data.timezone,
        });
      })
      .catch(() => {});
  }

  closePlanModal = () => {
    this.setState({ planModal: !this.state.planModal });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  getUnattempted = (arr) => {
    let c = 0;
    arr.map((v) => {
      if (v.not_attempted == 0) {
        c += 1;
      }
    });
    return c;
  };

  changeInput = (e) => {
    let { name, value } = e.target;
    if (Number.isInteger(parseInt(value)) && parseInt(value) >= 0) {
      this.setState({ countArr: { ...this.state.countArr, [name]: +value } });
    }
  };

  changeInputSelect = (e) => {
    let { name, value } = e.target;

    this.setState({ [name]: value });
  };

  changeDate = (date) => {
    this.setState({ date: date });
    var fullDate = new Date(date);
    var twoDigitMonth = fullDate.getMonth() + 1 + "";
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    var currentDate =
      fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;

    API({
      method: "GET",
      url: "get/session-wl?date=" + currentDate,
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((data) => {
        console.log(data);
        this.setState({
          selectedDate: data.data.date,
          data: data.data.data,
          isDate: false,
          timezone: data.data.timezone,
        });
      })
      .catch(() => {});
  };
  formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  formatDateTo12 = (date) => {
    let numDate = date.getDate();
    if(numDate < 10) {
      numDate = '0' + numDate;
    }
    let month = date.getMonth() + 1;
    if(month < 10) {
      month = '0' + month;
    }
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strDate = `${year}-${month}-${numDate} ${hours}:${minutes} ${ampm}`;
    return strDate;
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        {this.state.planModal && (
          <Modal isOpen={this.state.planModal} id="anouncementPopup" size="lg">
            <div class="modal-body">
              <button
                onClick={(e) => {
                  this.closePlanModal();
                }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
              <div class="mobileIcon">
                <Row>
                  <Col md={12}>
                    <table className="table">
                      <tr>
                        <th>Link</th>
                        <th>Password</th>
                      </tr>
                      <tr>
                        <td>
                          <a target="_blank" href={this.state.rec_link}>
                            Open Link
                          </a>
                        </td>
                        <td>{this.state.rec_pwd}</td>
                      </tr>
                    </table>
                  </Col>
                </Row>
              </div>
              <div class="contentInr"></div>
            </div>
          </Modal>
        )}
        <div className="content">
          <SidebarClose />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Live Session(s)</CardTitle>
                </CardHeader>
                <CardBody>
                  <h6>
                    <b>Selected Date - {this.state.selectedDate}</b>
                  </h6>
                  <div className="row">
                    <div className="col-md-9">
                      <Button
                        onClick={() => {
                          this.setState({ isDate: !this.state.isDate });
                        }}
                      >
                        Choose Date
                      </Button>
                      <div className="calendarOuter">
                        {this.state.isDate && (
                          <DayPicker
                            onDayClick={this.changeDate}
                            active={this.state.date}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-3"></div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12 tableOuter">
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th className="">Title</th>
                            <th className="">Description</th>
                            <th className="">Time (Sydney Time)</th>
                            <th className="">
                              Local Time ({this.state.timezone})
                            </th>
                            <th className="">Link</th>
                            <th className="">Recording</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                                <td>
                                  {this.state.selectedDate +
                                    " " +
                                    this.formatAMPM(
                                      new Date(val.time.replace(/-/g, "/"))
                                    )}
                                </td>
                                <td>
                                  {this.formatDateTo12(
                                    new Date(val.local_time.replace(/-/g, "/"))
                                  )}
                                </td>
                                <td>
                                  <a target="_blank" href={val.link}>
                                    Zoom Link
                                  </a>
                                </td>
                                <td>
                                  {val.recording_link && (
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) => {
                                        this.setState({
                                          planModal: true,
                                          rec_link: val.recording_link,
                                          rec_pwd: val.recording_password,
                                        });
                                      }}
                                    >
                                      Link
                                    </a>
                                  )}
                                  {!val.recording_link && "-"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <br />
                      <br />
                      <br />
                    </div>

                    {false && Object.keys(this.state.group).length > 0 && (
                      <h3>Feedback</h3>
                    )}
                    <div className="col-sm-12 feedbackCntnt">
                      {false &&
                        Object.keys(this.state.group).map((val, key) => {
                          return (
                            <div class="remarksBreakdown">
                              <h5>{this.getQuestionType(parseInt(val))}</h5>
                              <p>
                                <b>Question Attempted</b> -{" "}
                                {this.state.group[val].length}
                              </p>
                              <p>
                                <b>Score Percentage</b> -{" "}
                                {this.calculateScore(this.state.group[val])} %
                              </p>
                              <p>
                                {" "}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.calculateRemarks(
                                      this.state.group[val]
                                    ),
                                  }}
                                ></p>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
