import React, { useEffect } from 'react';

export default function RedirectTo(props) {

	useEffect(() => {
		let redirect_url = "/";
		switch (window.location.pathname) {
			case "/pte-speaking":
				redirect_url = "/pte-speaking-test-practice-material";
				break;
			case "/pte-writing":
				redirect_url = "/pte-writing-test-practice-material";
				break;
			case "/pte-reading":
				redirect_url = "/pte-reading-test-practice-material";
				break;
			case "/pte-listening":
				redirect_url = "/pte-listening-test-practice-material";
				break;
			default:
				redirect_url = "/";
		}

		window.location.href = redirect_url;
	}, []);

	return "";
}