import React from "react";
import { useDispatch } from "react-redux";
import API from "api";
import { setAlert } from "action";
import Plan from "./Plan";

const Plans = ({ plans, country, activeTab }) => {
  const currency = country === "IN" ? "INR" : "AUD";
  const dispatch = useDispatch();

  const startCheckout = async (id, e) => {
    const data = { id, platform: activeTab };

    const baseUrl = process.env.REACT_APP_URL_ALT + "/api/v2/";
    let message = "Something went wrong";
    try {
      let response = await API({
        url: baseUrl + "createCheckoutSession",
        method: "POST",
        data,
      });

      if (response.status === 200) {
        window.location.href = response.data.checkout_url;
        return;
      }
    } catch (error) {
      message = error;
    }

    dispatch(
      setAlert({
        flag: true,
        type: 2,
        message,
      })
    );
  };

  return (
    <div className="col-md-5 mt-0 mPlansOuter">
      <div className="row mPlansInr flex-column">
        {plans.map((plan) => (
          <Plan
            plan={plan}
            activeTab={activeTab}
            key={activeTab + '_' + plan.id}
            startCheckout={startCheckout}
            currency={currency}
            country={country}
          />
        ))}
      </div>
    </div>
  );
};

export default Plans;
