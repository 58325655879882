import useForm from "hooks/useForm";
import React from "react";
import { Button, FormGroup, Input } from "reactstrap";
import API from "api";
import { setAlert, setPasswordPopup } from "action";
import { useDispatch } from "react-redux";

const SetPassword = ({ toggle }) => {
  const dispatch = useDispatch();
  const { makeFormEmpty, onChangeHandler, formData, isFormValid } = useForm({
    password: {
      value: "",
      validation: (value) => value.length >= 8,
      isValid: false,
      error: false,
    },
    cPassword: {
      value: "",
      validation: (value, formData) =>
        !!value && value === formData.password.value,
      isValid: false,
      error: false,
    },
  });

  const handleSubmit = async () => {
    if (!isFormValid) {
      alert("Please enter password");
      return;
    }

    const data = { password: formData.password.value };
    let message = "Something went wrong";
    let type = 2;
    try {
      let response = await API({
        method: "POST",
        url: "set-password",
        data,
      });

      if ((response.status === 200)) {
        console.log("success", response);
        message = "Password updated successfully.";
        type = 1;
        dispatch(setPasswordPopup(false));
      } else {
        message = response?.response?.data?.message || "Something went wrong";
      }
    } catch (error) {
      console.log("error", error);
    }
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  return (
    <div className="">
      <h6 className="text-center">Set Password</h6>
      <hr />
      <FormGroup>
        <label htmlFor="password">Password:</label>
        <Input
          type="password"
          className="form-control"
          name="password"
          id="password"
          value={formData.password.value}
          onChange={onChangeHandler}
          onBlur={onChangeHandler}
        />
        {formData.password.error && (
          <span className="text-danger">
            The password should be at least 8 characters in length.
          </span>
        )}
      </FormGroup>
      <FormGroup>
        <label htmlFor="confirm-password">Confirm Password:</label>
        <Input
          type="password"
          className="form-control"
          name="cPassword"
          id="confirm-password"
          value={formData.cPassword.value}
          onChange={onChangeHandler}
          onBlur={onChangeHandler}
        />
        {formData.cPassword.error && (
          <span className="text-danger">
            The password and confirm password must match.
          </span>
        )}
      </FormGroup>
      <div className="text-center">
        <Button
          className="btn btn-primary"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default SetPassword;
