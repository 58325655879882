import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import API from 'api.js';
import SelectOption from "components/PracticeMaterial/SelectOption";

import multiLing from "assets/images/multi-ling.png";

export default function TranslateModal(props) {

	const { isOpen, toggle, question, question_id, languages, voices } = props;

	const [question_text, setQuestionText] = useState(question);
	const [selectedLanguage, setSelectedLanguage] = useState("English");


	const selectScriptLanguage = (selectedValue) => {
		if (selectedValue !== "English" && selectedValue !== "0") {
			setQuestionText("Translating text. Please wait...");

			API({
				url: 'translate/sentence',
				method: 'POST',
				data: {
					script: question,
					lang: selectedValue,
					question_id: question_id
				}
			}).then((res) => {

				if (res.status === 200) {

					if (typeof res.data.translation === "object") {
						setQuestionText(res.data.translation.translation);
					} else {
						setQuestionText(res.data.translation);
					}
				} else {
					setQuestionText(res.message);
				}

				setSelectedLanguage(selectedValue);
				
			});
		} else {
			setSelectedLanguage("English");
			setQuestionText(question);
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			backdropClassName="shadow-backdrop"
			className="practice-shadowing-modal text-translate-modal"
		>
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				<div className="row">
					<div className="col-md-7">
						<h4 className="mb-2 text-light">
							Text Translation</h4>
						<div className="row " style={{ textAlign: 'left' }}>
							{languages.length > 0 &&
								<SelectOption
									isClearable={false}
									selected={selectedLanguage}
									data={languages.filter((language, i) => {
										return language.label !== selectedLanguage
									})}
									changeEvent={selectScriptLanguage}
								/>
							}
						</div>
					</div>
					<div className="col-md-3">&nbsp;</div>
					<div className="col-md-2">
						<img src={multiLing} alt="multi-languages" />
					</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="text-to-translate">
					<div className="mb-2 ques-text">{question_text}</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					className="close-button"
					onClick={() => toggle(false)}
				>Close</Button>
			</ModalFooter>
		</Modal>
	);
}