import React from "react";
import { CardBody, Row, Col, Button } from "reactstrap";

const NotFinished = ({ id }) => {
  return (
    <div className="content questionsArea mock-already-completed">
      <Row>
        <Col xs={12}>
          <CardBody>
            <Row className="testFinish unselectable">
              <div className="col-sm-12">
                <p>
                  <span>
                    You can't resume this mock.{" "}
                    <a href={`/admin/test/${id}/0`}>
                      <Button color="primary" size="xs">
                        Click here
                      </Button>
                    </a>{" "}
                    to begin this test.
                  </span>
                </p>
              </div>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </div>
  );
};

export default NotFinished;
