import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const TokenFinished = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} size="lg" className="AiPopupOuter">
      <span
        onClick={() => toggle(false)}
        style={{
          color: "#fff",
          opacity: "unset",
          position: "absolute",
          top: "3%",
          right: "3%",
        }}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </span>
      <div class="modal-header">
        <img src="/images/ai-logo.jpg" />
      </div>
      <ModalBody>
        <h2>Dear User</h2>
        <p>
          Your access to complimentary tokens for attempting questions has been
          exhausted. Upgrade to enjoy Unlimited Practice Questions with AI
          Scoring.
        </p>
        <div className="AiUpgradeBtn">
          <Link to={"/admin/plans"} className="modalCloseBtn btn">
            Upgrade
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TokenFinished;
