import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const PopUps = ({ mockData, setMockData }) => {
  const closeTimeoutModal = () => {
    setMockData((prevState) => {
      return { ...prevState, timeOutFlag: false };
    });
  };

  const closeSoundModal = () => {
    setMockData((prevState) => {
      return { ...prevState, soundModal: false };
    });
  };

  const toggleYes = () => {
    let pending = mockData.pending;
    let message = mockData.msg;
    if (pending === 0 || pending === 1) {
      if (
        message === "Do you want to submit test?" ||
        message === "Do you want to save this question and exit mock test?"
      ) {
        clearInterval(mockData.intervalId);
      }
    }
    setMockData((prevState) => {
      return { ...prevState, stopRecording: true };
    });
  };

  const toggle = () => {
    setMockData((prevState) => {
      return { ...prevState, modal: false };
    });
  };

  return (
    <>
      {mockData.timeOutFlag && (
        <Modal isOpen={mockData.timeOutFlag} size="sm">
          <ModalBody>
            <div>Test stopped due to timeout.</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={closeTimeoutModal}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {mockData.soundModal && (
        <Modal isOpen={mockData.soundModal} size="sm">
          <ModalBody>
            <div>Please wait for question audio to be finished.</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={closeSoundModal}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {mockData.modal && (
        <Modal isOpen={mockData.modal} size="sm">
          <ModalBody>
            <div>{mockData.msg}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={toggleYes}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={toggle}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default PopUps;
