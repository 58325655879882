import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import Sound from 'react-sound';
import {base,s3base} from "api.js";
import MicRecorder from 'mic-recorder-to-mp3';
import getBlobDuration from 'get-blob-duration';
import "components/custom.css";
import NoMicrophone from "views/NoMicrophone";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns.jsx";
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class ShortQue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:3,
      secondCount2:1,
      duration:0,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      volume:100,
      trans:'',
      correctFlag:true,
      beepStatus:false,
      file:'',
      playbackSpeed: 1,
      soundKey: 0,
      secondCount2Bckup: 0
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
  	 var intervalId = setInterval(this.timer, 1000);
	   this.setState({intervalId: intervalId});
  	}
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {correctFlag:true,recordCount:0,isRecording:false,secondCount:3,secondCount2:1,questionId: props.data.id,blobURL:'',reset:true,trans:'',soundStatus:true,duration:0};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}
  }


  start = () => {
    this.setState({ beepStatus:true});
  };

  handleBeepFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' })
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
    }
  }
  updateCorrect = () =>{
     this.props.submit({'data':''});
     this.setState({correctFlag:false})
  }
  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a});
    }
    
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          let file = new File([blob], 'noname5.mp3', { type: blob.type });
          this.setState({ blobURL, isRecording: false,recordCount:100, file:file });
          SpeechRecognition.stopListening();
          
          const dataToSubmit = {
            data: file,
            audio_text: this.state.trans,
            duration: duration
          };
          this.props.submit(dataToSubmit, true);
          
          //this.props.submit({'data':file },true)
          this.props.showResult();
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          //this.setState({ blobURL, isRecording: false,recordCount:100 });
          let file = new File([blob], 'noname5.mp3', { type: blob.type });
          SpeechRecognition.stopListening();
          //this.props.submit({'data':file },true)
          //this.props.showResult();
          this.props.nextQuestion({
            data: file,
            audio_text: this.state.trans,
            duration:duration
          });
        }).catch((e) => console.log(e));
    }else{
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      });
    }
  };

	timer = () => {
    if (this.state.secondCount === 0) {
      clearInterval(this.state.intervalId);
      
    }else{
      this.setState({ secondCount: this.state.secondCount -1 });  
    }
    
  }

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      if(this.state.secondCount2Bckup === 0) {
        this.setState({secondCount2Bckup: this.state.secondCount2});
      }
      this.setState({ secondCount2: this.state.secondCount2 -1 });  
    }
    
  }

  handleSongFinishedPlaying = () => {
    var intervalId = setInterval(this.timer1, 1000);
    this.setState({'soundStatus':false,intervalId: intervalId});
    this.props.soundChecked();
  } 

  changeRange = (e) => {
      this.setState({volume: e.target.value});
      console.log(this.state.volume);
  }

	timerRecord = () => {
		if (this.state.recordCount === 8) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed, secondCount2: this.state.secondCount2Bckup});
  }
  
  render() {
    if (this.state.isBlocked) {
      return(<NoMicrophone />);
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}
    
    return (
      <>
      <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <Col xs={12}>
      	<div className="col-lg-12 p-0 text-center">
      { !this.props.resultMode && <div>  <div className="currentStatus">
            <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
            {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }
            
            <br></br>
            Volume
             <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
             <br></br>
            <Progress color="info" value={(this.state.soundPer)} />
            {!this.props.testMode && (
              <div className="d-flex justify-content-between">
                <div className='stop-btn'>
                {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
                </div>
                <div className="stop-btn">
                  <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
                </div>
              </div>
            )}
            </p>
            
          </div>
		  <div className="audio-box">
        <h4>Recorded Answer</h4>
        <p><strong>Current status:</strong></p>
        <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": '' } </p>
          <p>  { this.state.secondCount == 0 && this.state.secondCount2 !== 0 && 'Recording starts in '+this.state.secondCount2+ ' seconds'  }</p>
        <hr></hr>
        {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
        {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
        <hr></hr>
        <Progress color="info" value={(this.state.recordCount*12.5)} />
        <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
      </div> </div> }
      <Col xs={12}>
        {!this.props.resultMode && this.props.data && this.state.soundStatus && this.state.secondCount === 0 && <Sound
          url={s3base + this.props.data.media_link}
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={this.handleSongFinishedPlaying}
          onPlaying={this.handleSongPlaying}
          volume={this.state.volume}    
          playbackRate={this.state.playbackSpeed}  
          key={this.state.soundKey}    
        />}
      </Col>
		  <Col xs={12}>
		  	{ this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
		  </Col>
		</div>
      </Col>      
      <Col xs={12}><h5>{question}</h5></Col> 
      {!this.props.resultMode && this.state.beepStatus && <Sound
                  url={s3base + "/ptedata/ptemedia/beep.mp3"}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleBeepFinishedPlaying}
                />}     
      </>
    );
  }
}
export default ShortQue;