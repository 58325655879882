import React, { useState } from "react";
import PopupExamDate from "components/Dashboard/Popup/ExamDate.jsx";
import PopupScore from "components/Dashboard/Popup/Score.jsx";

export default function Mocks(props) {
	const { count, desired_score, exam_date, days_left } = props;

	const [examDate, setExamDate] = useState(exam_date);
	const [daysLeft, setDaysLeft] = useState(days_left);
	const [targetScore, setTargetScore] = useState(desired_score);

	const [showExamPopup, setShowExamPopup] = useState(false);
	const [showScorePopup, setShowScorePopup] = useState(false);

	return (
		<>
			<div className="statBox greenBox">
				<div className="totalMocks">
					<span>Total Mocks <br />Practiced</span>
					<b>{count !== undefined ? count : 0}</b>
				</div>
				<div className="desiredScore d-flex flex-column text-center">
					<span>Desired Score</span>
					<span className="desiredValue">{targetScore !== undefined ? targetScore : 0}</span>
					<p className="mt-auto mb-0 align-self-end">
						<small onClick={() => { setShowScorePopup(true) }} className="fw-medium cursor-pointer passive-link">Set Score</small>
					</p>
				</div>
				<div className="examDate d-flex flex-column">
					<div className="row">
						<span className="col-auto">Exam Date</span>
						<p className="m-0 col d-flex justify-content-end">
							<small onClick={() => { setShowExamPopup(true) }} className="fw-medium cursor-pointer passive-link">Set Date</small>
						</p>
					</div>
					<span className="desiredValue">{examDate !== undefined ? examDate : ''}</span>
					<p  className="mt-auto mb-0 align-self-end">
						<small className="fw-medium daysLeft">{daysLeft !== undefined ? daysLeft : 0} Days Left</small>
					</p>
				</div>
			</div>

			{showExamPopup &&
				<PopupExamDate
					open={showExamPopup}
					setShowExamPopup={setShowExamPopup}
					exam_date={examDate}
					set_exam_date={setExamDate}
					set_days_left={setDaysLeft}
				/>
			}

			{showScorePopup &&
				<PopupScore
					open={showScorePopup}
					setShowScorePopup={setShowScorePopup}
					setTargetScore={setTargetScore}
					targetScore={targetScore}
				/>
			}
		</>
	);
}
