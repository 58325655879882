import React, { useState } from "react";
import {
  Button,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Modal,
  ModalBody
} from "reactstrap";
import API from "api.js";
import Header from "components/Frontend/Header";
import Footer from "components/Frontend/Footer";
import "assets/css/business-page.css"

export default function BusinessPage(props) {

	const [instituteForm, setInstituteForm] = useState(false);

	const showHideInstituteRequestForm = (status) => {
		setInstituteForm(status);
	}

	return (
		<React.Fragment>
			<Header />
			<div className="innerPage">
				<section className="businessBanner">
					<div className="container">
						<div className="row businessBannerInr">
							<div className="col-md-6 bBannerCntnt">
								<h2>Empower your institute<br /> with us!</h2>
								<div className="subHeading">AI powered portal to help<br /> boost your Students’ PTE Scores</div>
								<p>Over <span>300+ Institutes</span> have joined us to Increase thier<br /> Accuracy and got fast results.</p>
								<div className="trynowBtn btn whiteBtn" onClick={() => { showHideInstituteRequestForm(true) }}>Try Now</div>
							</div>
							<div className="col-md-6 bannerAiOuter">&nbsp;</div>
						</div>
					</div>
				</section>
				<section className="featuresInfo">
					<div className="container">
						<div className="row featuresInfoInr">
							<div className="col-md-12 featuresOuter">
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/clock-icon.png" />
									</div>
									<p>Real-Time Test Environment</p>
								</div>
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/grow-icon.png" />
									</div>
									<p>Detailed Analytics</p>
								</div>
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/accurate-icon.png" />
									</div>
									<p>Instant and Accurate Evaluation</p>
								</div>
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/mock-test.png" />
									</div>
									<p>Mock Tests</p>
								</div>
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/depth-icon.png" />
									</div>
									<p>In-Depth Feedback</p>
								</div>
								<div className="featureBlock">
									<div className="featureIcon">
										<img src="images/icon/file-icon.png" />
									</div>
									<p>Templates</p>
								</div>
							</div>
							<div className="col-md-12 taskFeatures">
								<div className="taskFeatureBlk tfBlk01">
									<div className="tfIcon">
										<img src="images/icon/question.png" />
									</div>
									<div className="tfInfo">
										<span className="tfTitle">15,000+ <samp>Questions</samp></span>
									</div>
								</div>
								<div className="taskFeatureBlk tfBlk02">
									<div className="tfIcon">
										<img src="images/icon/ai.png" />
									</div>
									<div className="tfInfo">
										<span className="tfTitle">AI Scoring <samp>For all modules</samp></span>
									</div>
								</div>
								<div className="taskFeatureBlk tfBlk03">
									<div className="tfInfo">
										<span className="tfTitle">12+ countries use<samp><img src="images/icon/flags-img.png" /></samp></span>
									</div>
								</div>
								<div className="taskFeatureBlk tfBlk04">
									<div className="tfIcon">
										<img src="images/icon/grey-file.png" />
									</div>
									<div className="tfInfo">
										<span className="tfTitle">Unlimited <samp>Mock Test</samp></span>
									</div>
								</div>
								<div className="tfMainCntnt">
									<h4>Now conduct tasks seamlessly with us</h4>
									<div className="tfScreen">
										<img src="images/icon/feature-screen.png" />
									</div>
									<div className="btn whiteBtn">Get Started</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="helpOuter">
					<div className="container">
						<div className="row helpInr">
							<div className="col-md-5 instImg">
								<img src="images/instImg.png" />
							</div>
							<div className="col-md-7 helpCntnt">
								<h2>How we help your student perfect their score?</h2>
								<p>Every PTE aspirant has some goal in their mind. Our site is a curation of several questions by the leading experts. You get a brilliant UI where each section is divided strategically, so students quickly find what they are looking for. The Portal Is designed to power your students’ preparation with new-age Artificial Intelligence. Built on PTE standard algorithms. The technology is quick. Within just a few seconds, evaluates your students’ response and gives the orrections, imitating a human-like response, but only quicker and more accurately. The 95% algorithm adoption from the PTE exam ensures that your student gets just 5% more or less marks and score almost as expected.</p>
								<div className="helpFeatures">
									<ul className="impo-list">
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Unlimited questions</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Unlimited mock tests</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Access templates</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Accurate the score card</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>In-Depth feedback</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Success Plan</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Progress tracking</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Instant results</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>Real time performance</b></li>
										<li><i className="fa fa-check-circle"></i> &nbsp;<b>& Much more</b></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />

			<InstituteForm instituteForm={instituteForm} closeForm={showHideInstituteRequestForm} />
		</React.Fragment>
	);
}


const InstituteForm = ({ instituteForm, closeForm }) => {

	const [taskName, setTaskName] = useState("");
	const [taskPhone, setTaskPhone] = useState("");
	const [taskEmail, setTaskEmail] = useState("");
	const [taskInstitute, setTaskInstitute] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const updateMessages = (type, message) => {
		switch(type) {
			case "error":
				setSuccessMessage("");
				setErrorMessage(message);
				break;
			case "success":
				setErrorMessage("");
				setSuccessMessage(message);
				break;
		}
	}

	const post20taskRequest = async (e) => {
		e.preventDefault();
		if(taskName === '' || taskPhone === '' || taskEmail === '' || taskInstitute === '') {
			updateMessages("error", "Please fill all fields!");
		} else {
			const bodyFormData = new FormData();
			bodyFormData.set('name', taskName);
			bodyFormData.set('phone', taskPhone);
			bodyFormData.set('email', taskEmail);
			bodyFormData.set('institute', taskInstitute);
			
			let res = null;
			try {
				res = await API({
					method: 'POST',
					url: '/task-request-submit-data',
					data: bodyFormData
				});
			} catch(error) {};

			if (res.status === 200) {
				updateMessages("success", "Request Submitted Successfully!");
				setTimeout(function() {
					window.location.reload();
				}, 2000);
			} else {
				updateMessages("error", "Unable to submit request!");
			}
		}
	}

	return (
		<Modal isOpen={instituteForm} id="anouncementPopup" size="lg" className="taskpopup">
			<ModalBody>
				<Container>
					<Row>
						<Col className="ml-auto mr-auto" lg="12" md="12">
							<button onClick={ (e) => { closeForm(false) }} type="button" className="close" data-dismiss="modal">&times;</button>
							<div className="mobileIcon">
								<img src="/images/experienced-trainer.png" alt="mobile-img" />
							</div>
							<Form action="" className="form" method="">
								<h3 className="header text-center">Get our portal for your institute</h3>
								
								{successMessage !== "" && 
									<Alert color="success">{successMessage}</Alert>
								}
								{errorMessage !== "" && 
									<Alert color="danger">{errorMessage}</Alert>
								}

								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="nc-icon nc-single-02" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Name"
										type="text"
										name="task_name"
										value={taskName}
										onChange={(e) => { setTaskName(e.target.value)} }
										autoComplete="off"
									/>
								</InputGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="nc-icon nc-mobile" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Phone"
										type="number"
										name="task_phone"
										value={taskPhone}
										onChange={(e) => { setTaskPhone(e.target.value)} }
										autoComplete="off"
									/>
								</InputGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="nc-icon nc-email-85" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Email"
										type="text"
										name="task_email"
										value={taskEmail}
										onChange={(e) => { setTaskEmail(e.target.value)} }
										autoComplete="off"
									/>
								</InputGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="nc-icon nc-badge" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Institute Name"
										type="text"
										name="task_institute"
										value={taskInstitute}
										onChange={(e) => { setTaskInstitute(e.target.value)} }
										autoComplete="off"
									/>
								</InputGroup>
								<br />
								<Button
									className="btn-round mb-3"
									color="warning"
									onClick={(e) => { post20taskRequest(e) }}
								>Submit</Button>
							</Form>
						</Col>
					</Row>
				</Container>
			</ModalBody>
		</Modal>
	);
}