import React from "react";
import UserDetails from "components/Dashboard/Sidebar/UserDetails.jsx";
import Menu from "components/Dashboard/Sidebar/Menu.jsx";
import withPasswordPopup from "./withPasswordPopup";

function Sidebar(props) {
	const smallViewSidebarCollapse = () => {
		document.body.classList.remove('openSidebar');
	}

	return (
		<>
			<div className="leftSidebar">
				<div className="sidebar-fixed">
					<div className="crossBtn">
						<img onClick={smallViewSidebarCollapse} src="https://cdn-icons-png.flaticon.com/512/59/59836.png" />
					</div>
					<UserDetails />
					<Menu />
				</div>
			</div>
		</>
	);
}

export default withPasswordPopup(Sidebar);