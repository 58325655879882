import React, { Component } from "react";

// reactstrap components
import { Col } from "reactstrap";
import reactStringReplace from "react-string-replace";
import jsxToString from "jsx-to-string";
import "components/custom.css";
import { arrayMove } from "react-sortable-hoc";
import ReactHtmlParser from "react-html-parser";
import WordDefinitionModal from 'components/PracticeMaterial/PopUps/WordDefinitionModal';
import ModalPopup from "components/PopUps/Modal";
import FillInTheBlankExplanation from "components/PracticeMaterial/PopUps/FillInTheBlankExplanation";


class WrittingBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      secondCount: 1200,
      showTimer: false,
      questionId: "",
      reset: false,
      resultObj: {},
      board: {
        lanes: [
          {
            id: 1,
            title: "ReArrange Lines",
            cards: [
              {
                id: 1,
                title: "Add card",
              },
              {
                id: 2,
                title: "AAdd card",
              },
            ],
          },
        ],
      },
      items: [],
      correctFlag: true,
      answerData: { answer: [], correct: [] },
      showTimer: false,
      wordDefinitionModalIsOpen: false,
      modalDestroy: true,
      isModalOpen:false,
      selectedWord: "",
      explanationModal: false
    };
  }
  componentDidMount() {
    console.log(this.props)
    const { isQuestionTime, questionTime } = this.props;
    if (isQuestionTime) {
      this.setState({
        secondCount: questionTime * 60,
        showTimer: isQuestionTime,
      });
    }
    var intervalId = setInterval(this.timer, 1000);
    this.setState({ intervalId: intervalId });
  }

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if (
        (state.questionId && state.questionId !== props.data.id) ||
        props.restart
      ) {
        clearInterval(state.intervalId);
        clearInterval(state.recordId);
        document.querySelectorAll(".span11").forEach((t) => {
          t.value = "";
        });

        const { isQuestionTime, questionTime } = props;
        let secondState = { secondCount: 1200, showTimer: false };
        if (isQuestionTime) {
          secondState = {
            secondCount: questionTime * 60,
            showTimer: isQuestionTime,
          };
        }

        return {
          correctFlag: true,
          ...secondState,
          questionId: props.data.id,
          reset: true,
          items: props.data.option,
          answerData: { answer: [], correct: [] },
        };
      } else {
        if (!state.questionId) {
          return { questionId: props.data.id, correctFlag: true };
        }
      }
    }
  }

  timer = () => {
    if (this.state.secondCount === 1) {
      clearInterval(this.state.intervalId);
      if (this.state.showTimer) {
        this.setState({ timeoutStat: true });
        this.props.nextQuestionHandler();
      }
    }
    this.setState({ secondCount: this.state.secondCount - 1 });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  changeSelect = (e) => {
    this.props.showResult();
    let tempData = this.state.answerData.answer.slice();
    tempData[e.target.id] = e.target.value;

    this.props.submit(
      {
        answer: tempData,
        correct: this.props.data.question
          .replace(/<[^>]*>?/gm, "x_x")
          .split("x_x")
          .filter(function (i, x) {
            if (x % 2 != 0) return i;
          }),
      },
      true
    );
    this.setState({
      resultObj: {
        answer: tempData,
        correct: this.props.data.question
          .replace(/<[^>]*>?/gm, "x_x")
          .split("x_x")
          .filter(function (i, x) {
            if (x % 2 != 0) return i;
          }),
      },
      answerData: {
        answer: tempData,
        correct: this.props.data.question
          .replace(/<[^>]*>?/gm, "x_x")
          .split("x_x")
          .filter(function (i, x) {
            if (x % 2 != 0) return i;
          }),
      },
    });
  };

  showAnswer = () => {
    if (!this.props.resultMode) {
      let elem = document.querySelectorAll(".checkedSign");
      for (var i = 0; i <= elem.length - 1; i++) {
        let id = elem[i].dataset.id;

        if (
          elem[i].dataset.text.trim() ==
          document.getElementById(id).value.trim()
        ) {
          elem[i].innerHTML =
            '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
        } else {
          elem[i].innerHTML =
            '<i class="fa fa-times" style="font-size:32px;color:red"></i>';
        }
      }
    }
  };

  stopTest = () => {
    const data = { ...this.state.resultObj };
    if (this.state.showTimer) {
      data.time = this.state.secondCount;
    }
    this.props.nextQuestion(data);
  };

  updateCorrect = () => {
    const data = {
      answer: [],
      correct: this.props.data.question
        .replace(/<[^>]*>?/gm, "x_x")
        .split("x_x")
        .filter(function (i, x) {
          if (x % 2 != 0) return i;
        }),
    };

    if (this.state.showTimer) {
      data.time = this.state.secondCount;
    }

    this.props.submit(data);
    this.setState({
      correctFlag: false,
      resultObj: {
        answer: [],
        correct: this.props.data.question
          .replace(/<[^>]*>?/gm, "x_x")
          .split("x_x")
          .filter(function (i, x) {
            if (x % 2 != 0) return i;
          }),
      },
    });
  };


  openCloseWordDefinitionModal = (status, word="") => {
    this.setState({
      wordDefinitionModalIsOpen: status
    }, () => {
      if (!status) {
        setTimeout(() => {
          this.setState({
            modalDestroy: true,
            selectedWord: ""
          });
        }, 500);
      } else {
        this.setState({
          modalDestroy: false,
          selectedWord: word
        });
      }
    });
  }


  wordClickHandle = (e, word) => {
    e.preventDefault();
    this.openCloseWordDefinitionModal(true, word);
  }
  
  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  toggleModalExplanation = () => {
    this.setState({
      explanationModal: !this.state.explanationModal
    });
  }

  renderQuestionParagraph = (item, ques, correct) => {
    const question = reactStringReplace(
      ques.replace(/<[^>]*>?/gm, "x_x").replace(/x_x(.*?)x_x/g, ""),
      /(__add_blank__)/g,
      (m, j, p) => (
        <React.Fragment key={j}>
          <select
            className="span11"
            id={j}
            onChange={(e) => {
              this.changeSelect(e);
            }}
          >
            <option></option>
            {item[(j - 1) / 2].options.split(",").map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
          {this.props.answer && (
            <span>
              <span
                className="checkedSign"
                style={{ display: this.props.answer ? "inline-block" : "none" }}
                data-id={j}
                data-text={correct[(j - 1) / 2]}
              ></span>
              <span className="correct">{correct[(j - 1) / 2]}</span>
              {correct[(j - 1) / 2] !== this.state.answerData.answer[j] && (
                <span>
                  <i
                    className="fa fa-question cursor-pointer blanks explanation-icon"
                    onClick={() => {
                      this.setState({
                        answer_explaination_key: correct[(j - 1) / 2],
                      });
                      this.toggleModalExplanation();
                    }}
                  ></i>
                </span>
              )}
            </span>
          )}
        </React.Fragment>
      )
    );

    // re-format question
    let html = [];
    question.map((item, i) => {
      if (typeof item === "string") {
        item.split(" ").map((word, i) => {
          html.push(
            reactStringReplace(word, word, (match, i, o) => (
              <span
                key={i}
                className="question-para-word speach-text"
                onClick={(e) => this.wordClickHandle(e, word)}
              >{word}</span>
            ))
          );
          html.push(" ");
        });
      } else {
        html.push(item);
      }
    });

    return html.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
        </React.Fragment>
      )
    });
  }

  render() {
    const {isModalOpen}=this.state;
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.answer) {
      this.showAnswer();
    }

    if (this.props.stopRecording) {
      this.stopTest();
    }
    let item = [];
    let question = "";
    let correct = [];
    if (this.props.data) {
      question = this.props.data.question;
      correct = this.props.data.question
        .replace(/<[^>]*>?/gm, "x_x")
        .split("x_x")
        .filter(function (i, x) {
          if (x % 2 != 0) return i;
        });
      if (this.state.items.length === 0) {
        this.setState({ items: this.props.data.option });
      }

      item = this.props.data.option;

      if (this.state.correctFlag) {
        this.updateCorrect();
      }
    }

    if (this.state.reset) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({ intervalId: intervalId, reset: false });
    }
    return (
      <>
        {this.state.showTimer && (
          <Col xs={12} className="text-right">
            <b>
              <p>
                <i className="fa fa-clock-o"></i>{" "}
                <span>
                  Time Left:{" "}
                  {Math.floor(this.state.secondCount / 60) +
                    ":" +
                    (this.state.secondCount % 60)}
                </span>
              </p>{" "}
            </b>
          </Col>
        )}
        <Col xs={12}>
          <h4 className="infoText"></h4>
        </Col>

        <Col xs={12}>
          <h5>
            {this.props.data &&
              !this.props.resultMode &&
                this.renderQuestionParagraph(item, question, correct)
            }
          </h5>
        </Col>

        <Col xs={12}>
          <h3 className="question">
            {this.props.data && this.props.data.question_mcq}
          </h3>
        </Col>
        <Col xs={12}>
          {this.props.data && this.props.resultMode && <h4>Correct answer</h4>}
          <h5>
            {this.props.data &&
              this.props.resultMode &&
              ReactHtmlParser(
                question
                  .replace(/<span[^>]*>?/g, '<b class="correct">')
                  .replace(/<[^>]*span>?/g, "</b>&nbsp;")
                  .replace(/__add_blank__/g, "")
              )}
          </h5>
        </Col>
        <Col xs={12}>
          {this.props.data && this.props.resultMode && <h4>Your response</h4>}
          <h5>
            {this.props.data &&
              this.props.resultMode &&
              ReactHtmlParser(
                question
                  .replace(/<span[^>]*>?/g, '<b class="selected-option">')
                  .replace(/<[^>]*span>?/g, "</b>&nbsp;")
                  .replace(/__add_blank__/g, "")
              )}
          </h5>
        </Col>

        {!this.props.resultMode && !this.props.testMode &&
          <Col xs={12}>
            <div className="row shadowing">
              <div className="word-definition">
                {!this.state.modalDestroy &&
                  <WordDefinitionModal
                    isOpen={this.state.wordDefinitionModalIsOpen}
                    toggle={this.openCloseWordDefinitionModal}
                    word={this.state.selectedWord}
                    languages={this.props.languages}
                  />
                }
              </div>
            </div>
          </Col>
        }
        {isModalOpen && (
          <ModalPopup
            status={isModalOpen}
            toggle={this.toggleModal}
            showCloseBtn={true}
            render={(toggle) => <span>
                  <h2 className="modal-header modal-title">{
                  `${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`
                  }</h2>
              <p className="mb-2 definition-text">
                {this.props.option_explaination[this.state.answer_explaination_key.toLowerCase()]}
            
                </p>
            </span>}
          />
        )}

        {this.state.explanationModal &&
          <FillInTheBlankExplanation
            isOpen={this.state.explanationModal}
            title={`${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`}
            // data={this.props.option_explaination?this.props.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Explanation...'}
            data={this.props.option_explaination}
            keyName={this.state.answer_explaination_key}
            closeModal={this.toggleModalExplanation}
          />
        }
      </>
    );
  }
}

export default WrittingBlank;
