import React from 'react';
import { Link} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWall from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/VideoPTETestimonial';
import Packages from '../components/Frontend/Packages';
import WhyLove from '../components/Frontend/WhyLove';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Pte extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet>
        <title>PTE Coaching Classes in Parramatta | Language Academy</title>
        <meta name="twitter:title" content="Best PTE Coaching Classes In Parramatta | Top PTE Training Institute In Sydney" />
	  	<meta property="og:title" content="Best PTE Coaching Classes In Parramatta | Top PTE Training Institute In Sydney" />
	  	<meta name="description" content="Attend PTE coaching classes in Parramatta with Language Academy. Benefit from expert instruction and tailored practice to excel in your PTE exam." />

        <meta name="twitter:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
        <meta property="og:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
    </Helmet>
    <Header />
    	<div className="innerPage">
    	<section className="bannerOuter">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 text-center">
						<h1>Achieve all your dreams with the<br />Best PTE Coaching Classes in Parramatta</h1>
						<span>Join the top PTE Training Institute and hone your English skills under the guidance of PTE Experts</span><br />
						<br />
						<br />
						<Link to="/auth/register" className="themeBtn">Register Now</Link>
						<br/>
						<a href="javascript:;" className="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
					</div>
				</div>
			</div>
		</section>
		</div>
		<section className="pteInfo">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 text-center">
						<p>PTE - A ( Pearson Test of English Academic) is a highly popular exam among the people from all corners of the globe, who want to accomplish their dream of studying abroad or become a permanent resident of an English speaking country like Australia. This well-designed computer-based test assesses the candidate's English language proficiency on his/her Speaking, Writing, Reading, and Listening skills.</p>

						<p>To smash the PTE exam with an excellent score, a person has to perform well in all of these four sections, as being weak in any of these might ruin your overall exam performance and final result.</p>

						<p>Is clearing the test seems like a daunting task to you because of your weak areas in English? Are you confused about how to create a proper roadmap for your PTE preparation? Don't worry! under the guidance of experts from the top PTE training institute, you can prepare well for the exam and pave your way to success.</p>

						<p>Language Academy, the institute known for providing the best PTE coaching classes in Parramatta, strongly focuses on the students’ strengths and weaknesses to define the right strategy for each of them. Noting their weak areas, we help them to improve these with our expert tips, latest study & practice material, and mock tests. The exam is not a hard nut to crack if you follow all the points taught during the PTE classes. With your sincere efforts and our direction, you can easily ace the exam.</p>

						<p>Do you want to join the top PTE Institute in Parramatta? Call us on <a style={{color:"#51cbce"}} href="tel:61426230061">+61 426230061</a> for all your queries and doubts. We provide both online coaching and in-class PTE training in Parramatta for the students who are planning to pursue their higher studies. For booking the free demo PTE classes in Parramatta, you can drop us a mail at <a style={{color:"#51cbce"}} href="mailto:info@languageacademy.com.au">info@languageacademy.com.au</a> We would love to assist! So, are you ready to start your journey to success with the best PTE coaching centre?</p>
						<div className="pteCntnt text-center">
							<div className="row cardsOuter">

								<div className="col-sm-4">
									<img src="images/online-coching.png" alt="online-coching" />
									<h3>Online PTE Coaching Classes</h3>
									<p>Get the best online PTE trainer for your exam preparation.</p>
								</div>


								<div className="col-sm-4">
									<img src="images/book.png" alt="book" />
									<h3>Study Material</h3>
									<p>We have infinite resources & study material for PTE exam preparation.</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="packages widePackages">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 text-center">
						<h2 className="title">Packages</h2>
						<p>Get in touch with us to know more about online and face to face cource classes.</p>
						<div className="featurePlans">
						   <div className="featurePackage">
						      <h3>Bronze</h3>
						      <ul>
						         <li>Validity 10 Sessions</li>
						         <li>Method of Delivery Online</li>
						         <li>Access to all our proven methods & strategies</li>
						         <li>1-1 Personalised Feedback</li>
						         <li>Access to Online Resources</li>
						         <li>Online Mock Tests</li>
						      </ul>
						   </div>
						   <div className="featurePackage">
						      <h3>Silver</h3>
						      <ul>
						         <li>Validity 4-week</li>
						         <li>Method of Delivery Face-2-Face</li>
						         <li>Access to all our proven methods & strategies</li>
						         <li>1-1 Personalised Feedback</li>
						         <li>Access to Online Resources</li>
						         <li>Online Mock Tests</li>
						         <li>Class Mock Tests</li>
						         <li>Access to Practice Lab</li>
						      </ul>
						   </div>
						   <div className="featurePackage green">
						      <h3>Gold</h3>
						      <ul>
						         <li>Validity Unlimited (*Valid for 1 Year)</li>
						         <li>Method of Delivery Face-2-Face</li>
						         <li>Access to all our proven methods & strategies</li>
						         <li>1-1 Personalised Feedback</li>
						         <li>Access to Online Resources</li>
						         <li>Unlimited Classes</li>
						         <li>Online Mock Tests</li>
						         <li>Class Mock Tests</li>
						         <li>Access to Practice Lab</li>
						      </ul>
						   </div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Testimonial title="PTE Success Stories" />
		<WhyLove />
		<FameWall />
    	<Footer />
    </React.Fragment>);
    }
}

export default Pte;
