import React from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import API from "../../api";

const TipVideo = ({ showVideoPopUp, video, setPopUpVideo }) => {
  const markAsView = (e) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.set("vid", video.id);
    API({
      method: "POST",
      url: "mark/viewed",
      data: bodyFormData,
    });

    setPopUpVideo(null);
  };
  return (
    <>
      <Modal isOpen={showVideoPopUp} size="lg">
        <div className="bg-success modalHeader">
          <button
            onClick={(e) => {
              markAsView(e);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h2 className="text-center text-light">New Tips and Strategies</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={12} className="modal-yt-video">
              <iframe
                src={`https://www.youtube.com/embed/${video.youtube_vid}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TipVideo;
