import React from 'react';
import { Link,Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallNaati from '../components/Frontend/FameWallNaati';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTestimonial from '../components/Frontend/VideoNaatiTestimonial';
import NaatiTut from '../components/Frontend/NaatiTut';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        course:'NAATI CCL Online',
        lang:'Hindi',
        query:'',
        flag:false,
        readMoreFlag:false,
        loginLock:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});

      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "IELTS coaching Sydney");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {


      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }


  }

  changeReadMore = () =>{
    this.setState({readMoreFlag:!this.state.readMoreFlag})
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){
  if (this.state.flag) {
    return (<Redirect to="/thank-you" />);
  }
    return (
    <React.Fragment>
    <Helmet>
        <title>NAATI CCL Coaching in Sydney | Language Academy</title>
        <meta name="twitter:title" content="IELTS Classes in Sydney - Language Academy" />
        <meta property="og:title" content="IELTS Classes in Sydney - Language Academy" />
        <meta name="description" content="Attend NAATI CCL coaching classes in Sydney with Language Academy. Benefit from expert instruction and targeted practice to achieve your certification." />

        <meta name="twitter:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
        <meta property="og:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
    </Helmet>
<div className="innerPage pteOnline ieltsClasses naatiCCLSydney">
<Header />
<section className="bannerOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1>Training Centre in Sydney for NAATI CCL Exam Preparation</h1>
        <Link to="/contact" className="themeBtn">Contact us</Link>
      </div>
    </div>
  </div>
</section>
<section className="ieltsTraining">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 ">
        <div className="text-center">
        <h2 className="title"><span>Why Sydney</span> Is the Best Location for NAATI CCL Coaching Classes?</h2>
        </div>
        <p>Sydney is one of the most popular destinations for higher education among international students. Preparing for NAATI CCL Coaching in Sydney helps gain the five precious points towards Australian PR. Sydney has many government and private educational institutes, which makes it an excellent place to pursue higher education.</p>
        <p>Sydney has a diverse population of various nations and ethnicity. Citizens welcome and accommodate any international student with open hearts. This is one of the reasons why NAATI CCL Coaching Classes in Sydney are the most preferred choices among the entire continent.</p>
        {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
            {this.state.readMoreFlag && <React.Fragment><h5>How Can NAATI CCL Coaching Classes in Sydney Help You?</h5>
        <p>When you think of taking a NAATI CCL test, you need to understand this clearly that NAATI CCL can be taken both online and offline. However, the testing body doesn’t run any NAATI CCL Classes in Sydney of its own. </p>
        <p>Language Academy offers both online and offline courses, which are very helpful in grabbing the five essential points for Australian PR. Students who can come to our campus can enrol in our on-campus program. The students who are unable to attend our on-campus course due to some reasons can enrol in our online course.</p>
        <p>We are a very popular NAATI CCL training centre in Sydney with the highest success rate.</p>
        <h5>What is NAATI CCL?</h5>
        <p>NAATI CCL Test helps in assessing an aspirant’s language skills at a community level in many languages. It assesses the excellence of an aspirant to translate the conversation dialogues from Language other than English (LOTE) to English and vice versa. We at Language Academy help the aspirants to take the NAATI CCL test in 10 different languages like Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati and Sinhalese.</p>
        <p>The test has a recording of dialogues between people, and test-takers have to listen to these recordings. There are two dialogues in each recording, and each has 35-word short stanzas, carrying 45 marks. Securing a minimum of 29 is mandatory to qualify for a valid score.</p></React.Fragment>}
        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
      </div>
    </div>
  </div>
</section>
<section className="practicePortal">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">Why choose <span>Language Academy NAATI CCL Exam Preparation</span></h2>
        <div className="row whyloveCntnt">
          <div className="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>Our certified faculties help you to use their expertise in improving your reading, writing, listening, and speaking sections.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>We continuously involve personally with every student, which enables them to unleash the potential in them and achieve the highest scores possible.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free CCL Books</h3>
            <p>Every student at the Language Academy gets templates to study and understand the best way to write an essay and leave the best impression.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Students can easily access the study material and take practice tests through our mobile app. Just in a few taps, they can get started.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>During the entire class duration, our experts can be reached out to seek any help. They will answer all your questions and help you anywhere you stuck.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>100 + Audio Dialogues</h3>
            <p>We have curated several previous question papers to help our students in practicing the common questions that are asked frequently.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our experts note the weaknesses and strengths of students during the discussion sessions to devise personalized study plans for them.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>Our costs are pocket-friendly so the students can easily pay off their fees and improve their score.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>Our mock tests provide the students with the actual experience of taking tests. Students can test their reading, listening, and writing skills.</p>
          </div>
        </div>
        <button className="themeBtn">Register for a FREE Trial Session</button>
      </div>
    </div>
  </div>
</section>
<VideoTestimonial title="Success Stories" />

<section className="practicePortal">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">Language Academy <span>CCL App</span></h2>
        <p>This mobile app consists of preparation with a list of all the comprehensive vocabulary as well as dialogues with sample answers.</p>
        <div className="row whyloveCntnt">
          <div className="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Vocabulary in 7 different categories</h3>
            <p>A complete list of 2000+ words is included and explained in the various native language along with the translation.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Mock tests like real-time Exam</h3>
            <p>The feel of the actual test environment helps you to understand properly what can you expect and how to perform.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Comprehensive practice dialogue</h3>
            <p>Dialogues from different categories and scenarios similar to the ones that came in previous exams are included in it.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>80+ practice dialogues</h3>
            <p>Dialogue with sample responses and answers prepared by certified interpreters are included.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Last-minute package</h3>
            <p>Short packages are given with tips to boost your confidence and prepare well before the Exam.</p>
          </div>
        </div>
        <p>Let’s make your learning meaningful and successful!</p>
        <button className="themeBtn">Get Started</button>
      </div>
    </div>
  </div>
</section>
<section className="getWithUs">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className="title text-center">What You Will <span>Get With Us</span></h2>
        <div className="row">
          <div className="col-sm-8">
            <div className="ourFeatures">
              <ul className="checklist">
                <li>Full Access to CCL App</li>
                <li>Access to comprehensive practice dialogues with Sample Answers</li>
                <li>Access to Last Minute Practice dialogues with Sample Answers</li>
                <li>Access to Mock Tests with Sample Answers</li>
                <li>Access to comprehensive vocabulary with answers</li>
                <li>Live Practice Sessions with NAATI certified Interpreter</li>
                <li>Live weekly mock test with Feedback</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            <div className="naatiCoaching">
              <h3>Take a FREE Demo Class</h3>
              <form onSubmit={this.submitForm}>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Email', this.state.email, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={this.state.phone}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </div>
                <div className="fieldOuter">
                  <select
                  name="course"
                  value={this.state.course}
                  onChange={(e) => { this.changeInput(e)} }>
                    <option value="NAATI CCL Online">NAATI CCL Online</option>
                    <option value="PTE On-Campus">PTE On-Campus</option>
                    <option value="PTE Online4">PTE Online</option>
                    <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                    <option value="IELTS Online1">IELTS Online</option>
                    <option value="IELTS On-Campus1">IELTS On-Campus</option>
                  </select>
                </div>
                <div className="fieldOuter">
                  <select
                  name="lang"
                  value={this.state.lang}
                  onChange={(e) => { this.changeInput(e)} }>
                    <option value="Hindi">Hindi</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Nepali">Nepali</option>
                    <option value="Bangla">Bangla</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Sinhalese">Sinhalese</option>
                  </select>
                </div>
                <div className="fieldOuter">
                  <input type="text"
                    name="query"
                    placeholder="Comments/Query"
                    value={this.state.query}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Query', this.state.query, 'required')}
                </div>
                <div className="fieldOuter">
                  {!this.state.loginLock && <button className="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                  {this.state.loginLock && <button className="themeBtn" >Wait...</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="whyLove">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">How Does it <span>Works?</span></h2>
        <div className="row whyloveCntnt">
          <div className="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Step 1 - Orientation Class</h3>
            <p>Here you'll learn the basics of NAATI. The experts will guide you on how to gain maximum score in the test.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Step 2 - Live Practice Session</h3>
            <p>Our trainers will monitor your performance, and will also give you feedback on your dialogues and vocab.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Step 3 - App for CCL Practice</h3>
            <p>Access to CCL app helping you in excellent listening practice with audio recordings and sample answers.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Step 4 - Weekly Mock Test</h3>
            <p>You will have weekly tests and will also get a full scorecard suggesting your scores, and areas of improvement.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Step 5 - Final Consultation</h3>
            <p>Once you complete the course, our experts will tell you how much more practice you should do before you book the exam.</p>
          </div>
        </div>
        <button className="themeBtn">Achieve Your Desired Score</button>
      </div>
    </div>
  </div>
</section>
<FameWallNaati />
<section className="WhyLove">
  <div className="container">
    <div className="row whyloveCntnt text-center">
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>5K +</h3>
        <p>Students Taught</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>5000+</h3>
        <p>Successful Students</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>1K +</h3>
        <p>Online Success Stories</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>5K +</h3>
        <p>Total Success Stories</p>
      </div>
    </div>
  </div>
</section>
<Testimonial />
<section className="mapOuter">
  <div className="container-fluid p-0">
    <div className="row">
      <div className="col-sm-12">
        <iframe style={{width: '100%', height: '500px'}} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Suite%20502%20Level%205/630%20George%20St,%20Sydney%20NSW%202000,%20Australi+(Language%20Academy%20Sydney%20-%20PTE,%20IELTS%20&amp;%20NAATI%20CCL%20Coaching%20Classes)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
    </div>
  </div>
</section>
<NaatiTut title="NAATI CCL Preparation Tutorial Videos" />
    <Footer />
      </div>
    </React.Fragment>);
    }
}

export default Pte;
