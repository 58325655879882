import { useEffect, useState } from "react";
import API from "../api";
import { useDispatch } from "react-redux";
import { setAlert } from "action/index";

const useHttp = (url, method = "GET", data = [], sendRequest = true) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        let response = await API({
          method: method,
          url: url,
          data: data,
        });
        if (response.status >= 200 && response.status < 300) {
          setResponse(response.data);
        } else {
          setError(true);
          dispatch(
            setAlert({
              flag: true,
              type: 2,
              message: "Something went wrong.",
            })
          );
        }
      } catch (e) {
        // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // +  catch not getting errors because here in api.js promise chain in broken  +
        // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        console.log(e);
      }
    };

    sendRequest && fetch();
  }, []);

  return [response, error];
};

export default useHttp;
