/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
class FameWall extends React.Component {
constructor(props) {
  super(props);
  this.state = {
    homePage:false
  }
}
componentDidMount() {
    
}
 render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
	return (
	<section className="fameWall">
    <div className="container">
        <div className="row">
            <div className="col-sm-12 text-center">
                <h2 className="title">Wall of <span>Fame</span></h2>
                <p>Meet our champions who have made it in the PTE / NAATI CCL exam</p>
                <Slider {...settings} classNameName="fameSlider regular slider">
                    <div>
                      <img src="/images/pte/pte1.jpeg" alt="fame-img-01" />
                    </div>
                    <div>
                      <img src="/images/pte/pte2.jpeg" alt="fame-img-02" />
                    </div>
                    <div>
                      <img src="/images/pte/pte3.jpeg" alt="fame-img-03" />
                    </div>
                    <div>
                      <img src="/images/pte/pte4.jpeg" alt="fame-img-04" />
                    </div>
                    <div>
                      <img src="/images/pte/pte5.jpeg" alt="fame-img-05" />
                    </div>
                    <div>
                      <img src="/images/pte/pte6.jpeg" alt="fame-img-01" />
                    </div>
                    <div>
                      <img src="/images/pte/pte7.jpeg" alt="fame-img-02" />
                    </div>
                    <div>
                      <img src="/images/pte/pte8.jpeg" alt="fame-img-03" />
                    </div>
                    <div>
                      <img src="/images/pte/pte9.jpeg" alt="fame-img-04" />
                    </div>
                    <div>
                      <img src="/images/pte/pte10.jpeg" alt="fame-img-05" />
                    </div>
                    <div>
                      <img src="/images/pte/pte11.jpeg" alt="fame-img-05" />
                    </div>
                    <div>
                      <img src="/images/pte/pte12.jpeg" alt="fame-img-05" />
                    </div>
                </Slider>
                <Slider {...settings} classNameName="fameSlider regular01 slider">
                    <div>
                      <img src="/images/naati/punjabi3.jpg" alt="fame-img-01" />
                    </div>
                    <div>
                      <img src="/images/naati/urdu1.jpg" alt="fame-img-02" />
                    </div>
                    <div>
                      <img src="/images/naati/hindi3.jpg" alt="fame-img-03" />
                    </div>
                    <div>
                      <img src="/images/naati/nepali4.jpg" alt="fame-img-04" />
                    </div>
                    <div>
                      <img src="/images/naati/bangla1.jpg" alt="fame-img-05" />
                    </div>
                    <div>
                      <img src="/images/naati/nepali6.jpg" alt="fame-img-01" />
                    </div>
                    <div>
                      <img src="/images/naati/urdu2.jpg" alt="fame-img-02" />
                    </div>
                    <div>
                      <img src="/images/naati/urdu3.jpg" alt="fame-img-03" />
                    </div>
                    <div>
                      <img src="/images/naati/hindi2.jpg" alt="fame-img-04" />
                    </div>
                    <div>
                      <img src="/images/naati/nepali2.jpg" alt="fame-img-05" />
                    </div>
                    <div>
                      <img src="/images/naati/nepali3.jpg" alt="fame-img-05" />
                    </div>
                </Slider>
                <Link to="/scorecard"><button className="themeBtn">Visit our Wall of Fame</button></Link>
            </div>
        </div>
    </div>
</section>
);
}
}
export default FameWall;