import { useDispatch } from "react-redux";
import API from "../api";
import { setToken, setUser, setSub, expVidPopup, setPasswordPopup } from "action/index.js";
import { useHistory } from "react-router-dom";

const useLogOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async () => {
    let response = await API({
      method: "POST",
      url: "logout",
    });

    if (response.status && response.status === 200) {
      dispatch(setToken(null));
      dispatch(setUser(null));
      dispatch(setSub(false));
      dispatch(expVidPopup(0));
      dispatch(setPasswordPopup(false));
      history.push("/auth/login?logout");
    }
  };

  const logoutDirectly = () => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(setSub(false));
    dispatch(expVidPopup(0));
    dispatch(setPasswordPopup(false));
    history.push("/auth/login");
  };

  return { logout, logoutDirectly };
};

export default useLogOut;
