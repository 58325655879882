import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import close from "../../assets/images/close.svg";
import Attachment from "./Attachment";

const Notification = ({ notification, clicked, handleClear }) => {
  return (
    <Accordion
      key={notification.id}
      className={
        "mb-2 accordion-notification" +
        (clicked === notification.id ? " clear-notification" : "")
      }
      expanded
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <div>
          <Typography>{notification.title}</Typography>
          <span
            style={{
              position: "absolute",
              top: "4%",
              right: "1%",
            }}
            onClick={() => handleClear(notification.id)}
          >
            <img
              style={{
                maxWidth: "15px",
                width: "auto",
                cursor: "pointer",
              }}
              src={close}
              alt=""
            />
          </span>
        </div>
      </AccordionSummary>
      {(notification.message || notification.attachment) && (
        <AccordionDetails className="">
          {notification.attachment && (
            <Attachment notification={notification} />
          )}
          {notification.message && (
            <Typography className="">{notification.message}</Typography>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default Notification;
