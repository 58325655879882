import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

const categoriesName = ["Speaking", "Writing", "Reading", "Listening"];

const MockInfo = ({
  setShowMockInfo,
  initialTime,
  categoriesData,
  category,
}) => {
  const categoryName = categoriesName[category - 1];

  const subCategoriesNames = categoriesData
    .sort((a, b) => {
      return a.order - b.order;
    })
    .map((subCategory) => {
      return (
        <li
          style={{ textTransform: "capitalize" }}
          key={subCategory.sub_categories.id}
        >
          {subCategory.sub_categories.title}
        </li>
      );
    });

  return (
    <div className="content">
      <Row>
        <Col xs={12}>
          <Card style={{ minHeight: "80vh" }}>
            <CardHeader>
              <CardTitle tag="h4">
                Sectional Mock Test - {categoryName} Module
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="mb-3">
                This Mock Test will contain the following questions:
              </div>
              <ul style={{ listStyle: "circle" }}>{subCategoriesNames}</ul>
              <div className="mt-4">
                <div className="my-3">
                  Time Allowed: <b>{initialTime} minutes</b>
                </div>
                <div className="my-3">
                  Please note that all the questions appearing in this mock
                  contribute to the {categoryName} score.
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <div className="bottomBtns">
            <Row>
              <Col xs={9}></Col>
              <Col xs={3}>
                <Button
                  color="primary"
                  size="xs"
                  onClick={() => setShowMockInfo(false)}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MockInfo;
