import React, { useState, useEffect } from "react";

export default function EstimatedScore(props) {

	const { estimatedScore } = props;
	return (
		<div className="col-md-12 col-xl-4">
			<h3 className="">Estimated Score in the Exam</h3>
			<div className="scoreImg position-relative">
				<span className="scoreValue">{estimatedScore !== undefined ? estimatedScore : 'N/A'}</span>
				<svg className="mw-100" width="284" height="284" viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g filter="url(#filter0_d_2_180)">
						<path d="M142 0C71.86 0 15 56.86 15 127C15 197.14 71.86 254 142 254C212.14 254 269 197.14 269 127C269 56.86 212.14 0 142 0ZM142 224.02C88.42 224.02 44.98 180.58 44.98 127C44.98 73.42 88.42 29.98 142 29.98C195.58 29.98 239.02 73.42 239.02 127C239.02 180.58 195.58 224.02 142 224.02Z" fill="white"/>
					</g>
					<path d="M248 126.98C248 150.864 240.06 172.928 226.68 190.671L237.06 196.892C251.47 177.319 260 153.144 260 126.97C260 62.8191 208.8 10.6103 145.03 9V21.012C202.08 22.6223 248 69.5403 248 126.98Z" fill="#B0117A"/>
					<path d="M36 126.98C36 113.188 38.65 99.9954 43.47 87.8934L33.03 81.6323C27.22 95.6047 24 110.917 24 126.98C24 165.977 42.92 200.562 72.07 222.046L78.29 211.664C52.62 192.301 35.99 161.546 35.99 126.98H36Z" fill="#B5C004"/>
					<path d="M138.97 21.012V9C93.32 10.1502 54.12 37.2348 35.52 76.0614L45.9 82.2824C62.38 46.9664 97.77 22.1722 138.97 21.012Z" fill="#4F4F4E"/>
					<path d="M142 232.998C120.29 232.998 100.08 226.427 83.26 215.185L77.06 225.527C95.69 237.829 118.01 245 142 245C178.81 245 211.68 228.137 233.32 201.723L222.86 195.452C203.4 218.405 174.38 232.998 142 232.998Z" fill="#0659B2"/>
					<defs>
						<filter id="filter0_d_2_180" x="0" y="0" width="284" height="284" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix"/>
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
							<feOffset dy="15"/>
							<feGaussianBlur stdDeviation="7.5"/>
							<feComposite in2="hardAlpha" operator="out"/>
							<feColorMatrix type="matrix" values="0 0 0 0 0.659896 0 0 0 0 0.695747 0 0 0 0 0.875 0 0 0 0.25 0"/>
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_180"/>
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_180" result="shape"/>
						</filter>
					</defs>
				</svg>
			</div>
		</div>
	);
}
