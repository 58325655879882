/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
class ClientFeedback extends React.Component {
constructor(props) {
  super(props);
  this.state = {
    homePage:false
  }
}
componentDidMount() {
    
}
 render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
	return (
    <section id="testimonial-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>What Our Clients Say</h2>
          <p>One of the best PTE Academic Exam preparation tools to evaluate your performance</p>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="testi-box">
              <div class="content-testimonial">
                <div class="container my-4">
                  <Slider {...settings} classNameName="fameSlider regular slider">

                          
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                            <div class="card mb-2">
                              <div class="card-body">
                                <p class="card-text">"Abore et dolore magna aliqua. Ut veniam, Consectetur adipiscing enim ad minim sed do eiusmod tempor incididunt ut l quis nostrud exercitation ullamco laboris nisi."</p>
                                <img class="card-img-top" src="/assets/img/leesa.png"
                                   alt="Card image cap" />
                                 <div class="ratings">*****</div>
                                 <p class="client-name">Leesa Bell</p>
                              </div>
                            </div>
                          

                          
                  </Slider>
                  


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);
}
}
export default ClientFeedback;