import React,{Component} from "react";
import {
  Col,
  Progress,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import { base, s3base } from "api.js";
import Sound from 'react-sound';
import "components/custom.css";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";

class Dictation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      content:'',
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{},
      playbackSpeed: 1,
      soundKey: 0,
      question_audio: null,
      defaultSelectedVoice: null,
      playStatus: Sound.status.STOPPED
    };
  }

  componentDidMount() {
    if (!this.props.answer) {
      
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice
      });
    }

  	// var intervalId = setInterval(this.timer, 1000);
    // this.setState({intervalId: intervalId});
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

  			return {
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 10,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          content: '',
          question_audio,
          defaultSelectedVoice
        };
  		} else {
  			if (!state.questionId) {
  				return {
            correctFlag: true,
            questionId: props.data.id
          };
  			}
  		}
  	}
  }


	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);

      this.setState({
        playStatus: Sound.status.PLAYING,
        secondCount: 0
      });
		} else {
  		this.setState({
        secondCount: (this.state.secondCount - 1)
      });
    }
	}

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  changeRange = (e) => {
    this.setState({
      volume: parseInt(e.target.value)
    });
  }

  handleSongFinishedPlaying = () => {
    console.log("stopped.");
    this.setState({
      soundStatus: false,
      playStatus: Sound.status.STOPPED
    });
    this.props.soundChecked();
    this.props.showResult();
  }

  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");

  };

  changePlaybackSpeed = (speed) => {
    // this.setState((prevState) => ({
    //   soundKey: prevState.soundKey + 1
    // }));
    // this.setState({playbackSpeed: speed});

    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1,
      playbackSpeed: speed
    }));
  }

  chaneText = (e) =>{
    let { name, value } = e.target;
    this.props.submit({'answer':value,'correct':''},true);
    this.setState({ [name] : value, resultObj: {'answer':value,'correct':''} });
  };

  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':''});
    this.setState({correctFlag:false,resultObj: {'answer':'','correct':''}});
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });
	}

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({
      secondCount: 0,
      playStatus: Sound.status.PLAYING
    });
  };

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  changeAudio = (selected) => {

    const { question_audios } = this.props.data;
    const selected_audio = question_audios.filter((audio, i) => {
      return audio.label === selected.label;
    });

    this.setState({
      question_audio: `${s3base}/${selected_audio[0].value}`,
      playStatus: Sound.status.PLAYING
    });
  }

  onLoad = (obj) => {
    // console.log(obj)
  }

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    return (
      <>
        <Col xs={12}>
        	<h4 className="infoText"></h4>
          {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 &&
            <Sound
              autoLoad={true}
              url={this.state.question_audio}
              // url={s3base + this.props.data.media_link}
              playStatus={this.state.playStatus}
              onPlaying={this.handleSongPlaying}
              volume={this.state.volume}
              onFinishedPlaying={this.handleSongFinishedPlaying}
              playbackRate={this.state.playbackSpeed}
              key={this.state.soundKey}
              onLoading={this.onLoad}
            />
          }
        </Col>

        {!this.props.resultMode && 
          <Col xs={12}>
          	<div className="col-lg-12 text-center">
        		  <div className="currentStatus">
                <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
                  {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }
                  <br></br>
                  
                  Volume
                  <Input
                    placeholder="Volume"
                    type="range"
                    defaultValue={this.state.volume}
                    step={1}
                    onInput={(e) => this.changeRange(e)}
                    min={0}
                    max={100}
                  />
                  <br></br>
                
                  <Progress color="info" value={(this.state.soundPer)} />
                  {!this.props.testMode && (
                    <div className="d-flex justify-content-between">
                      <div className='stop-btn'>
                        {
                          this.state.secondCount !== 0 &&
                          this.state.blobURL === '' &&
                          !this.state.isRecording &&
                            <Button color="success" onClick={this.skip} size="sm">Skip</Button>
                        }
                      </div>

                      <div className="stop-btn clearfix">
                        <div className="select-voice pull-left">
                          {this.state.defaultSelectedVoice !== null &&
                            <SelectVoiceDropDown
                              voices={this.props.voices}
                              selected={this.state.defaultSelectedVoice}
                              changeAudio={this.changeAudio}
                            />
                          }
                        </div>
                        <div className="select-speed pull-right">
                          <PlaybackSpeedBtns
                            changePlaybackSpeed={this.changePlaybackSpeed}
                            playbackSpeed={this.state.playbackSpeed}
                          />
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              </div>

              <Col xs={12}></Col>
        		  
              <Col xs={12}>
        		  	{this.state.blobURL &&
                  <audio
                    src={this.state.blobURL}
                    controls="controls"
                  />
                }
        		  </Col>
        		</div>
          </Col>
        }

        <Col xs={12}><h5>{question}</h5></Col>
        
        {!this.props.resultMode &&
          <Col xs={12}>
            <FormGroup>
              <Input
                type="textarea"
                name="content"
                autoCorrect="false"
                spellCheck="false"
                id="textBox"
                onChange={(e) => { this.chaneText(e)}}
                value={this.state.content}
              />
            </FormGroup>
            <div className="bottomContent">
              <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
              <div className="copyPaste">
                <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
                <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
                <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
              </div>
            </div>
          </Col>
        }
        
        <Col xs={12}>
          <h5>{this.props.data && this.props.answer &&  this.props.data.audio_script }</h5>
        </Col>
      </>
    );
  }
}
export default Dictation;
