import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import API from "api.js";

import { thead, tbody } from "variables/general";
import SidebarClose from "components/Sidebar/SidebarClose";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    API({
      method: "GET",
      url: "transaction",
    })
      .then((data) => {
        console.log(data);
        this.setState({ data: data.data.data });
      })
      .catch(() => {});
  }

  cancelSub = () => {
    // this.setState({modal:true});
    this.props.history.push("/admin/subscription-detail");
  };

  render() {
    return (
      <>
        <div className="content">
          <SidebarClose />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Your Subscriptions</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="transactionOuter">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="">Subscription</th>
                          <th className="">Amount</th>
                          <th className="">Status</th>
                          <th className="">Recurring date</th>
                          <th className="">Expiring on</th>
                          <th className="">Action(s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.length === 0 && (
                          <tr>
                            <td colSpan="5" className="">
                              No Data Found
                            </td>
                          </tr>
                        )}
                        {this.state.data.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td className="">
                                {val.details !== null
                                  ? val.details.package
                                  : val.subscription &&
                                    val.subscription.plan &&
                                    val.subscription.plan.title}
                              </td>
                              <td className="">
                                {val.details !== null
                                  ? val.details.currency.toUpperCase()
                                  : val.subscription.plan.price_in == val.amount
                                  ? "INR"
                                  : "AUD"}
                                {" " + val.amount}
                              </td>
                              <td>
                                {val.subscription.status === 1
                                  ? "Active"
                                  : "Inactive"}
                              </td>
                              <td>
                                {val.subscription?.status === 1 &&
                                val.subscription?.is_recurring_plan === 1 ? (
                                  val.subscription?.expiry_date
                                ) : (
                                  <div>-</div>
                                )}
                              </td>
                              <td>
                                {val.subscription?.status === 1 &&
                                val.subscription?.is_recurring_plan === 0 ? (
                                  val.subscription?.expiry_date
                                ) : (
                                  <div>-</div>
                                )}
                              </td>
                              <td>
                                {val.subscription?.status === 1 &&
                                  val.subscription?.is_recurring_plan === 1 && (
                                    <>
                                      <button
                                        className="btn1 btn-danger"
                                        onClick={(e) => {
                                          this.cancelSub();
                                        }}
                                      >
                                        Cancel Subscription
                                      </button>
                                    </>
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Transaction;
