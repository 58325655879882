import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API,{base} from "../api.js";
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            list:[]
        }
    }

    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
        API({
              method: 'GET',
              url: "testimonial/list",
              data:'json',
            }).then(data => {
                console.log(data.data.response.data)
              this.setState({list:data.data.response.data});
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
    }
 render(){   
    return (
    <React.Fragment>
    <Header />
        
        <section className="testimonials mt-5">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h2 className="title">Student <span>Testimonials</span></h2>
                    <p>We provide the top-rated PTE training to the candidates to make their career journey smooth</p>
                        {this.state.list.map((item)=>{
                            return (<div className="testiBlock">
                            <div className="imgOuter">
                                <img src={base+item.image} alt="user" />
                            </div>
                            <h3>{item.name}</h3>
                            <p>{item.message}</p>
                        </div>);
                        })}
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Refund;