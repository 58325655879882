import React, { useState } from "react";
import { s3base } from "api";
import classes from "../../assets/css/notification.module.css";
// import Image from "react-image-enlarger";

const Attachment = ({ notification }) => {
  // const [zoomed, setZoomed] = React.useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  let attachment, attachmentSplited, attachmentType;

  if (notification.attachment) {
    attachmentSplited = notification.attachment.split(".");
    attachmentType = attachmentSplited[attachmentSplited.length - 1];
    if (attachmentType === "pdf") {
      attachment = (
        <a
          href={s3base + notification.attachment}
          className={classes["download-btn__attachment"]}
          download
          target="_blank"
        >
          <span>Open</span>
          <span>PDF</span>
        </a>
      );
    } else {
      attachment = (
        // <Image
        //   src={s3base + notification.attachment}
        //   alt=""
        //   zoomed={zoomed}
        //   className={classes["notification-attachment"]}
        //   onClick={() => setZoomed(true)}
        //   onRequestClose={() => setZoomed(false)}
        // />
        <>
          <img
            src={s3base + notification.attachment}
            alt=""
            className={classes["attachment-image"]}
            onClick={() => {
              setIsPopupOpen(true);
              setIsClosing(false);
            }}
          />
        </>
      );
    }
  }

  const closePopup = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 500);
  };

  return (
    <>
      {isPopupOpen && (
        <div className={classes["img-popup"]} onClick={closePopup}>
          <span className={classes["close"]} onClick={closePopup}>
            <div>&times;</div>
          </span>
          <img
            className={`${classes["popup-content"]} ${
              isClosing ? classes["closing"] : ""
            }`}
            src={s3base + notification.attachment}
            alt=""
          />
        </div>
      )}
      {notification.attachment && (
        <div
          className={
            "text-center pe-3 " + classes["notification-attachment__wrapper"]
          }
        >
          {attachment}
        </div>
      )}
    </>
  );
};

export default Attachment;
