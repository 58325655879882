import React from "react";
import MobileDetect from "mobile-detect";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { store } from "store/index";
import { isModalShown } from "action";
import { useLocation } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    token: state.token,
    loaded: state.loaded,
    user: state.user,
    subscribed: state.subscribed,
    alert: state.alert,
    modal: state.isModalShown,
  };
};

const DownloadApp = (props) => {
  console.log(props);
  const [link, setLink] = useState("");
  const {pathname}=useLocation();
  useEffect(() => {
    if (isMobile) {
      let md = new MobileDetect(window.navigator.userAgent);
      if (md.os() === "AndroidOS" || md.os() === "iOS") {
        if (!props.modal && !pathname.includes("/video-course")) {
          if (document.getElementsByClassName("modal-open").length === 0) {
            const modal = new window.bootstrap.Modal(
              document.getElementById("staticBackdrop")
            );
            modal.show();
          }
        }
        if (md.os() === "AndroidOS") {
          setLink(process.env.REACT_APP_ANDROID_URL);
        } else if (md.os() === "iOS") {
          setLink(process.env.REACT_APP_IOS_URL);
        }
      }
    }
  }, []);
  console.log(link);
  const close = () => {
    const modal = new window.bootstrap.Modal(
      document.getElementById("staticBackdrop")
    );
    Object.assign(modal, {
      _ignoreBackdropClick: false,
      _isBodyOverflowing: true,
      _isShown: true,
      _isTransitioning: false,
      _originalBodyPadding: 0,
      _scrollbarWidth: 0,
      _backdrop: document.getElementsByClassName("modal-backdrop")[0],
    });
    modal.hide();
    store.dispatch(isModalShown(true));
  };
  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Try our app
              </h5>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <a href={link}>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3 w-100 ml-0"
                    style={{ fontSize: "medium", backgroundColor: "#94C23C" }}
                  >
                    GET THE APP
                  </button>
                </a>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary mb-3 w-100 ml-0"
                  style={{
                    fontSize: "medium",
                    backgroundColor: "white",
                    color: "#94C23C",
                    borderColor: "#94C23C",
                  }}
                  data-bs-dismiss="modal"
                  onClick={close}
                >
                  CONTINUE IN MOBILE WEB
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, null)(DownloadApp);
