import React, { useRef, useState } from "react";
import PopUps from "./PopUps";
import ResourceCheck from "../../components/SectionalTest/ResourceCheck";
import { Card, Col, Row } from "reactstrap";
import TestHeader from "./TestHeader";
import Questions from "./Questions";
import BottomBtns from "./BottomBtns";
import MockInfo from "./MockInfo";

const TestBody = ({ mockData, setMockData, routeState, soundArr }) => {
  const timerRef = useRef();
  const [showMockInfo, setShowMockInfo] = useState(true);
  const showTest = () => {
    setMockData((prevState) => {
      return {
        ...prevState,
        resourceTested: true,
        secondCount: prevState.mockTime,
      };
    });
  };

  const mainData = showMockInfo ? (
    <MockInfo
      setShowMockInfo={setShowMockInfo}
      categoriesData={mockData.categoriesData}
      initialTime={mockData.initialTime}
      category={mockData.mockCat}
    />
  ) : (
    <div className="content questionsArea">
      <Row>
        <Col xs={12}>
          <Card>
            <TestHeader
              mockData={mockData}
              setMockData={setMockData}
              id={routeState.id}
              ref={timerRef}
            />
            <Questions
              mockData={mockData}
              setMockData={setMockData}
              routeState={routeState}
              soundArr={soundArr}
              timerRef={timerRef}
            />
          </Card>
        </Col>
        <BottomBtns mockData={mockData} setMockData={setMockData} />
      </Row>
    </div>
  );

  return (
    <>
      <PopUps mockData={mockData} setMockData={setMockData} />
      {mockData.mockCat && !mockData.resourceTested && (
        <ResourceCheck
          showTest={showTest}
          pending={0}
          data={{}}
          cat={mockData.mockCat}
        />
      )}

      {mockData.resourceTested && mainData}
    </>
  );
};

export default TestBody;
