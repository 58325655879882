import React from "react";
import { Link } from "react-router-dom";
import { encode as base64_encode } from 'base-64';
import { store } from 'store/index';
import API from "../../api"

import UserSubscriptions from "components/Dashboard/UserSubscriptions.jsx";
import UpcomingClasses from "components/Dashboard/UpcomingClasses.jsx";




export default function RightSidebar(props) {
	const { classes, subscription } = props;


	const options = (token, id) => {
		return {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ user_id: id, token })
		};
	}

	const redirectNow = (token_id) => {
		const PTE_CORE_URL = process.env.REACT_APP_PTE_CORE_URL;
		const encoded_token_id = base64_encode(token_id);
		const pte_core = `${PTE_CORE_URL}default/check/post?cust=${encoded_token_id}`;
		window.location.href = pte_core; // redirect to pte core website.
	}

	const redirectToPteCore = () => {
		const { user: { id, email, token: access_token }, token } = store.getState();
		console.log(store.getState());
		// let ab = null;
		if (access_token !== undefined && access_token !== null && access_token.length > 0) {
			redirectNow(access_token[0].id);
			// if (access_token.length > 0) {
			// }
		} else {
			// get id.
			API({
				url: "token/id",
				method: "GET"
			}).then((res) => {
				redirectNow(res.data.data);
			}).catch(err => console.log(err));
		}
	}
	
	return (
		<div className="rightSidebar">
			<div className="rightWrap">
				<div className="row">
					<div className="col d-flex justify-content-center mb-3">
						<Link to="/">
							<img src="/images/new_dash/la-logo.png" alt="" />
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col d-flex justify-content-center mb-4 mt-4">
						<a href="#" onClick={redirectToPteCore} style={{textDecoration: "none"}}>
							<span className="sheen-gold pte-core__title">Go to PTE CORE</span>
						</a>
					</div>
				</div>
				<UserSubscriptions subscription={subscription} />
				{subscription.length > 0 &&
					<UpcomingClasses classes={classes} />
				}
			</div>
		</div>
	);
}
