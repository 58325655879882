import React, { useState } from 'react';
import {
	Row, Col,
	Card, CardBody,
	Button, Input
} from 'reactstrap';
import SidebarClose from "components/Sidebar/SidebarClose";
import API from 'api.js';

export default function WritingAssessment(props) {

	const [title, setTitle] = useState("");
	const [prompt, setPrompt] = useState("");
	const [userInput, setUserInput] = useState("");
	const [result, setResult] = useState(null);

	const handleSubmit = (e) => {
		if (title !== "" && prompt !== "" && userInput !== "") {

			API({
				url: "writing/assessment",
				method: "POST",
				data: {
					prompt,
					user_input: userInput,
					title
				}
			}).then(res => {

				let r = res.data.response[0];
				console.log(JSON.parse(r));
				setResult(JSON.parse(r));

			})
		}
	}

	return (
		<div className="siteContent py-2 multipleCards">
			<SidebarClose />

			<Row>
				<Col className="practice-material-categories" xs={12}>
					<h4>Writing Assessment using OpenAi</h4>
					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<label
										htmlFor="title"
										style={{ fontWeight: "bold", fontSize: "16px" }}
									>Title:</label>
									<br />
									<Input
										className="title"
										id="title"
										onKeyUp={(e) => setTitle(e.target.value)}
										style={{ width: "100%", padding: "5px" }}
									/>
								</Col>
								<Col xs={6}>
									<label
										htmlFor="instruction-set"
										style={{ fontWeight: "bold", fontSize: "16px" }}
									>Instructions:</label>
									<br />
									<textarea
										className="prompt"
										id="instruction-set"
										onKeyUp={(e) => setPrompt(e.target.value)}
										style={{ width: "100%", padding: "5px" }}
									></textarea>
								</Col>
								<Col xs={6}>
									<label
										htmlFor="user-response"
										style={{ fontWeight: "bold", fontSize: "16px" }}
									>User Response:</label>
									<br />
									<textarea
										className="response"
										id="user-response"
										onKeyUp={(e) => setUserInput(e.target.value)}
										style={{ width: "100%" }}
									></textarea>
								</Col>
								<Col xs={6} className="mb-5">
									<Button
										onClick={(e) => handleSubmit(e)}
									>Submit</Button>
								</Col>
							</Row>

							<Row>
								<Col xs={12}>
									<h6>Result:</h6>

									<div className="result">
										{result !== null &&
											<pre>{JSON.stringify(result, null, 4)}</pre>
										}
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
}