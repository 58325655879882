import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import API from "../api";
import { setNotifications } from "action";
import Notification from "components/Notification/Notification";
import SidebarClose from "components/Sidebar/SidebarClose";

function Notifications() {
  const [clicked, setClicked] = useState(null);
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const handleClear = async (id) => {
    setClicked(id);
    let response = await API({
      url: "markNotificationAsRead",
      method: "POST",
      data: { id },
    });

    if (response.status === 200) {
      dispatch(setNotifications(response.data));
    } else {
      setClicked(null);
    }
  };

  return (
    <div className="content">
      <SidebarClose />
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Notifications</CardTitle>
            </CardHeader>
            <CardBody>
              {notifications.map((notification) => {
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    clicked={clicked}
                    handleClear={handleClear}
                  />
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Notifications;
