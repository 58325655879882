import React from "react";

const Benefits = ({ plan, paymentMethod }) => {
  const isGold = plan.title === "GOLD" && plan.is_video !== 0;

  return (
    <>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>
        {/* For RazorPay */}
        <span>Duration {plan.duration} Month(s)</span>

        {/* For CCAvenue Only */}
        {/* <span>Duration {paymentMethod === "UPI" ? "[SELECTED]" : plan.duration} Month(s)</span> */}
      </div>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
        <span>Unlimited Al scoring for the question types.</span>
      </div>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
        <span>Full access to the exam questions.</span>
      </div>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
        <span>Access to latest templates and prediction files.</span>
      </div>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
        <span>Unlimited sectional mock tests with scoring.</span>
      </div>
      <div className="plan-detail">
        <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
        <span>Unlimited full mock tests with scoring.</span>
      </div>
      {isGold && (
        <>
          <div className="plan-detail">
            <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
            <span>Access to reading explanation videos.</span>
          </div>
          <div className="plan-detail">
            <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
            <span>Full access to premium strategy videos.</span>
          </div>
          <div className="plan-detail">
            <i className="fa fa-check grn-color" aria-hidden="true"></i>{" "}
            <span>Full access to the detailed video course.</span>
          </div>
        </>
      )}
      <div className="plan-detail">
        <span>&nbsp;</span>
      </div>
      <div className="plan-detail">
        <span>&nbsp;</span>
      </div>
      <div className="plan-detail">
        <span>&nbsp;</span>
      </div>
      <div className="plan-detail">
        <span>&nbsp;</span>
      </div>
    </>
  );
};

export default Benefits;
