import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

const BuyBtn = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (status, e) => {
    e.preventDefault();
    setShowModal(status);
  };

  const data = (
    <section className="package py-0 p-0 mb-0 packagesOuter" id="package">
      <div className="container">
        <div className="row packagesCntnt">
          <div className="col-md-8 packsCard bestPack">
            <div className="packsCardInr">
              <div className="packsCardMain">
                <span>BEST SELLER</span>
                <div className="packHead">
                  <div className="packHeadInr">
                    <div className="packIcon">
                      <img src="images/achedamy-logo-03.png" />
                    </div>
                    <h3>GOLD</h3>
                    <div className="packRadio">
                      <div className="packRadioInr">
                        <i className="fa fa-check-circle"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="packBody">
                  <ul>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Unlimited AI scoring for all question types</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Full access to them exam questions</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Access to latest templates and prediction files
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Unlimited sectional mock tests with scoring</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Unlimited full mock tests with scoring</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Unlimited comprehensive mock tests with scoring
                      </span>
                    </li>
                    <li className="availInGold">
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Access to reading explanation videos</span>
                    </li>
                    <li className="availInGold">
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Full access to premium strategy videos</span>
                    </li>
                    <li className="availInGold">
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>Full access to the detailed video course</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-0 mPlansOuter">
            <div className="row mPlansInr flex-column">
              <div className="col">
                <div className="mplanInr month01">
                  <div className="mplanHead">
                    <h3>AUD (1 MONTH)</h3>
                    <span className="planTag">Standard</span>
                  </div>
                  <div className="mpanBody" style={{minHeight: "202px"}}>
                    <p>29* (1 Month)</p>
                    <span>* Auto Renews every 1 month</span>
                    <div className="buyNowBtn">
                      <a
                        href=""
                        className="btn ss-btn outlineBtn m-0"
                        data-animation="fadeInUp"
                        data-delay=".8s"
                      >
                        <span>BUY NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mplanInr month02">
                  <div className="mplanHead">
                    <h3>AUD (2 MONTH)</h3>
                    <span className="planTag">Best Seller</span>
                  </div>
                  <div className="mpanBody" style={{minHeight: "202px"}}>
                    <div className="mplanOffer">
                      <i className="fa fa-check-circle"></i>
                      <span>58 16% OFF</span>
                    </div>
                    <p>49* (2 Months)</p>
                    <span>* Auto Renews every 2 months</span>
                    <div className="buyNowBtn">
                      <a
                        href=""
                        className="btn ss-btn outlineBtn m-0"
                        data-animation="fadeInUp"
                        data-delay=".8s"
                      >
                        <span>BUY NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mplanInr month03">
                  <div className="mplanHead">
                    <h3>AUD (3 MONTH)</h3>
                    <span className="planTag">Most Savings</span>
                  </div>
                  <div className="mpanBody" style={{minHeight: "202px"}}>
                    <div className="mplanOffer">
                      <i className="fa fa-check-circle"></i>
                      <span>87 21% OFF</span>
                    </div>
                    <p>69* (3 Month)</p>
                    <span>* Auto Renews every 3 month</span>
                    <div className="buyNowBtn">
                      <a
                        href=""
                        className="btn ss-btn outlineBtn m-0"
                        data-animation="fadeInUp"
                        data-delay=".8s"
                      >
                        <span>BUY NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <>
      <a
        href="#packages"
        className="btn ss-btn outlineBtn"
        data-animation="fadeInUp"
        data-delay=".8s"
      >
        <span>Upgrade to Gold Package and Access Video Course</span>
      </a>
      <BootstrapModal
        status={showModal}
        toggle={() => handleModal(false)}
        message=""
        data={data}
      />
    </>
  );
};

const BootstrapModal = ({ status, toggle, message, data }) => {
  return (
    <Modal isOpen={status} size="xl">
      {/* <ModalBody> */}
      {/* <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span> */}
      {message && <h6>{message}</h6>}
      {data && data}
      {/* </ModalBody> */}
    </Modal>
  );
};

export default BuyBtn;
