import React from "react";
import { Col, Row } from "reactstrap";
import Title from "./Title";
import List from "./List";
import Item from "./Item";

const Category = ({ children, containerClass }) => {
  return (
    <Row className={"videoZoneOuter " + containerClass}>
      <Col xs={12}>{children}</Col>
    </Row>
  );
};

export default Category;

Category.Title = Title;
Category.List = List;
Category.Item = Item;
