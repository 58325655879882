import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import API from "../api";
import Branch from "components/Branch/Branch";
class Branches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      flag: false,
      lockSubmit: false,
      branches: [],
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.pageView();
    this.getBranches();
  }

  getBranches() {
    API({
      url: "getAllBranches",
      method: "GET",
    }).then((res) => {
      if (res.status && res.status === 200) {
        this.setState({ branches: res.data.branches });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy</title>
          <meta name="twitter:title" content="Language Academy" />
          <meta property="og:title" content="Language Academy" />
        </Helmet>
        <Header />
        <div className="innerPage">
          <section className="bannerOuter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1>Our Branches</h1>
                  <span>Visit us and fulfil your dreams</span>
                  <br />
                  <a href="javascript:;" className="downArrow">
                    <img src="images/banner-arrow.png" alt="banner-arrow" />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="branchesOuter">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center branchesHeading">
                <h2 className="pb-3">Hello. What can we help you with?</h2>
                <h3>Our Offices</h3>
              </div>
              {this.state.branches.map((branch) => {
                return <Branch branch={branch.branch_detail} key={branch.id} />;
              })}
              <div className="col-sm-12 div20task text-center">
                <h4>
                  For online classes, please contact us on{" "}
                  <a href="tel:61455566654">+61 455 566 654</a>
                </h4>
                <h4>
                  For online classes in India, please contact us on{" "}
                  <a href="tel:917652811912">+91 765 281 1912</a>
                </h4>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Branches;
