import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Redirect, useLocation } from "react-router-dom";
import useHttp from "hooks/useHttp";
import { isChrome } from "util/isChrome";
import { getAudioPermission } from "util/getAudioPermission";
import AlreadyCompleted from "components/SectionalTest/AlreadyCompleted";
import { toggleSidebar } from "util/toggleSidebar";
import { addBeforeUnload } from "util/beforeUnload";
import { removeBeforeUnload } from "util/beforeUnload";
import MockStatusCheck from "components/SectionalTest/MockStatusCheck";
import TestBody from "components/SectionalTest/TestBody";

const soundArr = [2, 4, 5, 13, 14, 15, 16, 17, 18, 19, 20];

const Test = () => {
  const { state: routeState } = useLocation();
  const [allOk, setAllOk] = useState(false);
  const [mockData, setMockData] = useState(initialState);
  const showMock =
    !mockData.alreadyCompleted &&
    !mockData.redirectToPending &&
    !mockData.redirectToBegin;

  // request to get the mock, don't edit it directly, create a copy of this to edit because it's a state variable
  const [mock] = useHttp(
    `web/mock/questions/${routeState?.id}/${routeState?.pending}`,
    "GET",
    [],
    !!routeState
  );

  useEffect(() => {
    if (mock) {
      const mockCopy = JSON.parse(JSON.stringify(mock));

      const pending = parseInt(routeState?.pending);
      let redirectToPending = pending === 0 && mockCopy.resume;
      let redirectToBegin = pending === 1 && !mockCopy.resume;
      let alreadyCompleted = mockCopy.complete === 1;
      let soundStat = !soundArr.includes(
        mockCopy.mock.question[0].subcategory_id
      );

      /**
       * Providing question Count. Sequencing for result.
       */
      let seq = pending === 0 ? 1 : mockCopy.q_count + 1;
      mockCopy.mock.question.forEach((question) => {
        question.question_number = seq;
        seq++;
      });

      const type = mockCopy.mock.question[0].subcategory_id;
      const categoriesData = mockCopy.mock.mock_question_types;

      const { time_for_each_question: questionTime } =
        mockCopy.mock.mock_question_types.find(
          (value) => value.question_sub_category_id === type
        );

      let questionTimeData = {};

      if (questionTime) {
        questionTimeData = {
          isQuestionTime: true,
          questionTime,
        };
      }

      setMockData((prevState) => {
        return {
          ...prevState,
          redirectToBegin,
          redirectToPending,
          alreadyCompleted,
          soundStat,
          ids: mockCopy.mock.question,
          title: mockCopy.mock.title,
          mockCat: mockCopy.mock.category,
          questionData: mockCopy.mock.question,
          type,
          qCount: mockCopy.q_count,
          secondCount: parseFloat(mockCopy.time) * 60,
          mockTime: parseFloat(mockCopy.time) * 60,
          missingData: { mockId: routeState?.id, questions: [] },
          categoriesData,
          ...questionTimeData,
          initialTime: mockCopy.mock.time,
        };
      });
    }
  }, [mock, routeState]);

  useEffect(() => {
    getAudioPermission(() => {
      setAllOk(isChrome);
    });

    toggleSidebar(); // toggle sidebar
    addBeforeUnload();
    return () => {
      toggleSidebar();
      removeBeforeUnload();
    };
  }, [getAudioPermission, toggleSidebar]);

  // Redirect to mocks page if state is empty for route
  if (!routeState?.id) {
    return <Redirect to={"/admin/mock"} />;
  }

  return (
    <>
      <>
        {mockData.alreadyCompleted ? (
          <AlreadyCompleted title={mockData.title} />
        ) : (
          <>
            <MockStatusCheck
              mockData={mockData}
              id={routeState.id}
              allOk={allOk}
            />
            {allOk && showMock && (
              <TestBody
                mockData={mockData}
                setMockData={setMockData}
                routeState={routeState}
                soundArr={soundArr}
              />
            )}
          </>
        )}
      </>
    </>
  );
};

const initialState = {
  alreadyCompleted: false,
  redirectToPending: false,
  redirectToBegin: false,
  soundStat: true,
  ids: [],
  title: "",
  mockCat: false,
  questionData: [],
  type: "",
  qCount: 0,
  secondCount: 0,
  mockTime: 20,
  missingData: {},
  currentQuestion: 0,
  resourceTested: false,
  result: {
    strategy: 1,
  },
  isResult: false,
  nextType: 0,
  modal: false,
  stopRecording: false,
  dashboardFlag: false,
  failed: [],
  qTime: 0,
  pending: 0,
  complete: 0,
  submitProcess: false,
  internet: true,
  saveProcess: false,
  resultId: false,
  timeOutFlag: false,
  soundModal: false,
  msg: "",
  intervalId: null,
  timeOut: false,
  isQuestionTime: false,
  questionTime: null,
  categoriesData: [],
  connected: true,
  initialTime: 0,
};

export default Test;
