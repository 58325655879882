import React from "react";

const Msg = ({ notification }) => (
  <div>
    <b>{notification.title}</b> <br />
    {notification.message && <div>{notification.message}</div>}
  </div>
);

export default Msg;
