import React, { useRef } from "react";
import { useState } from "react";
import Api from "../../../api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { setAlert } from "action/index";

const SelectBranch = ({ isOpen, togglePopup, branches }) => {
  const selectRef = useRef();
  const [error, setError] = useState("");
  const [studentType, setStudentType] = useState(null);
  const [branch, setBranch] = useState("");
  const isCampus = studentType === "campus";
  const isDirect = studentType === "direct";
  const dispatch = useDispatch("");

  const submit = () => {
    if (isCampus || isDirect) {
      let data;
      setError("");
      if (branch && isCampus) {
        data = { branch: branch };
      } else if (isDirect && !branch) {
        data = { branch: "N/A" };
      } else {
        setError("Please select any branch");
        return;
      }

      Api({
        method: "POST",
        url: "branchUpdate",
        data: data,
      }).then((res) => {
        if (res.status === 200) {
          togglePopup(false);
          dispatch(
            setAlert({
              flag: true,
              type: 1,
              message: "Branch saved successfully!",
            })
          );
        }
      });

      return;
    }

    setError("Please select any type");
  };

  const onTypeChange = (e) => {
    setStudentType(e.target.value);
    if (e.target.value === "direct") {
      setBranch("");
    }
  };

  const onBranchChange = (e) => {
    if (e.target.value) {
      setBranch(e.target.value);
    }
  };

  console.log(studentType, branch);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Select your branch</ModalHeader>
      <ModalBody>
        <FormGroup>
          <em className="info-txt">
            *This information must be 100% appropriate otherwise your account
            may be blocked
          </em>
          {error && <Alert color="warning">{error}</Alert>}
          <select
            className="form-control mt-3 mb-3"
            onChange={onTypeChange}
            defaultValue={""}
          >
            <option value="" disabled>
              Please select type...
            </option>
            <option value="campus">Student with LA</option>
            <option value="direct">Not a student with LA</option>
          </select>
          {isCampus && (
            <select
              className="form-control"
              onChange={onBranchChange}
              name="select"
              value={branch}
            >
              <option value="" disabled>
                Please select branch...
              </option>
              {branches.map((branch) => (
                <option value={branch.name} key={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={submit} color="primary">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectBranch;
