import React from "react";
import { useEffect } from "react";
import { setUser, setAlert, setSub } from "action/index.js";
import { connect } from "react-redux";
import {
  Table,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useState } from "react";
import API from "api.js";
import { useCallback } from "react";
import { store } from "store/index";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setSub: (sub) => dispatch(setSub(sub)),
  };
};

const SubscriptionDetail = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [userData, setUserData] = useState(null);
  const activeSubscription = userData && userData.active_subscription;

  const getUser = useCallback(() => {
    API({
      method: "GET",
      url: "user-my-profile",
    })
      .then((data) => {
        if (data.data.user.active_subscription.length > 0) {
          const user = data.data.user;
          if(user.active_subscription[0].is_recurring_plan === 0) {
            props.history.replace("/admin/profile");
          }
          const subscriptionId =
            user.active_subscription[0].stripe_subscription_id;

          if (subscriptionId === "backend_subscription") {
            props.history.replace("/admin/profile");
          } else {
            setSubscriptionId(subscriptionId);
            setUserData(user);
          }
        } else {
          props.history.replace("/admin/profile");
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const cancelSubApi = () => {
    setIsModalOpen(false);
    if (subscriptionId) {
      var bodyFormData = new FormData();
      bodyFormData.set("user_id", userData.id);
      bodyFormData.set("mode", activeSubscription[0].mode);
      bodyFormData.set("stripe_subscription_id", subscriptionId);
      API({
        method: "POST",
        url: "cancel/sub",
        data: bodyFormData,
      })
        .then((data) => {
          console.log(data.data);
          if ("status" in data) {
            props.setUser(data.data.user);
            const sub =
              data.data.user.active_subscription.length === 0 ? false : true;
            props.setSub(sub);
            store.dispatch(
              setAlert({ flag: true, type: 1, message: data.data.message })
            );

            props.history.replace("/admin/dashboard");
            return;
          } else {
            store.dispatch(
              setAlert({ flag: true, type: 2, message: "Something went wrong" })
            );
          }
        })
        .catch((error) => {
          store.dispatch(
            setAlert({ flag: true, type: 2, message: "Something went wrong" })
          );
        });
    }
  };

  const showTable = activeSubscription && activeSubscription.length > 0;

  return (
    <>
      <div className="content">
        <Modal isOpen={isModalOpen} size="sm">
          <ModalBody>
            <div>Do you want to cancel subscription ?</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={cancelSubApi}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col xs={12}>
            <Card style={{ height: "95vh" }}>
              <CardHeader>
                <CardTitle tag="h4">Subscription Details</CardTitle>
              </CardHeader>
              <CardBody>
                {showTable && (
                  <Table>
                    <tbody>
                      <tr>
                        <th>Package Name:</th>
                        <td>{activeSubscription[0]?.plan?.title}</td>
                      </tr>
                      <tr>
                        <th>Package Expiry:</th>
                        <td>{activeSubscription[0]?.expiry_date}</td>
                      </tr>
                      <tr>
                        <th>Amount:</th>
                        <td>
                          {activeSubscription[0]?.transaction.details.currency + " "}
                          {activeSubscription[0]?.amount}
                        </td>
                      </tr>
                      <tr>
                        <th>Recurring Plan:</th>
                        <td>
                          {activeSubscription[0]?.is_recurring_plan
                            ? "YES"
                            : "NO"}
                        </td>
                      </tr>
                      <tr>
                        <th>Cancel Subscription:</th>
                        <td>
                          <button
                            className="btn1 btn-danger"
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Cancel Subscription
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetail);
