import React, { useEffect } from "react";
import { loadScript } from "util/loadScript";
import LaLogo from "../../../assets/images/la-logo.png";
import API from "api";
import { useSelector } from "react-redux";

const RazorPay = ({
  plan,
  children,
  isLoggedIn = true,
  redirect,
  activeTab,
}) => {
  const { id: planId } = plan;

  const userData = useSelector((state) => {
    const user = state.user;
    if (!user) {
      return {};
    }
    let data = { email: user.email };
    ifThere(["phone", "first_name"], data, user);
    return data;
  });

  function ifThere(keys, data, main) {
    keys.forEach((key) => {
      main[key] && (data[key] = main[key]);
    });
  }

  // console.log(userData);
  useEffect(() => {
    async function init() {
      // console.log("ran");
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js",
        "razorpay-script"
      );

      if (!res) {
        alert("Something went wrong. Please try again later");
        return;
      }
    }
    init();
  }, []);

  async function displayRazorpay() {
    try {
      let url = "createSubscription";
      if (!isLoggedIn) {
        url = "createSubscriptionWithoutLogin";
      }
      console.log({ package_id: planId });
      const response = await API({
        method: "POST",
        url,
        data: { package_id: planId, platform: activeTab },
      });

      if (response.status != 200) {
        return alert("Something went wrong. " + response.message);
      }

      const { subscriptionData } = response.data;
      const { id: subId } = subscriptionData;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
        subscription_id: subId,
        name: "Language Academy",
        description: "Monthly Silver Plan",
        image: LaLogo,
        handler: afterPayment,
        prefill: userData,
        theme: {
          color: "#F37254",
        },
        config: {
          display: {
            hide: [
              {
                method: "card",
              },
            ],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert("Something went wrong. ", error.message);
    }
  }

  async function afterPayment({
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature,
  }) {
    const data = {
      razorpay_payment_id,
      razorpay_signature,
      razorpay_subscription_id,
      plan_id: planId,
    };

    let url = "verifySignature";
    if (!isLoggedIn) {
      url = "verifySignatureWithoutLogin";
    }

    const resp = await API({
      method: "POST",
      url,
      data,
    });

    // console.log(resp);
    if (resp.status == 200) {
      return redirect();
    }

    alert(
      "Something went wrong. Invalid payment signature. Please contact us regarding this issue"
    );
  }

  return children(displayRazorpay);
};

export default RazorPay;
