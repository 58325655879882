import React, { useState, useEffect } from 'react';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,

  Card,
  CardTitle,
  CardText,
  Button
} from 'reactstrap';
import { Link } from "react-router-dom";
import API from "api.js";
import ListAndBuy from "components/Plans/CCAvenue/ListAndBuy";

const CARD = 'CARD';
const UPI = 'UPI';
export default function SelectPaymentMethod({ stripeForm, country, isGold, selectedMethod, plan }) {
	const [activeTab, setActiveTab] = useState(CARD);
	const [plans, setPlans] = useState([]);

	const tabToggle = (tab) => {
		if(activeTab !== tab) setActiveTab(tab);
	}

	const loadUPIPlans = () => {
		let url = `getCcavPackages?countryId=${country}`;
		if (process.env.REACT_APP_ENV === 'production') {
			url = `https://ipbackend22.languageacademy.com.au/api/v2/getCcavPackages?countryId=${country}`
		}

		API({
			url,
			method: "GET"
		}).then(({ data }) => {
			const selected_plans = [];
			
			data.plans.map((plan) => {
				if (plan.is_gold === isGold) {
					selected_plans.push(plan);
				}
			});
			setPlans(selected_plans);
		}).catch(err => console.log("err:", err));
	}


	/**
	 * Use this for CCAVENUE plans.
	 * Comment this block when using RazorPay Plans.
	 */
	useEffect(() => {
		if (isGold !== undefined) {
			loadUPIPlans();
		}
	}, [isGold]);


	useEffect(() => {
		selectedMethod(activeTab);
	}, [activeTab]);

	return (
		<div className="panel panel-default">
			{/*<h2 className="paymentFormTitle">Select a payment method</h2>*/}

			<div className="">
				{country === "IN" &&
					<Nav tabs>
						<NavItem>
							<NavLink
								className={`btn btn-primary btn-lg ${activeTab === CARD ? "active" : ""}`}
								onClick={() => { tabToggle(CARD) }}
							>Credit/Debit Card</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={`btn btn-primary btn-lg ${activeTab === UPI ? "active" : ""}`}
								onClick={() => { tabToggle(UPI) }}
							>Pay with UPI</NavLink>
						</NavItem>
					</Nav>
				}
				<TabContent activeTab={activeTab}>
					<TabPane tabId={CARD}>
						<Row className="mt-3">
							<Col sm="12">
								<Card body>
									{stripeForm()}
								</Card>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId={UPI}>
						<Row className="mt-3">
							<Col sm="12">
								<Card body className="payCardsOuter">
									<ListAndBuy 
										plans={plans}
										plan={plan}
										isGold={isGold}
									/>

									{/*Comment Below code When using RazorPay*/}
									{/* <Row>
										<Col sm="6">
											<div className="text-danger pull-left mb-0" style={{fontSize: "12px"}}>* Prices inclusive of all taxes.</div>
											<br /><div className="text-danger" style={{fontSize: "12px", marginTop: "-5px"}}>* One-time Purchase.</div>
										</Col>
										<Col sm="6">
											<p className="pull-right" style={{fontSize: "12px"}}>
												<Link
													to="/admin/ccav/plans"
													className="text-info"
												>Explore all UPI Plans</Link>
											</p>
										</Col>
									</Row> */}
									{/*Till here.*/}
									
								</Card>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
}