import React from "react";
import { CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

const AlreadyCompleted = ({title}) => {
  return (
    <div className="content questionsArea mock-already-completed">
      <Row>
        <Col xs={12}>
          <CardHeader>
            <Row>
              <Col xs={12}>
                <CardTitle tag="h4">{title}</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="testFinish unselectable">
              <div className="col-sm-12">
                <p>
                  <span>
                    Dear user, you've already <strong>FINISHED</strong> this
                    mock. Please attempt some other mock.
                  </span>
                </p>
              </div>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </div>
  );
};

export default AlreadyCompleted;
