import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import API from "api.js";
import RazorPay from "components/Payments/Razorpay/RazorPay";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

export default function ListAndBuy({ plan, isGold, plans }) {
  const formRef = useRef();
  const [actionUrl, setActionUrl] = useState("");
  const [encRequest, setEncRequest] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const history = useHistory();

  const planWrapColor = (i) => {
    const colors = ["skyblue", "darkblue", "planGreen"];
    return colors[i];
  };

  const startCCavProcess = (plan_id) => {
    API({
      method: "POST",
      url: `plan/ccavenue`,
      data: { plan_id },
    })
      .then((data) => {
        const res = data.data.data;
        setActionUrl(res.post_url);
        setEncRequest(res.encRequest);
        setAccessCode(res.access_code);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const enrolNow = (plan_id) => {
    startCCavProcess(plan_id);
  };

  useEffect(() => {
    if (actionUrl !== "" && encRequest !== "" && accessCode !== "") {
      const form = formRef.current;
      if (form) {
        if (typeof form.requestSubmit === "function") {
          form.requestSubmit();
        } else {
          form.dispatchEvent(new Event("submit", { cancelable: true }));
        }
      }
    }
  }, [actionUrl, encRequest, accessCode]);

  const cardDivClasses = `payCardInr payCardInr-h100 skyblue ${
    isGold === 1 ? "gold" : "silver-card__price"
  }`;

  const redirectAfterPayment = () => {
    history.push(
      `/admin/sub/success?p=${plan.price}&currency=${ReactHtmlParser(
        plan.currency
      )}&mode=razorpay`
    );
  };

  /**
   * CCAvenue Plans.
   */
  // return (
  //   <Row>
  //     {plans.map((plan, i) => {
  //       return (
  //         <Col sm="4" className="payCard" key={i}>
  //           <div className={`payCardInr ${isGold === 1 ? "gold" : ""} ${planWrapColor(i)}`}>
  //             <span className="planDuration">{plan.title} - <span style={{fontSize: "9px", fontWeight: "bold"}}>{plan.duration} MONTH{plan.duration > 1 ? "S" : ""}</span></span>
  //             <span className="planRange">₹ {plan.price_in}*</span>
  //             <button className="btn btn-info btn-block planBtn" onClick={() => {enrolNow(plan.id)}}>BUY NOW</button>
  //           </div>
  //         </Col>
  //       )
  //     })}
  //     <form ref={formRef} method="POST" name="submitForm" action={actionUrl} style={{display: "none"}}>
  //       <input type="hidden" name="encRequest" value={encRequest} />
  //       <input type="hidden" name="access_code" value={accessCode} />
  //     </form>
  //   </Row>
  // );

  const showDiscount = plan.discount > 0;

  /**
   * Razor Pay.
   *
   */
  return (
    <>
      <Row className={"main-container"}>
        <Col className="payCard">
          <div className={cardDivClasses}>
            <span className="planDuration">
              {plan.title} -{" "}
              <span className="payCardInr-duration">
                {plan.duration} MONTH{plan.duration > 1 ? "S" : ""}
              </span>
            </span>
            <span className="planRange payCardInr-price">
              PRICE - ₹ {plan.razorpay_price}
              {showDiscount && (
                <span className="fs-4">
                  &nbsp;
                  <small>
                    <s>{plan.base_price}</s>
                  </small>
                  &nbsp;
                  {plan.discount + "% off"}
                </span>
              )}
            </span>
            <span className="renew_note ms-4 text-danger font-weight-bold">
              * You can cancel this subscription anytime.
            </span>
            <RazorPay
              plan={plan}
              isLoggedIn={true}
              redirect={redirectAfterPayment}
            >
              {(clickHandler) => (
                <button
                  className="btn btn-info btn-block planBtn button-payment__razorpay"
                  onClick={clickHandler}
                >
                  BUY NOW
                </button>
              )}
            </RazorPay>
          </div>
        </Col>

        <form
          ref={formRef}
          method="POST"
          name="submitForm"
          action={actionUrl}
          style={{ display: "none" }}
        >
          <input type="hidden" name="encRequest" value={encRequest} />
          <input type="hidden" name="access_code" value={accessCode} />
        </form>
      </Row>
      <span className="renew_note">* Price inclusive of all taxes.</span>
      <span className="renew_note text-danger font-weight-bold mt-5 pt-5">
        * You can cancel this subscription anytime.
      </span>
    </>
  );
}
