import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import API, { base } from "../api.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
class Maintenance extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/api/checkForServer`)
      .then((res) => {
        if (res.status === 200) window.location.href = "/admin/dashboard";
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <React.Fragment>
        <div class="container text-center">
          <img
            className="w-50"
            src="https://img.freepik.com/free-vector/website-maintenance-abstract-concept-vector-illustration-website-service-webpage-seo-maintenance-web-design-corporate-site-professional-support-security-analysis-update-abstract-metaphor_335657-2295.jpg"
            alt="Maintenance Icon"
          />
          <h1
            style={{
              "font-size": "36px",
              "font-weight": "bold",
              margin: 0,
              padding: 0,
              "text-transform": "uppercase",
            }}
          >
            Website Under Maintenance
          </h1>
          <p
            style={{
              "font-size": "18px",
              margin: "20px 0",
              padding: 0,
            }}
          >
            Sorry for the inconvenience, our website is currently undergoing
            maintenance. We'll be back soon!
          </p>
          <p
            style={{
              "font-size": "18px",
              margin: "20px 0",
              padding: 0,
            }}
          >
            Contact us at{" "}
            <a href="mailto:info@languageacademy.com.au">
              info@languageacademy.com.au
            </a>{" "}
            if you have any questions.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Maintenance;
