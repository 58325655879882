import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function MenuItemDropdown(props) {

	const { item, icon, label, premiumIcon, submenu } = props;

	const [subMenu, setSubMenu] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [menuActive, setMenuActive] = useState("");
	const [linkActiveStyle, setLinkActiveStyle] = useState("");

	useEffect(() => {
		setSubMenu(submenu);

		const f = submenu.find(menu => `/admin/${menu.url}` === window.location.pathname);
		setMenuActive(f !== undefined ? "show" : "");
		setLinkActiveStyle(f !== undefined ? "menuCurrent" : "");
		setIsOpen(true);
	}, []);


	useEffect(() => {
		const f = submenu.find(menu => `/admin/${menu.url}` === window.location.pathname);
		setMenuActive(f !== undefined ? "show" : "");
		setLinkActiveStyle(f !== undefined ? "menuCurrent" : "");
	}, [window.location.pathname]);
	

	return (
		<>
			{isOpen &&
				<>
					<a href="#"
						className={`dropdown-toggle ${menuActive} ${linkActiveStyle}`}
						role="button"
						id={`dropdownMenuButton${item}`}
						data-bs-toggle="dropdown" 
						aria-expanded="false"
					>
						<span className={`menuIcon ${icon}`}></span>
						<span className={`menuText ${premiumIcon ? "crownIcon" : ""}`}>{label}</span>
					</a>
					<ul className={`dropdown-menu ${menuActive}`} aria-labelledby={`dropdownMenuButton${item}`}>
						{subMenu.map((menu, key) => {
							return (
								<li className="menuItem" key={key}>
									<NavLink activeClassName={linkActiveStyle} to={`/admin/${menu.url}`}>{menu.label}</NavLink>
								</li>
							)
						})}
					</ul>
				</>
			}
		</>
	);
}
