import React from "react";

const FilterByExplanationVideo = ({onChange}) => {
  return (
    <>
      <label htmlFor="">Filter By</label>
      <select name="filterByVid" id="" className="form-control" onChange={onChange}>
        <option value="all">None</option>
        <option value="yes">With Explanation Videos</option>
        <option value="no">Without Explanation Videos</option>
      </select>
    </>
  );
};

export default FilterByExplanationVideo;
