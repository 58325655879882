import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Alert
} from "reactstrap";
import {store,persistor} from 'store/index';
import API from "api.js";
import { NavLink, Link } from "react-router-dom";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
import cookie from 'react-cookies';
import { Helmet } from "react-helmet";
class Zoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: false,
      modal1:false,
      videoPlan:true,
      loadmore:false,
      active_subscription:[]
    };
  }
   componentDidMount() {
    API({
      method: 'GET',
      url: 'user-data-get/active_subscription'
    }).then(data => {
      let active_subscription = data.data.user.active_subscription
      this.setState({active_subscription:active_subscription});
      if(active_subscription.length > 0 && active_subscription[0].package_id === 9){
        this.setState({modal1:true});
      }
    }).catch(()=>{
    });

  this.loadVideo();

  }

  loadVideo =() =>{
    API({
      method: 'GET',
      url: 'video1?skip='+this.state.data.length
    }).then(data => {
      let loadmore = true;
      if (data.data.video.length < 10) {
        loadmore = false;
      }
      if (data.status === 200) {
        this.setState({'data':[...this.state.data,...data.data.video],videoPlan:true,loadmore:loadmore});  
      }else if(data.status === 207){
        this.setState({'data':[...this.state.data,...data.data.video],error:data.data.message,videoPlan:false,loadmore:loadmore});
      }
      
  }).catch(()=>{
      
    });
  }

  closeModal = () => {
    this.setState({modal1:false});
  }
 
  render() {
    
    return (
      <>
      {this.state.modal1 && <Modal isOpen={this.state.modal1} size="lg">
        <div className="modalHeader">
          <h2>OPT FOR VIDEO COURSES NOW</h2>
        </div>
        <ModalBody className="video-popup">
          <Row>
            <Col className="" md={12}>
              
                <ul class="checklist">
                  <li>24 hours access.</li>
                  <li>All the proven tips, templates and strategies.</li>
                  <li>Real exam practice questions.</li>
                  <li>Complete access tonline practice portal.</li>
                  <li>3 full length scored mock text with score-report and feedback.</li>
                  <li>One to one consultation session with the tutor. (30 minutes each)</li>
                  <li>Regular prediction files.</li>
                  <li>Access to exam prediction files with highly repeated questions.</li>
                </ul>
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Link to="/video-courses" ><Button color="secondary" className="modalCloseBtn" >SUBSCRIBE</Button></Link>
          <Button color="secondary" onClick={(e)=>{this.closeModal()}} className="modalCloseBtn" >CLOSE</Button>
          
        </ModalFooter>
      </Modal>}
      <Helmet htmlAttributes>
        <title>Online PTE Video Course - Language Academy</title>
        <meta name="twitter:title" content="Online PTE Video Course - Language Academy" />
        <meta property="og:title" content="Online PTE Video Course - Language Academy" />
        <meta name="description" content="Excel in PTE with this video course designed for you. 24 hours access to real exam practice questions, video tutorials, online practice portal and many more features." />      
          
        <meta name="twitter:description" content="Excel in PTE with this video course designed for you. 24 hours access to real exam practice questions, video tutorials, online practice portal and many more features." />
        <meta property="og:description" content="Excel in PTE with this video course designed for you. 24 hours access to real exam practice questions, video tutorials, online practice portal and many more features." />
      </Helmet>
        <div className="content videosContent">
          <Row>
            <Col xs={12}>
              <Card>
              {this.state.error && <Alert color="primary">{this.state.error}</Alert>}
                <CardHeader>
                  <CardTitle tag="h4">PTE Videos</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        
                              <th className="">
                                Title
                              </th>
                              <th className="">
                                Description
                              </th>
                              
                              <th className="">
                                Action
                              </th>
                            
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((prop, key) => {
                        return (
                          <tr>
                                  <td className="">
                                    {prop.title}
                                  </td>
                                  <td className="">
                                    {prop.description}
                                  </td>
                                  
                                  <td className="">
                                    {this.state.videoPlan && <NavLink
                                      to={'/admin/video/'+prop.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                    <i className="" />
                                    <p>View Video</p>
                                  </NavLink>}
                                  {!this.state.videoPlan && <NavLink
                                      to={'/video-courses'}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                    <i className="" />
                                    <p>Upgrade to video plans</p>
                                  </NavLink>}
                                  </td>
                                
                          </tr>
                        );
                      })}
                      {this.state.loadmore && <Button onClick={(e)=>{this.loadVideo();}}>Load more</Button>}
                    </tbody>
                  </Table>
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default Zoom;
