
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink } from "react-router-dom";
import { setToken, setUser, setSub} from 'action/index.js';
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub))
   };
};
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      password: '',
      success:false,
      confirmPassword:'',
      error:null,
      error_bag:null,
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async(e) => {
    
    this.setState({error_bag:null});
    e.preventDefault();
    let cred = {
      email: this.state.email,
      password: this.state.password,
    };
    var bodyFormData = new FormData();
    bodyFormData.set('otp', this.state.otp);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('password_confirmation', this.state.confirmPassword);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/reset-password',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    console.log(res);
    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.setState({success:true});     
      
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\
        '; })
      this.setState({error_bag:str});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials."});
    }
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    if (this.state.success) {
      return (<Redirect to="/auth/login" />);  
    }
    return (
      <>
      <section class="bannerOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>Login</h1>
        <span>Login your account</span>
      </div>
    </div>
  </div>
</section>
<section class="contentOuter">
      <div className="login-page">
        <Container>
          <Row >
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                    <CardHeader>
                      <h3 className="header text-center">Reset Password</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        placeholder="Verification Code"
                        type="text"
                        name="otp"
                        value={this.state.otp}
                        onChange={(e) => { this.changeInput(e)} }
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => { this.changeInput(e)} }
                        autoComplete="off"
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={(e) => { this.changeInput(e)} }
                        autoComplete="off"
                      />
                    </InputGroup>
                    <br />
                   
                  </CardBody>
                  <CardFooter>

                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      onClick={(e) => {this.postLogIn(e)}}
                    >
                      change Password
                    </Button>
                    <NavLink className="mt-5" to='/auth/forgot'>don't get Verification Code</NavLink>
                  </CardFooter>

                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
          }}
        />

      </div>
      </section>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
