import { useEffect, useState } from "react";

function useForm(initialData) {
  const [formData, setFormData] = useState(initialData);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValidForm = Object.values(formData).reduce((acc, curr) => {
      if (!curr.isValid) {
        acc = false;
      }
      return acc;
    }, true);

    setIsFormValid(isValidForm);
  }, [formData]);

  const onChangeHandler = (e) => {
    const { value, name: tempName } = e.target;
    const name = tempName;
    setFormData((prevFormData) => {
      const oldData = prevFormData[name];
      const isValid = oldData.validation(value, formData);
      const error = !isValid;
      return {
        ...prevFormData,
        [name]: {
          ...oldData,
          value,
          isValid,
          error,
        },
      };
    });
  };

  const makeFormEmpty = () => {
    setFormData(initialData);
  };

  console.log(formData);
  
  return {
    makeFormEmpty,
    onChangeHandler,
    formData,
    isFormValid,
  };
}

export default useForm;
