import React, { useEffect, useState } from "react";
import classes from "../../assets/css/notification.module.css";
import { useHistory, useLocation } from "react-router-dom";
import API from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Msg from "./Msg";

const Bell = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    const fetch = async () => {
      let response = await API({
        url: "getNotifications",
        method: "GET",
      });

      if (response.status === 200 && response.data) {
        const importantNotifications = response.data.filter(
          (value) => value.is_important == 1
        );

        showImportantNotifications(importantNotifications);

        dispatch(setNotifications(response.data));
      }
    };

    fetch();
  }, [pathname]);

  const showImportantNotifications = (notifications) => {
    notifications.forEach((notification) => {
      toast.info(<Msg notification={notification} />, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        toastId: notification.id,
        icon: false,
        onClose: function ({ notification }) {
          markAsRead(notification.id);
        },
      });
    });
  };

  const markAsRead = async (id) => {
    let response = await API({
      url: "markNotificationAsRead",
      method: "POST",
      data: { id },
    });

    if (response.status === 200) {
      dispatch(setNotifications(response.data));
    }
  };

  const handleClick = () => {
    history.push("/admin/notifications");
  };

  return (
    <>
      <div
        className={"pl-3 bell-wrapper"}
        style={{ height: "26px", width: "40px", cursor: "pointer" }}
        onClick={handleClick}
      >
        <div className={classes["notification-box"]}>
          <span className={classes["notification-count"]}>
            {notifications.length}
          </span>
          <div
            className={`${classes["notification-bell"]} ${
              notifications.length ? classes["notification-bell-animation"] : ""
            }`}
          >
            <span className={classes["bell-top"]}></span>
            <span className={classes["bell-middle"]}></span>
            <span className={classes["bell-bottom"]}></span>
            <span
              className={`${classes["bell-rad"]} ${classes["ball-rad-animation"]}`}
            ></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bell;
