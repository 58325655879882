import React, { useState } from "react";
import SpeakingDetails from "components/Dashboard/PracticeDetails/SpeakingDetails.jsx";
import WritingDetails from "components/Dashboard/PracticeDetails/WritingDetails.jsx";
import ReadingDetails from "components/Dashboard/PracticeDetails/ReadingDetails.jsx";
import ListeningDetails from "components/Dashboard/PracticeDetails/ListeningDetails.jsx";

export default function PracticeDetails(props) {

	const { speaking, writing, reading, listening } = props.practiceData;
	return (
		<div className="row">
			<SpeakingDetails
				attempted={speaking.done}
				total={speaking.total}
				average_score={props.show_average_score}
				category="1"
			/>
			<WritingDetails
				attempted={writing.done}
				total={writing.total}
				average_score={props.show_average_score}
				category="2"
			/>
			<ReadingDetails
				attempted={reading.done}
				total={reading.total}
				average_score={props.show_average_score}
				category="3"
			/>
			<ListeningDetails
				attempted={listening.done}
				total={listening.total}
				average_score={props.show_average_score}
				category="4"
			/>
		</div>
	);
}
