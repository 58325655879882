import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class PayOnline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pay_amount:'',
            email:"",
            currency:"",
            error:[],
            paymentForm:false,
            submit:false,
            disableSubmit:false,
            flag:false,
            lockSubmit:false,
            cvc: '',
            message: '',
            expYear: '',
            expMonth: '',
            cardNumber: '',
            formProcess: false,
            paymentStatus:false,
            plan:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
        this.loadStripe();
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }
    submitForm = () =>{
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            let json = {
                "pay_amount": this.state.pay_amount,
                "email": this.state.email,
                "currency": this.state.currency,
                "cardNumber": this.state.cardNumber,
                "expMonth": this.state.expMonth,
                "expYear": this.state.expYear,
                "cvc": this.state.cvv,
            }
            console.log(this.state);
            this.setState({submit:true,paymentForm:true,disableSubmit:true,flag:true});
            // store.dispatch(setAlert({'flag':true,'type':1,'message':'Please Enter Card Details!'}));
            window.Stripe.card.createToken({
                number: this.state.cardNumber,
                exp_month: this.state.expMonth,
                exp_year: this.state.expYear,
                cvc: this.state.cvv
            },async (status, response) => {
                if (status === 200) {
                    this.setState({
                        message: 'sending payment..',
                        formProcess: false,
                        lockSubmit: false
                    });
                    var bodyFormData = new FormData();
                    bodyFormData.set('email', this.state.email);
                    bodyFormData.set('pay_amount', this.state.pay_amount);
                    bodyFormData.set('currency', this.state.currency);
                    bodyFormData.set('token', response.id);
                    let res = null;
                    console.log(bodyFormData);
                    console.log(this.state);
                    console.log(response.id);
                    try{
                        res = await API({
                            method: 'POST',
                            url: 'payouts',
                            data: bodyFormData,
                        })
                    }catch(error) {
                        console.log(error);
                    };
                    if(res){
                        if (res.status === 200) {
                            console.log(res.data.message);
                            this.setState({paymentStatus:true});
                        }else if (res.status === 202) {
                            console.log(res.data.message);
                            this.setState({message:res.data.message,paymentStatus:false});
                            store.dispatch(setAlert({'flag':true,'type':2,'message':res.data.message}));
                        }
                    }
                } else {
                    this.setState({
                        message: response.error.message,
                        formProcess: false,
                        lockSubmit: false
                    });
                }
            });
        } else {
            console.log('else');
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    loadStripe = () => {
        let key;
        if(process.env.REACT_APP_ENV === 'production') {
        	key = process.env.REACT_APP_STRIPE_LIVE_APP_KEY
        } else {
        	key = process.env.REACT_APP_STRIPE_TEST_APP_KEY
        }

        if(!window.document.getElementById('stripe-script')) {
            var s = window.document.createElement("script");
            s.id = "stripe-script";
            s.type = "text/javascript";
            s.src = "https://js.stripe.com/v2/";
            s.onload = () => {
                window['Stripe'].setPublishableKey(key);
            }
            window.document.body.appendChild(s);
        }
    }
 render(){
    if (this.state.paymentStatus) {
        this.props.history.push({
            pathname: `/thank-you-payout`,
            state: this.state
        });
    }
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
        <meta name="twitter:title" content="Language Academy" />
        <meta property="og:title" content="Language Academy" />
    </Helmet>
    <Header />
        <div class="innerPage payoutBnr">
            <section class="bannerOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h1>Pay Online</h1>
                            <span>Payment Gateway System Page For Easy Transaction </span><br />
                            <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="section-title text-center pl-40 pr-40 mt-5">
                <h2>Enter Payment Details</h2>
        </section>
        <section class="paysection">
            <div class="container">
                <div class="col-sm-8 payOnlineForm" id="payOnlineFormId">
                    <p className="container text-center text-danger"><b><i>{this.state.message}</i></b></p>
                    <div class="fieldOuter">
                        <label>Pay Amount</label>
                        <input type="number" name="pay_amount" onChange={this.handleChange} value={this.state.pay_amount} placeholder="Pay Amount..." min="0" />
                        {this.validator.message('Pay Amount', this.state.pay_amount, 'required')}
                    </div>
                    <div class="fieldOuter">
                        <label>Your Email</label>
                        <input type="email" onChange={this.handleChange} value={this.state.email} name="email" placeholder="Your email..." />
                        {this.validator.message('Email', this.state.email, 'required|email')}
                    </div>
                    <div class="fieldOuter">
                        <label>Select Currency</label>
                        <select class="form-control currency" name="currency" onChange={(e) => { this.handleChange(e)} } value={this.state.currency}>
                            <option value="">Select Currency</option>
                            <option value="USD">United States Dollar (USD)</option>
                            <option value="AED">United Arab Emirates dirham (AED)</option>
                            <option value="AFN">Afghanistan (AFN)</option>
                            <option value="ALL">Albania (ALL)</option>
                            <option value="AMD">Armenian dram (AMD)</option>
                            <option value="ANG">Netherlands Antillean guilder (ANG)</option>
                            <option value="AOA">Angola (AOA)</option>
                            <option value="ARS">Argentina (ARS)</option>
                            <option value="AUD">Australian dollar (AUD)</option>
                            <option value="AWG">Aruban florin (AWG)</option>
                            <option value="AZN">Azerbaijan (AZN)</option>
                            <option value="BAM">Bosnia-Herzegovina Convertible Marka (BAM)</option>
                            <option value="BBD">Barbados (BBD)</option>
                            <option value="BDT">Bangladesh (BDT)</option>
                            <option value="BGN">Bulgaria (BGN)</option>
                            <option value="BIF">Burundi (BIF)</option>
                            <option value="BMD">Bermuda (BMD)</option>
                            <option value="BND">Brunei (BND)</option>
                            <option value="BOB">Bolivia (BOB)</option>
                            <option value="BRL">Brazil (BRL)</option>
                            <option value="BSD">Bahamas (BSD)</option>
                            <option value="BWP">Botswana (BWP)</option>
                            <option value="BYN">Belarus (BYN)</option>
                            <option value="BZD">Belize (BZD)</option>
                            <option value="CAD">Canada (CAD)</option>
                            <option value="CDF">Democratic Republic of the Congo (CDF)</option>
                            <option value="CHF">Swiss franc (CHF)</option>
                            <option value="CLP">Chilean peso (CLP)</option>
                            <option value="CNY">China (CNY)</option>
                            <option value="COP">Colombia (COP)</option>
                            <option value="CRC">Costa Rica (CRC)</option>
                            <option value="CVE">Cape Verde (CVE)</option>
                            <option value="CZK">Czechia (CZK)</option>
                            <option value="DJF">Djibouti (DJF)</option>
                            <option value="DKK">Danish krone (DKK)</option>
                            <option value="DOP">Dominican Republic (DOP)</option>
                            <option value="DZD">Algerian dinar (DZD)</option>
                            <option value="EGP">Egyptian pound (EGP)</option>
                            <option value="ETB">Ethiopia (ETB)</option>
                            <option value="EUR">Euro (EUR)</option>
                            <option value="FJD">Fiji (FJD)</option>
                            <option value="FKP">Falkland Islands pound (FKP)</option>
                            <option value="GBP">Pound sterling (GBP)</option>
                            <option value="GEL">Georgia (GEL)</option>
                            <option value="GIP">Gibraltar (GIP)</option>
                            <option value="GMD">Gambia (GMD)</option>
                            <option value="GNF">Guinea (GNF)</option>
                            <option value="GTQ">Guatemala (GTQ)</option>
                            <option value="GYD">Guyana (GYD)</option>
                            <option value="HKD">Hong Kong (HKD)</option>
                            <option value="HNL">Honduras (HNL)</option>
                            <option value="HRK">Croatia (HRK)</option>
                            <option value="HTG">Haiti (HTG)</option>
                            <option value="HUF">Hungary (HUF)</option>
                            <option value="IDR">Indonesia (IDR)</option>
                            <option value="ILS">Israeli Shekel (ILS)</option>
                            <option value="INR">Indian rupee (INR)</option>
                            <option value="ISK">Iceland (ISK)</option>
                            <option value="JMD">Jamaica (JMD)</option>
                            <option value="JPY">Japan (JPY)</option>
                            <option value="KES">Kenya (KES)</option>
                            <option value="KGS">Kyrgyzstan (KGS)</option>
                            <option value="KHR">Cambodia (KHR)</option>
                            <option value="KMF">Comoros (KMF)</option>
                            <option value="KRW">South Korean won (KRW)</option>
                            <option value="KYD">Cayman Islands (KYD)</option>
                            <option value="KZT">Kazakhstan (KZT)</option>
                            <option value="LAK">Laos (LAK)</option>
                            <option value="LBP">Lebanon (LBP)</option>
                            <option value="LKR">Sri Lanka (LKR)</option>
                            <option value="LRD">Liberia (LRD)</option>
                            <option value="LSL">Lesotho (LSL)</option>
                            <option value="MAD">Moroccan dirham (MAD)</option>
                            <option value="MDL">Moldova (MDL)</option>
                            <option value="MGA">Madagascar (MGA)</option>
                            <option value="MKD">North Macedonia (MKD)</option>
                            <option value="MMK">Myanmar (MMK)</option>
                            <option value="MNT">Mongolia (MNT)</option>
                            <option value="MOP">Macao (MOP)</option>
                            <option value="MRO">Mauritanian ouguiya (MRO)</option>
                            <option value="MUR">Mauritius (MUR)</option>
                            <option value="MVR">Maldives (MVR)</option>
                            <option value="MWK">Malawi (MWK)</option>
                            <option value="MXN">Mexico (MXN)</option>
                            <option value="MYR">Malaysia (MYR)</option>
                            <option value="MZN">Mozambique (MZN)</option>
                            <option value="NAD">Namibia (NAD)</option>
                            <option value="NGN">Nigeria (NGN)</option>
                            <option value="NIO">Nicaragua (NIO)</option>
                            <option value="NOK">Norwegian krone (NOK)</option>
                            <option value="NPR">Nepal (NPR)</option>
                            <option value="NZD">New Zealand dollar (NZD)</option>
                            <option value="PAB">Panama (PAB)</option>
                            <option value="PEN">Peru (PEN)</option>
                            <option value="PGK">Papua New Guinea (PGK)</option>
                            <option value="PHP">Philippines (PHP)</option>
                            <option value="PKR">Pakistan (PKR)</option>
                            <option value="PLN">Poland (PLN)</option>
                            <option value="PYG">Paraguay (PYG)</option>
                            <option value="QAR">Qatar (QAR)</option>
                            <option value="RON">Romanian leu (RON)</option>
                            <option value="RSD">Serbia (RSD)</option>
                            <option value="RUB">Russian ruble (RUB)</option>
                            <option value="RWF">Rwanda (RWF)</option>
                            <option value="SAR">Saudi Arabia (SAR)</option>
                            <option value="SBD">Solomon Islands (SBD)</option>
                            <option value="SCR">Seychelles (SCR)</option>
                            <option value="SEK">Sweden (SEK)</option>
                            <option value="SGD">Singapore (SGD)</option>
                            <option value="SHP">Saint Helena, Ascension and Tristan da Cunha (SHP)</option>
                            <option value="SLL">Sierra Leone (SLL)</option>
                            <option value="SOS">Somali shilling (SOS)</option>
                            <option value="SRD">Suriname (SRD)</option>
                            <option value="STD">São Tomé and Príncipe (STD)</option>
                            <option value="SZL">Eswatini (SZL)</option>
                            <option value="THB">Thailand (THB)</option>
                            <option value="TJS">Tajikistan (TJS)</option>
                            <option value="TOP">TONGA Pa’anga (TOP)</option>
                            <option value="TRY">Turkish Lira (TRY)</option>
                            <option value="TTD">Trinidad and Tobago Dollar (TTD)</option>
                            <option value="TWD">New Taiwan Dollar (TWD)</option>
                            <option value="TZS">TANZANIA (TZS)</option>
                            <option value="UAH">Ukraine (UAH)</option>
                            <option value="UGX">Uganda (UGX)</option>
                            <option value="UYU">Uruguay (UYU)</option>
                            <option value="UZS">Uzbekistan (UZS)</option>
                            <option value="VND">Vietnam (VND)</option>
                            <option value="VUV">Vanuatu (VUV)</option>
                            <option value="WST">Samoa (WST)</option>
                            <option value="XAF">Central African CFA franc (XAF)</option>
                            <option value="XCD">Eastern Caribbean dollar (XCD)</option>
                            <option value="XOF">West African CFA franc (XOF)</option>
                            <option value="XPF">CFP franc (XPF)</option>
                            <option value="YER">Yemen (YER)</option>
                            <option value="ZAR">South African rand (ZAR)</option>
                            <option value="ZMW">Zambia (ZMW)</option>
                        </select>
                        {this.validator.message('Currency', this.state.currency, 'required')}
                    </div>
                    <div className="fieldOuter">
                        <label>CARD NUMBER</label>
                        <div id="cardNumber">
                            <input type="text" className="form-control" placeholder="Valid Card Number" name="cardNumber" maxLength="18" onChange={this.handleChange} value={this.state.cardNumber}/>
                            <span className="input-group-addon"><span className="fa fa-credit-card"></span></span>
                        </div>
                        {this.validator.message('Card Number', this.state.cardNumber, 'required')}
                    </div>
                    <div className="fieldOuter col-md-4 col-sm-12" id="expMonth">
                        <label>EXPIRATION MONTH</label>
                        <select name="expMonth" className="form-control expMonth" onChange={this.handleChange}>
                            <option value="">Select Month</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        {this.validator.message('expMonth', this.state.expMonth, 'required')}
                    </div>
                    <div className="fieldOuter col-md-4 col-sm-12" id="expYear">
                        <label>EXPIRATION YEAR</label>
                        <select name="expYear" className="form-control expYear" onChange={this.handleChange}>
                            <option value="">Select Year</option>
                            <option value={new Date().getYear() - 100}>{1900 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 99}>{1901 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 98}>{1902 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 97}>{1903 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 96}>{1904 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 95}>{1905 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 94}>{1906 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 93}>{1907 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 92}>{1908 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 91}>{1909 + new Date().getYear()}</option>
                            <option value={new Date().getYear() - 90}>{1910 + new Date().getYear()}</option>
                        </select>
                        {this.validator.message('expYear', this.state.expYear, 'required')}
                    </div>
                    <div className="fieldOuter col-md-4 col-sm-12" id="cvv">
                        <label>CVV CODE</label>
                        <input type="text" name="cvv" className="form-control" placeholder="CVV" maxLength="4" onChange={this.handleChange} />
                        {this.validator.message('cvv', this.state.cvv, 'required')}
                    </div>
                    <div class="fieldOuter text-center">
                        {!this.state.lockSubmit && <button type="submit" disabled={this.state.lockSubmit} name="" onClick={this.submitForm} class="themeBtn" >Submit</button>}
                        {this.state.lockSubmit && <button type="submit" name="" class="themeBtn" >Checking User...</button>}
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default PayOnline;
