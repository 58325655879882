/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import {store,persistor} from 'store/index';
import API,{ base} from "api.js";
import AOS from 'aos';
class Header extends React.Component {
    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            homePage:false,
            staffFlag:false,
            isMainSite:false,
            logoIcon:"",
        }
    }
    componentDidMount() {
        const isMainSite = (window.location.hostname.split('.').shift() === "www")?true:false;
        this.setState({isMainSite:isMainSite});
        window.scrollTo(0, 0);
        if (!isMainSite) {
          var bodyFormData = new FormData();
          bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
          API({
            method: 'POST',
            url: 'get-logo',
            data:bodyFormData
          }).then(data => {

            if (data.data.data) {
              this.setState({logoIcon:data.data.data});
            }
            
          }).catch(()=>{
            
          });
        }
    }

    openStaff = () =>{
        this.setState({staffFlag:!this.state.staffFlag})
    }
 render(){
	return (
		<header id="header">
      <div class="container d-flex align-items-center">

        <h1 class="logo-portal mr-auto"><Link to="/"><img src="/assets/img/logo.png" /></Link></h1>

        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li className={(this.props.active === "home")?"active":""}><Link to="/">Home</Link></li>
            <li className={(this.props.active === "about")?"active":""}><Link to="/about">About</Link></li>
            <li className={(this.props.active === "contact")?"active":""}><Link to="/contact">Contact</Link></li>
            
            <li className="log-in"><Link to="/auth/login">Login</Link></li>
            <li className="sign-up-free"><Link to="/auth/register">Sign Up Free</Link></li>
          </ul>
        </nav>

      </div>
    </header>
	);
}
}

export default Header;