import React, { useState } from "react";
import {
  CardNumberElement,
  useElements,
  useStripe,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

let stripe;
const StripeElements = ({ purchase, formProcess, message, setFormProcess, planIsValid }) => {
  const stripe = useStripe();
  const elements = useElements();
  // const [cardError, setCardError] = useState(null);
  const [paymentError, setPaymentError] = useState({
    cardNumber: null,
    expiryDate: null,
    cvcError: null,
  });

  const submit = async () => {
    setFormProcess(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    // console.log(cardNumberElement);
    const { error, token } = await stripe.createToken(cardNumberElement);

    if (error) {
      console.log(error);
      setFormProcess(false);
    } else {
      purchase(token.id);
    }
  };

  const invalidSubmit = () => {
    console.log("You are trying to purchase an invalid plan. Please select other plan.");
  }

  const checkValidity = (name, event) => {
    const { error } = event;
    if (error && error.message) {
      setPaymentError((errors) => {
        return { ...errors, [name]: error };
      });
    } else {
      setPaymentError((errors) => {
        return { ...errors, [name]: null };
      });
    }
  };

  return (
    <div className="card-number">
      <div className="panel-body mt-5">
        <p>
          <b>{message}</b>
        </p>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="form-group">
              <label>Card Number</label>
              <CardNumberElement
                className="border stripe-elements bg-white"
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "red",
                    },
                  },
                }}
                onChange={checkValidity.bind(this, "cardNumber")}
              />
              {paymentError.cardNumber && paymentError.cardNumber.message && (
                <small className="color-danger">
                  {paymentError.cardNumber.message}
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-7 col-md-7">
            <div className="form-group">
              <label>
                <span className="hidden-xs">Expiration</span> Date
              </label>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <CardExpiryElement
                      className="border stripe-elements bg-white"
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "red",
                          },
                        },
                      }}
                      onChange={checkValidity.bind(this, "expiryDate")}
                    />
                    {paymentError.expiryDate &&
                      paymentError.expiryDate.message && (
                        <small className="color-danger">
                          {paymentError.expiryDate.message}
                        </small>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-5 col-md-5 pull-right">
            <div className="form-group">
              <label>CVC Code</label>
              <CardCvcElement
                className="border stripe-elements bg-white"
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "red",
                    },
                  },
                }}
                onChange={checkValidity.bind(this, "cvcError")}
              />
              {paymentError.cvcError && paymentError.cvcError.message && (
                <small className="color-danger">
                  {paymentError.cvcError.message}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="panel-footer">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            {formProcess ? (
              <button
                disabled={true}
                className="btn btn-warning btn-lg btn-block"
              >
                <img src="/images/white-lock.png" alt="white-lock" />
                Please wait...
              </button>
            ) : (
              <button
                className="btn btn-warning btn-lg btn-block text-light"
                onClick={planIsValid ? submit : invalidSubmit}
                disabled={planIsValid ? "" : "disabled"}
              >
                <img src="/images/white-lock.png" alt="white-lock" /> {planIsValid ? "Process payment" : "Invalid Plan. Please select Other"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeElements;
