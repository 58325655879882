import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWall from '../components/Frontend/FameWall';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Home extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet>
        <title>PTE Speaking Test Practice Material| Language Academy</title>
        <meta name="description" content="Prepare for the PTE speaking test with Language Academy's PTE speaking test practice material . Enhance your fluency and confidence with targeted exercises and feedback." />
        <meta name="twitter:title" content="PTE Speaking Material for Practice | Best PTE Speaking Practice Test" />
        <meta name="og:title" content="PTE Speaking Material for Practice | Best PTE Speaking Practice Test" />
        <meta name="twitter:description" content="Are you facing issues in PTE Academic speaking module and worried about your exam score? No need to worry! With adequate practice and the right PTE speaking material, you can surely bolster your skills and ace this module in the exam. If you want the best PTE speaking practice test material & sample questions, or seeking expert assistance to attain your desired scores, contact us at info@languageacademy.com.au." />
        <meta property="og:description" content="Are you facing issues in PTE Academic speaking module and worried about your exam score? No need to worry! With adequate practice and the right PTE speaking material, you can surely bolster your skills and ace this module in the exam. If you want the best PTE speaking practice test material & sample questions, or seeking expert assistance to attain your desired scores, contact us at info@languageacademy.com.au." />
      </Helmet>
    <Header />
    <div className="innerPage">
    <section className="bannerOuter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Brush up your English skills with the Best Online PTE Speaking practice test</h1>
                        <span>Avail the best PTE Speaking Material to practice, and score higher in the exam</span><br />
                        <a href="javascript:;" className="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                    </div>
                </div>
            </div>
        </section>
      </div>
      <section className="pteInfo">
         <div className="container">
            <div className="row">
              <div className="col-lg-10 col-lg-offset-1">
                  <p className="web-content">
                     Many aspirants perform well in writing, reading, and speaking modules, but when it comes to the PTE speaking test module, they find themselves all at sea. When they try to speak in English, they feel as if they have suddenly run out of words despite having a lot of thoughts in mind. Do you also face difficulties in converting your ideas to speech, and find yourself fumbling in the English speaking test? Don't get disappointed! Adequate practice with the right PTE speaking material is the key to overcome these issues.
                  </p>
                  <p className="web-content">
                     To boost your preparation and make yourself exam-ready, you need to practice a good number of PTE speaking sample questions so that you can avoid the long pauses or gaps while speaking in English. Moreover, you must couple your practice with a proper study plan, that includes attempting the mock tests or PTE speaking practice test sets at regular intervals, to accurately gauge your performance. For making a proper study plan to ace the exam, you can take guidance from the expert PTE trainers.
                  </p>
                  <p className="web-content">Are you searching for the top PTE tutors who can assist you with formulating a proper preparation plan and picking the right PTE academic speaking practice test material? Call us on <a style={{color: 'rgb(81, 203, 206)'}} href="tel:+61426230061" target="blank">+61 426230061</a> and get solutions to all your exam related queries.</p>
               </div>
            </div>
         </div>
      </section>
    <section id="pte-modules">
         <div className="container">
            <div className="row text-center">
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-listening-test-practice-material">
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/listening.png" className="listening" />
                        </div>
                        <h3 className="module-title">Listening</h3>
                        <p className="module-description">Start preparing for the listening section using the latest practice material.</p>
                     </div>
                  </Link>
               </div>
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-writing-test-practice-material">
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/writing.png" className="writing" />
                        </div>
                        <h3 className="module-title">Writing</h3>
                        <p className="module-description">Sharpen your writing skills with the best PTE writing practice material.</p>
                     </div>
                  </Link>
               </div>
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-reading-test-practice-material">
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/reading.png" className="reading" />
                        </div>
                        <h3 className="module-title">Reading</h3>
                        <p className="module-description">Get the intensive PTE reading practice material to skyrocket your score.</p>
                     </div>
                  </Link>
               </div>
            </div>
         </div>
      </section>
    <WhyLove />
    <Testimonial />
    <Footer />
    </React.Fragment>);
    }
}

export default Home;
