import React from 'react';
import { Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import WhyLove from '../components/Frontend/WhyLove';
import VideoTestimonial from '../components/Frontend/VideoNaatiTestimonial';
import NaatiTut from '../components/Frontend/NaatiTut';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Slider from "react-slick";
import { Link} from 'react-router-dom';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        readMoreFlag:false,
        course:'NAATI CCL Online',
        query:'',
        lang:"Hindi",
        flag:false,
        loginLock:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});

      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>Language:</b> '+this.state.lang+'<br/><b>Course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "NAATI Online coaching");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {


      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }


  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
  changeReadMore = () =>{
    this.setState({readMoreFlag:!this.state.readMoreFlag})
  }
 render(){
    if (this.state.flag) {
      return (<Redirect to="/thank-you" />);
    }
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1
    };
    return (
    <React.Fragment>
    <div className="innerPage naatiCCL">
    <Helmet>
        <title>NAATI CCL Online Coaching Classes Australia | Language Academy</title>
        <meta name="twitter:title" content="NAATI CCL Online Coaching Centre - Language Academy" />
      <meta property="og:title" content="NAATI CCL Online Coaching Centre - Language Academy" />
      <meta name="description" content="Prepare for the NAATI CCL exam with Language Academy's online coaching classes in Australia. Access expert guidance and practice materials to succeed." />

        <meta name="twitter:description" content="Language Academy, one of the best NAATI CCL online training centre in Australia. We help you to prepare for the NAATI test course in Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati, Sinhalese and help you perform better in the exam. To join the best NAATI CCL preparation online classes, get in touch with us now!" />
        <meta property="og:description" content="Language Academy, one of the best NAATI CCL online training centre in Australia. We help you to prepare for the NAATI test course in Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati, Sinhalese and help you perform better in the exam. To join the best NAATI CCL preparation online classes, get in touch with us now!" />
    </Helmet>
    <Header />
  <section className="bannerOuter">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h1>Online Coaching Classes for NAATI Exam Preparation</h1>
          <div className="row bannerCntnt">
            <div className="col-sm-8 my-auto">
              <iframe src="https://www.youtube.com/embed/6tJN984IoQ4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="col-sm-4 bannerForm">
              <h3>Take a FREE Demo Class</h3>
              <form onSubmit={this.submitForm}>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Email', this.state.email, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={this.state.phone}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </div>
                <div className="fieldOuter">
                  <select
                    name="course"
                    value={this.state.course}
                    onChange={(e) => { this.changeInput(e)} }
					value={this.state.course}
                  >
                    <option value="NAATI CCL Online">NAATI CCL Online</option>
                    <option value="PTE On-Campus1">PTE On-Campus</option>
                    <option value="NAATI CCL Online">PTE Online</option>
                    <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                    <option value="IELTS Online">IELTS Online</option>
                    <option value="IELTS On-Campus">IELTS On-Campus</option>
                  </select>
                </div>
                <div className="fieldOuter">
                  <select
                    name="lang"
                    value={this.state.lang}
                    onChange={(e) => { this.changeInput(e)} }
					value={this.state.lang}
                  >
                    <option value="Hindi">Hindi</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Nepali">Nepali</option>
                    <option value="Bangla">Bangla</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Sinhalese">Sinhalese</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="Kannada">Kannada</option>
                  </select>
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="query"
                    placeholder="Comments/Query"
                    value={this.state.query}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Query', this.state.query, 'required')}
                </div>
                <div className="fieldOuter">
                  {!this.state.loginLock && <button className="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                  {this.state.loginLock && <button className="themeBtn" >Wait...</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="whatispte">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="title">About <span>NAATI CCL Course</span></h2>
          <p>NAATI CCL Test assesses the aspirant’s language skills at a community level in many languages. The test assesses how well a candidate can translate the conversation dialogues from English to their choice of language and vice versa. The Language Academy experts help the aspirants to take the NAATI CCL test in 8 different languages like Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati and Sinhalese.</p>
          <p>A test taker has to listen to a recording and interpret the ongoing conversation in another language. They get both English dialogues and dialogues in their chosen language to have a thorough assessment of their interpretation skills from/to English.</p>
          {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
            {this.state.readMoreFlag && <React.Fragment><h5>What is the pattern of NAATI CCL Test?</h5>
          <p>NAATI CCL Test has recorded conversation dialogues. Each recording has two dialogues of 300 words each. These both recordings must be translated and interpreted in half an hour. Each recording has some segments of 35 words which each carries 45 marks. Securing a minimum of 29 in each dialogue is a requisite for qualifying the NAATI CCL test successfully.</p>

          <h5>Why take NAATI CCL training online?</h5>
          <p>Attending an on-campus program could be difficult for some people, so, attending a NAATI CCL online course can help. If you’re among such few who cannot attend classes, then you must Enrol in a NAATI CCL online coaching.</p>
          <p>NAATI CCL Online Classes let you hone your translation and interpretation skills with the ease of accessing the course from anywhere you want. You can easily balance your underlying commitments and NAATI CCL training by attending NAATI CCL online coaching.</p>
          <p>Crack NAATI CCL exams with Language Academy. You can prepare with both online coaching and in-class coaching for NAATI CCL exam preparation and upscale your English Translation and Interpretation skills!</p>
          <h5>What all does the NAATI online preparation course at Language Academy Includes?</h5>
          <p>At Language Academy, our NAATI CCL online preparation course curriculum includes modules strategized after carefully assessing your skills. We understand how important this NAATI CCL online course is for you to claim five additional extra points towards your Australian permanent residency. You can quickly take your scores to peak with our proven strategies and personalized feedback that are made to help you prepare better and improve your performance.</p></React.Fragment>}
          {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
        </div>
      </div>
    </div>
  </section>
  <section className="practicePortal">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2 className="title">Language Academy <span>NAATI CCL Mobile Application</span> For NAATI CCL Preparation </h2>
          <p>This mobile app consists of preparation with a list of all the comprehensive vocabulary as well as dialogues with sample answers.</p>
          <div className="row whyloveCntnt">
            <div className="col-sm-4">
              <img src="images/experienced-trainer.png" alt="experienced-trainer" />
              <h3>Vocabulary in 7 different categories</h3>
              <p>A complete list of 2000+ words is included and explained in the various native language with the translation.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/star-ribben.png" alt="star-ribben" />
              <h3>Mock tests like real-time Exam</h3>
              <p>The feel of the actual test environment helps you to understand properly what can you expect and how to perform.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/book.png" alt="book" />
              <h3>Comprehensive practice dialogue</h3>
              <p>Dialogues from different categories and scenarios similar to the ones that came in previous exams are included in it.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/settings.png" alt="settings" />
              <h3>80+ practice dialogues</h3>
              <p>Dialogue with sample responses and answers prepared by certified interpreters are included.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Last-minute package</h3>
              <p>Short packages are given with tips to boost your confidence and prepare well before the Exam.</p>
            </div>
          </div>
          <p>Let’s make your learning meaningful and successful!</p>
          <button className="themeBtn">Get Started</button>
        </div>
      </div>
    </div>
  </section>
  {false && <section className="featurePricing">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h2 className="title"><span>Packages</span></h2>
                    <p></p>
                    <div className="featurePlans smallFP">
                        <div className="featurePlan">
                            <h3>Features</h3>
                            <ul>
                                <li>Price</li>
                                <li>Full Access to NAATI CCL App</li>
                                <li>Access to comprehensive practice dialogues with Sample Answers</li>
                                <li>Access to Last Minute Practice dialogues with Sample Answers</li>
                                <li>Access to Mock Tests with Sample Answers</li>
                                <li>Access to comprehensive vocabulary with answers</li>
                                <li>Live Practice Sessions with NAATI certified Interpreter</li>
                                <li>Live weekly mock test with Feedback</li>
                            </ul>
                        </div>
                        <div className="featurePackage">
                            <h3>PLUS (4 WEEKS)</h3>
                            <ul>
                                <li><span className="packagePrice">$499</span>1 Month</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><button className="themeBtn">Enrol</button></li>
                            </ul>
                        </div>
                        <div className="featurePackage green">
                            <h3>PRO (8 WEEKS)</h3>
                            <ul>
                                <li><span className="packagePrice">$690</span>1 Month</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                <li><button className="themeBtn">Enrol</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>}
  <section className="whyLove">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2 className="title">Why choose Language Academy NAATI CCL exam preparation</h2>
          <div className="row whyloveCntnt">
            <div className="col-sm-4">
              <img src="images/experienced-trainer.png" alt="experienced-trainer" />
              <h3>Experienced Trainer</h3>
              <p>Our experienced trainers help you in improving your reading, writing, listening, and speaking sections for Online NAATI.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/star-ribben.png" alt="star-ribben" />
              <h3>Highest Success Rate</h3>
              <p>We continuously involve personally with every student, which enables them to unleash the potential in them and achieve the highest scores possible.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/book.png" alt="book" />
              <h3>Free Books</h3>
              <p>Free Templates Every student at the Language Academy gets proven templates to study and understand the best way to write an essay and leave the best impression.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/settings.png" alt="settings" />
              <h3>Latest Resources</h3>
              <p>Students can easily access the study material and take practice tests through our mobile app. Just in a few taps, they can get started.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Online Support</h3>
              <p>During the entire class duration, our experts can be reached out to seek any help. They will answer all your questions and help you anywhere you stuck.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>3500 + Questions to Practice</h3>
              <p>We have curated several previous question papers to help our students in practicing the common questions that are asked frequently.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Discussion Sessions</h3>
              <p>Our experts note the weaknesses and strengths of students during the discussion sessions to devise personalized study plans for them.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Affordable Fees</h3>
              <p>Our costs are pocket-friendly so the students can easily pay off their fees and improve their score.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Real Time Test Environment</h3>
              <p>Our mock tests provide the students with the actual experience of taking tests. Students can test their reading, listening, and writing skills.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="whyLove">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2 className="title"><span>How to</span> Prepare</h2>
          <p>Language Academy provides you the best practice materials that can help you achieve 5 bonus points required for Australian PR</p>
          <div className="row whyloveCntnt">
            <div className="col-sm-4">
              <div className="col-sm-12">
                <h3>NAATI CCL Practice Material</h3>
                <p>Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati and Sinhalese</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="col-sm-12">
                <h3>NAATI CCL Vocabulary</h3>
                <p>Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati and Sinhalese</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="col-sm-12">
                <h3>NAATI CCL Test Practice</h3>
                <p>Dialogues Hindi, Punjabi, Urdu, Nepali, Bangla, Tamil, Telugu, Malayalam, Gujarati and Sinhalese</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="whyLove">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2 className="title">How Does it <span>Works?</span></h2>
          <div className="row whyloveCntnt">
            <div className="col-sm-4">
              <img src="images/experienced-trainer.png" alt="experienced-trainer" />
              <h3>Step 1 - Orientation Class</h3>
              <p>Here you'll learn the basics of NAATI. The experts will guide you on how to gain maximum score in the test.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/star-ribben.png" alt="star-ribben" />
              <h3>Step 2 - Live Practice Session</h3>
              <p>Our trainers will monitor your performance, and will also give you feedback on your dialogues and vocab.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/book.png" alt="book" />
              <h3>Step 3 - App for CCL Practice</h3>
              <p>Access to CCL app helping you in excellent listening practice with audio recordings and sample answers.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/settings.png" alt="settings" />
              <h3>Step 4 - Weekly Mock Test</h3>
              <p>You will have weekly tests and will also get a full scorecard suggesting your scores, and areas of improvement.</p>
            </div>
            <div className="col-sm-4">
              <img src="images/support.png" alt="support" />
              <h3>Step 5 - Final Consultation</h3>
              <p>Once you complete the course, our experts will tell you how much more practice you should do before you book the exam.</p>
            </div>
          </div>
          <a href="https://www.languageacademy.com.au/auth/register"><button className="themeBtn">Achieve Your Desired Score</button></a>
        </div>
      </div>
    </div>
  </section>
  <VideoTestimonial title="What Our Students Say About Our NAATI CCL Exam Training" />
  <section className="fameWall">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Score Cards of Our <span>NAATI CCL Students</span></h2>

                        <Slider {...settings} classNameName="fameSlider regular01 slider">
                            <div>
                              <img src="images/naati/punjabi3.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="images/naati/urdu1.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="images/naati/hindi3.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="images/naati/nepali4.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="images/naati/bangla1.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="images/naati/nepali6.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="images/naati/urdu2.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="images/naati/urdu3.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="images/naati/hindi2.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="images/naati/nepali2.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="images/naati/nepali3.jpg" alt="fame-img-05" />
                            </div>
                        </Slider>
                        <Link to="/scorecard"><button className="themeBtn">Visit our Wall of Fame</button></Link>
                    </div>
                </div>
            </div>
        </section>
      <NaatiTut title="CCL Exam Preparation Tutorial Videos" />


<Footer />
</div>
</React.Fragment>);
}
}

export default Pte;
