import React, { useState } from "react";
import { Link } from "react-router-dom";
import API from "api.js";

export default function SpeakingDetails(props) {

	const [averageScore, setAverageScore] = useState(null);

	const getAverageScore = (event) => {
		API({
			method: "GET",
			url: `get/average/score?category=${props.category}`
		}).then(res => {
			setAverageScore(res.data.data);
		}).catch(err => console.log(err));
	}

	return (
		<div className="col-md-12 col-xl-6 mt-3">
			<div className="card detailCard cardSpeaking">
				<div className="row">
					<div className="col-md-4">
						<h4 className="m-0">Speaking</h4>
						<p className="m-0"><small>Questions</small></p>
					</div>
					<div className="col-md-8 mt-3 mt-md-0">
						<div className="row">
							<div className="col">
								<h4 className="m-0">{props.attempted}</h4>
								<p className="m-0" ><small>Done</small></p>
							</div>
							<div className="col">
								<h4 className="m-0">{props.total}</h4>
								<p className="m-0" ><small>Total</small></p>
							</div>
							{props.average_score === 1 &&
								<div className="col align-self-end">
									{averageScore !== null ? (
										<>
											<h4 className="m-0">{averageScore}</h4>
											<p className="m-0">
												<small>
													<Link className="text-white" to={`/admin/progress/${props.category}?mock=0`}>more details...</Link>
												</small>
											</p>
										</>
									) : (
										<>
											<small>
												<Link to="#" className="text-white underline" onClick={getAverageScore}>Avg Score</Link>
											</small>
											<p className="m-0">&nbsp;</p>
										</>
									)}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
