import SetPassword from "components/Auth/SetPassword";
import ModalPopup from "components/PopUps/CustomModal";
import React from "react";
import { useSelector } from "react-redux";

const withPasswordPopup = (Component) => {
  return (props) => {
    const passwordPopup = useSelector((state) => state.passwordPopup);

    return (
      <>
        <ModalPopup
          status={passwordPopup}
          toggle={() => {}}
          showCloseBtn={false}
          render={(toggle) => <SetPassword toggle={toggle} />}
        />
        <Component {...props} />
      </>
    );
  };
};

export default withPasswordPopup;
