import { s3base } from "api";
import React from "react";

function Branch({ branch }) {
  return (
    <>
      {branch && (
        <div className="col-sm-6">
          <div className="adresssBlockInr">
            <div className="mapOuter">
              <img src={s3base + branch.background_image} />
              <h3>{branch.title}</h3>
            </div>
            <div className="adressCntnt">
              <div className="businessName">{branch.business_name}</div>
              <div className="addressInfo">
                <p
                  style={{
                    height: "5em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {branch.address}
                </p>
              </div>
              <div className="contactNo">
                <a
                  style={{ color: "grey" }}
                  href={"tel:" + branch.contact_number_to_dial}
                >
                  {branch.contact_number_to_display}
                </a>
              </div>
              <div className="locationBtn">
                <a
                  href={branch.direction_link}
                  target="_blank"
                  className="themeBtn"
                >
                  Get Directions
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Branch;
