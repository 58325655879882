import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import API, {base} from "api.js";
import SidebarClose from "components/Sidebar/SidebarClose";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:false
    };
  }

  componentDidMount() {
    API({
        method: 'GET',
        url: 'template/'+this.props.match.params.id
      }).then(data => {
        this.setState({data:data.data.data});
    }).catch(()=>{
      
    });
      

  }
  
  render() {
    
    return (
      <>      
        {this.state.data && <div className="content questionsArea">
          <SidebarClose />
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs={6}>
                              <CardTitle tag="h4">
                                 {this.state.data && this.state.data.title}
                              </CardTitle>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          
                          <Row className='unselectable'>
                          <Col>
                          {this.state.data && <embed width="100%" height="500px" src={base+this.state.data.pdf+'#toolbar=0'} /> }
                          </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    
                  </Row>
                </div>}
      </>
    );
  }
}

export default Test;
