import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  Row,
  Col
} from "reactstrap";

class ChromeCheckGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      chrome:false,
      microphone:true
    };
  }
  componentDidMount() {
    let chrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg/.test(navigator.userAgent)  && !/Chromium/.test(navigator.userAgent);
    if (navigator.getUserMedia === undefined) {
      this.setState({microphone:false,chrome:chrome})
    }else{
      navigator.getUserMedia({ audio: true },
        () => {
          console.log('Permission Granted');
          this.setState({microphone:true});
        }, (error) => {
          console.log('Permission Denied', error);
          this.setState({microphone:false})
        },
      );
    }

    this.setState({ chrome:chrome});
  }

  getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);    
    return raw ? parseInt(raw[2], 10) : false;
  }

  render() {
    let { chrome, microphone } = this.state;
    return (
      <>
        <div className="content py-4 checkGlobalPage">
          <h2>Requirements { chrome && microphone ? (<span className='text-info'>satisfied</span>) : (<span className='text-danger'>not satisfied</span>)}</h2>
          <Row>
            <div className={`col-6`}>
              <Card className={`${chrome ? 'text-info' : 'text-danger'}`} style={{ borderTop: '15px solid' }}>
                <CardHeader>
                  <CardTitle tag="h4">
                    <i className="fa fa-chrome"></i>&nbsp;&nbsp;&nbsp;Chrome browser
                  </CardTitle>
                  <CardSubtitle>This test is compatible in Google chrome browser only.</CardSubtitle>
                </CardHeader>
                <CardBody>
                  {chrome ? (
                    <React.Fragment>
                      <i className="bx bx-check-circle"></i>
                      <em>*You are using Google-Chrome browser version: <b>{this.getChromeVersion()}</b>.</em>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="bx bx-x-circle"></i>
                      <em>*Please open chrome browser for practice question(s)</em>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className={`col-6`}>
              <Card className={`${microphone ? 'text-info' : 'text-danger'}`} style={{ borderTop: '15px solid' }}>
                <CardHeader>
                  <CardTitle tag="h4">
                    <i className="fa fa-microphone"></i>&nbsp;&nbsp;&nbsp;Microphone
                  </CardTitle>
                  <CardSubtitle>This test requires a microphone connected to your system.</CardSubtitle>
                </CardHeader>
                <CardBody>
                  {microphone ? (
                    <React.Fragment>
                      <i className="bx bx-check-circle"></i>
                      <em>*Microphone detected on your device.</em>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="bx bx-x-circle"></i>
                      <em>*We could not find any microphone connected to your device. Please connect microphone and refresh page.</em>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default ChromeCheckGlobal;
