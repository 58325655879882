import React from "react";
import { NavLink } from "react-router-dom";
import MenuItemDropdown from "components/Dashboard/Sidebar/MenuItemDropdown.jsx";
import Logout from "components/Auth/Logout.jsx";

export default function LeftMenuItem(props) {

	const { item, type, url, icon, label, premiumIcon, bottomMenu } = props;
	return (
		<li className={`menuItem menuItem${item} ${type === 2 ? "dropdown" : ""} ${bottomMenu === true ? "mt-lg-4" : ""}`}>
			{(() => {
				if (type === 1) {
					return (
						<NavLink to={`/admin/${url}`} activeClassName="menuCurrent">
							<span className={`menuIcon ${icon}`}></span>
							<span className={`menuText ${premiumIcon ? "crownIcon" : ""}`}>{label}</span>
						</NavLink>
					)
				} else if (type === 2) {
					return (
						<MenuItemDropdown {...props} />
					)
				} else if (type === 3) {
					return (
						<Logout icon={icon} premiumIcon={premiumIcon} label={label} />
					)
				}
			})()}
		</li>
	);
}
