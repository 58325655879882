import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { StatusCode } from "react-http-status-code";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class About extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <StatusCode code={404}>
    <React.Fragment>
    <Header />
        <div class="content innerPage notFound">
        <section class="bannerOuter">
        </section>
        <section class="pageContent text-center">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <img src="/images/404-img.jpg" />
                        <div class="mt-5">
                            <p>Uh oh, we can't seem to find the page you're looking for. Try going back to <a href="/">home page</a> or see contact us <a href="/contact">here</a> for more information.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    <Footer />
    </React.Fragment>
    </StatusCode>);
    }
}

export default About;