import React, { useState, useEffect } from "react";
import SidebarClose from "components/Sidebar/SidebarClose.jsx";
import { store } from "store/index";
import { base } from "api.js";
import { useSelector } from "react-redux";
import Bell from "components/Notification/Bell";

export default function UserDetails(props) {
  const { user, isSubscribed, userType } = useSelector((state) => {
    return {
      user: state.user,
      isSubscribed: state.subscribed,
      userType: state.userType,
    };
  });
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const [userDesc, setUserDesc] = useState("");

  useEffect(() => {
    // const stored = store.getState();
    // if ('user' in stored && stored.user !== null) {
    if (user) {
      const first_name =
        user.first_name === "" || user.first_name === null
          ? ""
          : user.first_name;
      setName(
        `${first_name} ${
          user.last_name === null || user.last_name === "null"
            ? ""
            : user.last_name
        }`
      );
      setAvatar(base + user.image);
      setSubscribed(isSubscribed);

      if (userType && user.active_subscription.length > 0) {
        switch (userType) {
          case "portal":
            setUserDesc(
              `Portal user (${user.active_subscription[0].plan.title})`
            );
            break;
          case "direct":
            setUserDesc(user.active_subscription[0].plan.title);
            break;
          case "campus":
            user.center && setUserDesc(user.center);
            break;
          default:
            break;
        }
      }
    }
  }, [user, isSubscribed, userType]);

  return (
    <div className="row py-4">
      <div className="col d-flex flex-column justify-content-center text-center">
        <div className="d-flex notification-wrapper justify-content-between">
          <Bell />
          {/*<SidebarClose />*/}
        </div>
        <div className="avatarImg premiumUser">
          <img className="userAvatar" src={avatar} alt="user image" />
          {subscribed && (
            <img
              className="crownImg"
              src="/images/new_dash/crown.svg"
              alt="premium user"
            />
          )}
        </div>
        <p className="text-white mt-3 pb-0 mb-0">{name}</p>
        <span className="text-white m-2" style={{ fontSize: "12px" }}>
          {userDesc}
        </span>
      </div>
    </div>
  );
}
