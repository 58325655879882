import React, { useState } from "react";
import {
  // Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function PlaybackSpeedBtns({
  playbackSpeed,
  changePlaybackSpeed,
}) {

  const OneX = 1;
  const OneX2 = 1.2;
  const OneX5 = 1.5;
  const speedOptions = [OneX, OneX2, OneX5];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(OneX);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSelect = (item) => {
    setSelected(item);
    changePlaybackSpeed(item);
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="label-class" caret>{selected}x</DropdownToggle>
        <DropdownMenu>
          {speedOptions.map((item, i) => {
            if (item !== selected) {
              return (<DropdownItem key={i} onClick={() => onSelect(item)}>{item} x</DropdownItem>)
            }
          })}
        </DropdownMenu>
      </Dropdown>


      {/*<Button
        color="success"
        disabled={playbackSpeed === 1}
        onClick={changePlaybackSpeed.bind(this, 1)}
        size="sm"
      >
        1x
      </Button>
      <Button
        color="success"
        disabled={playbackSpeed === 1.2}
        onClick={changePlaybackSpeed.bind(this, 1.2)}
        size="sm"
      >
        1.2x
      </Button>
      <Button
        color="success"
        disabled={playbackSpeed === 1.5}
        onClick={changePlaybackSpeed.bind(this, 1.5)}
        size="sm"
      >
        1.5x
      </Button>*/}
      {/* <Button color="success" disabled={playbackSpeed === 2} onClick={changePlaybackSpeed.bind(this, 2)} size="sm">2x</Button> */}
    </>
  );
}
