import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import AOS from 'aos';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class Home extends React.Component {

    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 10000);
        }
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: '/submit-query',
            data: bodyFormData
          })
        }catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);
        if (res.status === 200) {
          store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          this.setState({indexModal:false});       
        }else if (res.response.status === 422) {
          this.setState({error:res.response,process:false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag:str});
        }
    
    
  }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney</title>
        <meta name="twitter:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta property="og:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta name="description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />      
        
        <meta name="twitter:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />
        <meta property="og:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />      
      </Helmet>
    
  <section class="header-with-banner">
    <Header active="contact" />
    <section id="hero about-us-page" class="d-flex align-items-center">
      <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 mb-5">
            <h2 class="main-heading pt-5 text-center">Contact Us</h2>
            <div class="breadcum">
              <ol class="breadcrumb primary-color text-center">
                  <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                  <li class="breadcrumb-item active">Contact Us</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>

  <main id="main" class="avout-page">

    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/phone.png" class="img-fluid" alt="" />
              </div>
              <div class="pehraraph mt-3">
                <p><b>Phone</b><br/>+61 426230061</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/email.png" class="img-fluid" alt="" />
              </div>
              <div class="pehraraph mt-3">
                <p><b>Email </b><br/>Info@Pteportal.com</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/location.png" class="img-fluid" alt="" />
              </div>
              <div class="pehraraph mt-3">
                <p><b>Address</b><br/>46 George Street, Parramatta</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 footer-links pt-5">
            <h2 class="banner-heading text-center pt-5">Have Some Questions</h2>
                <p class="text-center sub-quest-head">We'd love to help you</p>
              <div class="list-of-online">
              </div>
          </div>
          <div class="col-xl-6 col-lg-6 pt-5">
            <img src="/assets/img/msg.png" class="img-fluid" alt="" />
          </div>

          <div class="col-xl-6 col-lg-6 pt-5 text-center client-form contact-us">
            <div class="form-heading">
            </div>
            <form>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="First Name" />
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Last Name" />
              </div>
              <div class="form-group">
                <input type="email" class="form-control" placeholder="What’s your Email?" />
              </div>
              <div class="form-group">
                <textarea class="form-control text-area-block" placeholder="Your Question" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>


      </div>
    </section>


    <section id="power-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Take Scored PTE Mock Test <i><b>Free</b></i> Now</h2>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="sign-box">
              <div class="sign-up-free">
                <button type="submit" class="btn btn-submit">Sign Up For Free</button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </section>

  </main>
  <Footer />
      
    </React.Fragment>);
    }
}

export default Home;