import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import API from "api.js";
import { Button } from "react-bootstrap";

const GoogleBtn = (props) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const credentialResponse = {
        ...tokenResponse,
        credential: tokenResponse.access_token,
      };
      console.log(credentialResponse);
      API({
        method: "POST",
        url: "/googleSignUp",
        data: credentialResponse,
      })
        .then((res) => {
          console.log(res);
          props.afterLogin(res);
        })
        .catch((err) => {
          // this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false});
        });
    },
  });
  
  return (
    <span style={{cursor: "pointer"}} onClick={() => login()}>
      <svg
        width="44"
        height="42"
        viewBox="0 0 44 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.6993 21.13C40.6993 19.6387 40.5708 18.5504 40.2928 17.4219H22.2144V24.1528H32.826C32.6121 25.8256 31.4568 28.3447 28.8894 30.0374L28.8534 30.2628L34.5695 34.434L34.9655 34.4712C38.6025 31.3071 40.6993 26.6518 40.6993 21.13Z"
          fill="#4285F4"
        ></path>
        <path
          d="M22.2132 38.8669C27.412 38.8669 31.7764 37.2546 34.9643 34.4736L28.8882 30.0398C27.2623 31.1079 25.08 31.8535 22.2132 31.8535C17.1213 31.8535 12.7997 28.6896 11.2591 24.3164L11.0333 24.3345L5.08969 28.6674L5.01196 28.8709C8.17832 34.7959 14.6823 38.8669 22.2132 38.8669Z"
          fill="#34A853"
        ></path>
        <path
          d="M11.2605 24.3173C10.854 23.1887 10.6187 21.9795 10.6187 20.7301C10.6187 19.4805 10.854 18.2714 11.2391 17.1428L11.2283 16.9025L5.21021 12.5L5.0133 12.5882C3.70829 15.0469 2.95947 17.8079 2.95947 20.7301C2.95947 23.6522 3.70829 26.4131 5.0133 28.8717L11.2605 24.3173Z"
          fill="#FBBC05"
        ></path>
        <path
          d="M22.2133 9.60299C25.8289 9.60299 28.2679 11.0741 29.6586 12.3035L35.0928 7.30561C31.7553 4.38345 27.4121 2.58984 22.2133 2.58984C14.6823 2.58984 8.17834 6.66069 5.01196 12.5856L11.2378 17.1402C12.7997 12.767 17.1214 9.60299 22.2133 9.60299Z"
          fill="#EB4335"
        ></path>
      </svg>
    </span>
  );
};

export default GoogleBtn;
