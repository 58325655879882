import React, { useState, useEffect } from "react";

export default function LoaderDumb(props) {
	const { show } = props;

	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		if (showLoader === show) {
			setShowLoader(!showLoader);
		}
	}, [show]);


	const blurModals = (items, op) => {
		if (op === 'add') for(let i=0; i<items.length; i++) items[i].classList.add('blur');
		else if (op === 'remove') for(let i=0; i<items.length; i++) items[i].classList.remove('blur');
	}

	return (
		<>
			{showLoader &&
				<div className="loaderWrap">
					<div className="la-loader">
						<img src="/images/new_dash/loader2.gif" alt="loader" />
					</div>
				</div>
			}
		</>
	);
}