import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col,
  Nav,
  NavItem,
  NavLink as NavLinkRS,
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";
import { store, persistor } from "store/index";
import { Helmet } from "react-helmet";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
import ContactUsPremium from "components/Help/ContactUsPremium";
import { connect } from "react-redux";
import SidebarClose from "components/Sidebar/SidebarClose";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember: true,
      loadmore: false,
      activeTab: 1,
    };
  }
  componentDidMount() {
    this.loadTemplate();
  }

  loadTemplate = () => {
    API({
      method: "GET",
      url: "help?skip=" + this.state.data.length + "&type=1",
    })
      .then((data) => {
        let loadmore = true;
        if (data.data.length < 10) {
          loadmore = false;
        }
        this.setState({
          data: [...this.state.data, ...data.data],
          loadmore: loadmore,
        });
      })
      .catch(() => {});
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content">
          <SidebarClose />
          <Row>
            <Col xs={12}>
              <Card>
                {!this.state.paidMember && (
                  <Alert color="success">
                    Please upgrade your plan to access secret templates.
                  </Alert>
                )}
                <CardHeader>
                  <CardTitle tag="h4">Help</CardTitle>
                </CardHeader>
                {this.props.user.active_subscription.length > 0 && (
                  <div>
                    <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                      tabs
                    >
                      <NavItem>
                        <NavLinkRS
                          className={this.state.activeTab === 1 ? "active" : ""}
                          onClick={() => {
                            this.setState({ activeTab: 1 });
                          }}
                        >
                          Help
                        </NavLinkRS>
                      </NavItem>
                      <NavItem>
                        <NavLinkRS
                          className={this.state.activeTab === 2 ? "active" : ""}
                          onClick={() => {
                            this.setState({ activeTab: 2 });
                          }}
                        >
                          Contact Us
                        </NavLinkRS>
                      </NavItem>
                    </Nav>
                  </div>
                )}
                <CardBody>
                  {this.state.activeTab === 1 && (
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="">Question</th>
                          <th className="">Resolution</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td className="">{val.title}</td>
                              <td className="">
                                <NavLink
                                  to={"/admin/viewpdf/" + val.id}
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  <i className="" />
                                  <p>View</p>
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                        {this.state.loadmore && (
                          <button
                            className="btn"
                            onClick={(e) => {
                              this.loadTemplate();
                            }}
                          >
                            Load more
                          </button>
                        )}
                      </tbody>
                    </Table>
                  )}
                  {this.state.activeTab === 2 && <ContactUsPremium />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(Template);
