import React, { useEffect } from "react";
import API from "../../api";
import { useHistory } from "react-router-dom";
import useLogOut from "hooks/useLogOut";

const Check = () => {
  const nav = useHistory();
  const { logoutDirectly } = useLogOut();
  useEffect(() => {
    // using then and catch instead of async await because useEffect callback should not return a promise
    API({
      url: "checkTokenStatus",
      method: "GET",
    })
      .then((res) => {
        if (res.status !== 200) {
          logoutDirectly();
          return;
        }
        nav.push("/admin/dashboard");
      })
      .catch((err) => {
        // not running in case of error, because of broken promise chain
      });
  }, []);

  return <></>;
};

export default Check;
