import React from "react";
import { useEffect } from "react";
import ct from "countries-and-timezones";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";

const NepalBranch = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname === "/auth/login" ||
      window.location.pathname === "/"
    ) {
      const timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
      const countryData = ct.getCountryForTimezone(timezone);
      if (countryData.name === "Nepal" && countryData.id === "NP") {
        setIsOpen(true);
      }
      console.log(countryData);
    }
  }, []);

  const close = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };
  return (
    <>
      {/* <Modal isOpen={isOpen} size="md" centered>
        <ModalHeader
          style={{ display: "flex" }}
          toggle={() => {
            setIsOpen(false);
          }}
        >
          <div style={{fontSize: "17px"}}>Important Information for LA Customers in Nepal</div>
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            <b>Our Branches</b>
          </p>
          <b>Butwal:</b> 11, kalikangr, kalikapath - opposite of Siddhartha Hardware, Rupandehi, Nepal<br />
          <b>Pokhara:</b> Siddarth Chowk, Pokhara 04 <br />
          <div class="onlineContact">
            <b className="onlineClasses">For Online Classes and Portal Subscription</b>
            <span className="contactNumber">+ 977 71591305, +977 9857056995</span>
          </div>
          <div class="instiBulkPackage">
            <b>For Institute Packages and Bulk Packages</b>
            <span className="instituteContact">+91 8360986507</span>
          </div>
        </ModalBody>
      </Modal> */}
      <Modal
        isOpen={isOpen}
        size="lg"
        id="anouncementPopup"
        centered
        style={{ maxWidth: "500px" }} className="nepalPopup"
      >
        <div className="text-left">
          <div className="modal-header pb-0" hidden="">
            <div>
              <div className="nepalPopupLogoImg">
                <img src="/images/logol.png" alt="logol" />
              </div>
              <h4 className="mt-0">
                Our Branches
                <a
                  onClick={close.bind(this)}
                  style={{ color: "#93c13e", opacity: "unset" }}
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
              </h4>
            </div>
          </div>
          <div
            className="modal-body pte_popup_modal_body"
            style={{ fontSize: "15px" }}
          >
            <p><b>Butwal:</b> 11, kalikangr, kalikapath - opposite of Siddhartha Hardware, Rupandehi, Nepal</p>
            <p><b>Pokhara:</b> Siddarth Chowk, Pokhara 04</p>            
            <div class="onlineContact">
              <b className="onlineClasses">For Online Classes and Portal Subscription</b>
              <span className="contactNumber">+ 977 71591305, +977 9857056995</span>
            </div>
            <div class="instiBulkPackage">
              <b>For Institute Packages and Bulk Packages</b>
              <span className="instituteContact">+91 8360986507</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NepalBranch;
