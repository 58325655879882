import React from "react";
import Mocks from "components/Dashboard/MyStats/Mocks.jsx";
import Practiced from "components/Dashboard/MyStats/Practiced.jsx";

export default function MyStats(props) {
	return (
		<div className="col-md-12 col-xl-8 statistics">
			<div className="borderTitle">
				<h3 className="text-uppercase fw-bold mb-3">My statistics</h3>
			</div>
			<div className="statsWrap">
				<Mocks {...props.mockData} />
				<Practiced count={props.practiceData.count} days={props.practiceData.days} />
			</div>
		</div>
	);
}
