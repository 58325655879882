import React from "react";
import { NavLink, Link } from "react-router-dom";
import LeftMenuItem from "components/Dashboard/Sidebar/LeftMenuItem.jsx";

export default function Menu(props) {
	const menuItems = [
		{
			type: 1,
			url: "dashboard",
			label: "Dashboard",
			icon: "menuIcon1",
			item: "1"
		},
		{
			type: 1,
			url: "test-material",
			label: "Practice Material",
			icon: "menuIcon2",
			item: "2"
		},
		{
			type: 1,
			url: "monthly-prediction",
			label: "Monthly Prediction",
			icon: "menuIcon4",
			item: "4"
		},
		{
			type: 1,
			url: "template",
			label: "Templates",
			icon: "menuIcon3",
			item: "3"
		},
		{
			type: 1,
			url: "prediction-files",
			label: "Prediction Files",
			icon: "menuIcon12",
			item: "12"
		},
		{
			type: 2,
			label: "Mock Tests",
			icon: "menuIcon5",
			item: "5",
			submenu: [
				{
					url: "mock",
					label: "Take Test"
				},
				{
					url: "pending",
					label: "Pending Test"
				},
				{
					url: "mock-result",
					label: "Test Result"
				}
			]
		},
		{
			type: 2,
			label: "Extensive Mock Tests",
			icon: "menuIcon5",
			item: "15",
			submenu: [
				{
					url: "mocks/new-sectional-tests",
					label: "Take Test"
				},
				{
					url: "pending/new-sectional-tests",
					label: "Pending Test"
				},
				{
					url: "results",
					label: "Test Result"
				},
			]
		},
		{
			type: 1,
			url: "videos",
			label: "Secret Strategy Videos",
			icon: "menuIcon6",
			premiumIcon: true,
			item: "6"
		},
		{
			type: 2,
			label: "Daily Feedback",
			icon: "menuIcon7",
			item: "7",
			submenu: [
				{
					url: "daily-task",
					label: "Daily Task"
				},
				{
					url: "success-plan",
					label: "Success Plan"
				},
				{
					url: "daily-feedback",
					label: "Daily Feedback"
				}
			]
		},
		{
			type: 1,
			url: "session",
			label: "Live Session",
			icon: "menuIcon9",
			item: "8"
		},
		{
			type: 1,
			url: "notifications",
			label: "Notifications",
			icon: "menuIcon14",
			item: "14"
		},
		{
			type: 1,
			url: "help",
			label: "Need Help",
			icon: "menuIcon13",
			item: "13"
		},
		{
			type: 1,
			url: "transaction",
			label: "Your Subscriptions",
			icon: "menuIcon9",
			item: "9",
			bottomMenu: true
		},
		{
			type: 1,
			url: "profile",
			label: "My Profile",
			icon: "menuIcon10",
			item: "10",
		},
		{
			type: 3,
			url: "javascript:void(0);",
			label: "Log Out",
			icon: "menuIcon11",
			item: "11",
			click: "logout"
		}
	];

	return (
		<div className="row">
			<div className="col">
				<nav className="nav-menu">
					<ul className="m-0 p-0 d-flex flex-column">
						{menuItems.map((menu, key) => {
							return <LeftMenuItem key={key} {...menu} />
						})}
					</ul>
				</nav>
			</div>
		</div>
	);
}
