import React,{Component} from "react";
import {
  Col,
  Progress,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import "components/custom.css";
import {store,persistor} from 'store/index';
class Essay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      content:'',
      lang:store.getState().user.lang,
      copy:'',
      resultObj:{},
      correctFlag:true,
      timeoutStat:false,
      showTimer: true,
    };
  }
  componentDidMount() {
  	var intervalId = setInterval(this.timer, 1000);
    const { isQuestionTime, questionTime } = this.props;
    if (isQuestionTime) {
      this.setState({
        secondCount: questionTime * 60,
        showTimer: isQuestionTime,
      });
    }
    this.setState({intervalId: intervalId});
  }


  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);

        const { isQuestionTime, questionTime } = props;
        let secondState = { secondCount: 1200, showTimer: true };
        if (isQuestionTime) {
          secondState = {
            secondCount: questionTime * 60,
            showTimer: isQuestionTime,
          };
        }

  			//var intervalId = setInterval(ReadAloud.timer1, 1000);
  			return {timeoutStat:false,correctFlag:true,...secondState,content:'',questionId: props.data.id,reset:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id,content:""};
  			}
  		}
  	}
  }

  stopTest = () =>{
    this.props.nextQuestion({...this.state.resultObj,time:this.state.secondCount});
  }
  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");

  };



	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
      this.setState({timeoutStat:true});
      if (this.state.showTimer) {
        this.props.nextQuestionHandler();
      }
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

	cleanString(input) {
	    let output = "";
	    for (let i = 0; i < input.length; i++) {
	        output += input.charCodeAt(i) <= 127 ? input.charAt(i) : ' ';
	    }
	    return output;
	}

  chaneText = (e) => {
    this.props.showResult();
    let { name, value } = e.target;
	let clean_value = value.replace('”', '"');
	clean_value = this.cleanString(clean_value);
	// let clean_value = value;
    this.props.submit({
		answer: clean_value,
		correct: '',
		length: clean_value.split(' ').filter(function(n) { return n != '' }).length,
		time: this.state.secondCount,
		lang: this.state.lang
	}, true);
    this.setState({
		[name]: clean_value,
		resultObj: {
			answer: clean_value,
			correct: '',
			length: clean_value.split(' ').filter(function(n) { return n != '' }).length,
			time: this.state.secondCount,
			lang:this.state.lang
		}
	});
  };

  chaneLang = (e) => {
    this.setState({lang:e.target.value});
  }
  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang}});
  }
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false});
  	}
    return (
      <>
      <Col xs={12} className="text-right"><b><p><i className="fa fa-clock-o"></i> <span>Time Left: {Math.floor(this.state.secondCount / 60) +':'+ (this.state.secondCount % 60)}</span></p> </b></Col>
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>

      <Col xs={12}><h5>{question}</h5></Col>
      <Col xs={12}>
      	<div className="col-lg-12 text-center">
		  <Col xs={12}>

		  </Col>

      {!this.props.resultMode && !this.props.answer && <>
        <Col xs={2} style={{margin:'10px'}}>

        </Col>
        <Col xs={12}>
              <FormGroup>
                {!this.state.timeoutStat && <Input type="textarea" autoCorrect="false" spellCheck="false" name="content" id="textBox" onChange={(e) => { this.chaneText(e)} } value={this.state.content}  />}
                {this.state.timeoutStat && <div className="timeout-box">{this.state.content}</div>}
              </FormGroup>
              <div className="bottomContent">
                <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
                <div className="copyPaste">
                  <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
                  <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
                  <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
                </div>
              </div>
            </Col>

            </>
          }
		</div>
      </Col>
      </>
    );
  }
}

export default Essay;
